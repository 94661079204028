import { requireAuth } from 'routes'

export default (store) => ({
  childRoutes: [
    {
      path: '/content/asset',
      onEnter: requireAuth(store),
      getComponent (nextState, cb) {
        require.ensure([], (require) => {
          const Assets = require('./containers/AssetsContainer').default
          cb(null, Assets)
        }, 'assets')
      }
    },
    {
      path: '/content/location/get_map/:assetId/asset',
      onEnter: requireAuth(store),
      getComponent (nextState, cb) {
        require.ensure([], (require) => {
          const AssetLocation = require('./containers/AssetLocationContainer').default
          cb(null, AssetLocation)
        }, 'assetlocation')
      }
    },
    {
      path: '/content/asset/:type(/:id)',
      onEnter: requireAuth(store),
      getComponent (nextState, cb) {
        require.ensure([], (require) => {
          const Assets = require('./containers/AssetCreateContainer').default
          cb(null, Assets)
        }, 'assetscreateupdate')
      }
    },
  ]
})
