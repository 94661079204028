import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { browserHistory } from 'react-router'
import PropTypes from 'prop-types'
import {
  getAssetsLikeName
} from 'store/modules/assets'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import throttle from 'lodash/throttle'

const propTypes = {
  assetsLike: PropTypes.array,
  getAssetsLikeName: PropTypes.func
}

const ManholeSearchBar = () => {
  const dispatch = useDispatch()
  const [options, setOptions] = useState([])
  const [inputValue, setInputValue] = useState('')
  const [value, setValue] = useState('')
  const assetsLike = useSelector((state) => {
    return state.assets.assetsLike
  })
  useEffect(() => {
    const options = assetsLike.map((i) => {
      return { label: i.assetNo, id: i.assetId }
    })
    setOptions(options)
  }, [assetsLike])

  const fetch = useMemo(
    () =>
      throttle((inputValue) => {
        dispatch(getAssetsLikeName(inputValue))
      }, 200),
    [options],
  )

  useEffect(() => {
    if (inputValue !== '') {
      fetch(inputValue)
    }
  }, [inputValue])

  return (
    <div>
      <Autocomplete
        value={value}
        onChange={(_event, newValue) => {
          setValue(newValue.label)
          browserHistory.push(`/search/manholes/${newValue.id}`)
          setValue('')
          setInputValue('')
        }}
        inputValue={inputValue}
        onInputChange={(_event, newInputValue) => {
          setInputValue(newInputValue)
        }}
        isOptionEqualToValue={(option, value) => option.label === value}
        id='manhole-autocomplete'
        options={options}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label='Manhole Search' />}
      />
    </div>
  )
}

ManholeSearchBar.propTypes = propTypes

export default ManholeSearchBar
