import { requireAuth } from 'routes'

export default (store) => ({
  childRoutes: [
    {
      path: '/content/construction_on_network',
      onEnter: requireAuth(store),
      getComponent (_nextState, cb) {
        require.ensure([], (require) => {
          const ConstructionOnNetwork = require('./containers/ConstructionOnNetworkContainer').default
          cb(null, ConstructionOnNetwork)
        }, '')
      }
    }, {
      path: '/content/construction_on_network/view/:id',
      onEnter: requireAuth(store),
      getComponent (_nextState, cb) {
        require.ensure([], (require) => {
          const ConstructionOnNetworkView = require('./containers/ConstructionOnNetworkViewContainer').default
          cb(null, ConstructionOnNetworkView)
        }, '')
      }
    }, {
      path: '/content/construction_on_network/edit/:id',
      onEnter: requireAuth(store),
      getComponent (_nextState, cb) {
        require.ensure([], (require) => {
          const ConstructionOnNetworkEdit = require('./components/EditConstructionOnNetwork').default
          cb(null, ConstructionOnNetworkEdit)
        }, '')
      }
    }, {
      path: '/content/construction_on_network/create',
      onEnter: requireAuth(store),
      getComponent (_nextState, cb) {
        require.ensure([], (require) => {
          const ConstructionOnNetworkCreate = require('./components/CreateConstructionOnNetwork').default
          cb(null, ConstructionOnNetworkCreate)
        }, '')
      }
    }
  ]
})
