import { requireAuth } from 'routes'
import { injectReducer } from 'store/reducers'

export default (store) => ({
  childRoutes: [
    {
      path: '/content/dashboard',
      onEnter: requireAuth(store),
      getComponent (nextState, cb) {
        require.ensure([], (require) => {
          const Dashboard = require('./containers/DashboardContainer').default
          const reducer = require('./modules/dashboard').default
          injectReducer(store, { key: 'dashboard', reducer })
          cb(null, Dashboard)
        }, 'dashboard')
      }
    },
    {
      path: '/content/dashboard/index/:month/:year',
      onEnter: requireAuth(store),
      getComponent (nextState, cb) {
        require.ensure([], (require) => {
          const Dashboard = require('./containers/DashboardContainer').default
          const reducer = require('./modules/dashboard').default
          injectReducer(store, { key: 'dashboard', reducer })
          cb(null, Dashboard)
        }, 'dashboard')
      }
    },
    {
      path: '/',
      onEnter: requireAuth(store),
      getComponent (nextState, cb) {
        require.ensure([], (require) => {
          const Dashboard = require('./containers/DashboardContainer').default
          const reducer = require('./modules/dashboard').default
          injectReducer(store, { key: 'dashboard', reducer })
          cb(null, Dashboard)
        }, 'dashboard')
      }
    }
  ]
})
