import { requireAuth } from 'routes'

export default (store) => ({
  path: '/content/network',
  onEnter: requireAuth(store),
  getComponent (nextState, cb) {
    require.ensure([], (require) => {
      const Networks = require('./containers/NetworksContainer').default
      cb(null, Networks)
    }, 'networks')
  }
})
