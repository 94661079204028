import React from 'react'
import PropTypes from 'prop-types'
import { connect, useSelector, useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'
import { logout } from 'store/modules/auth'
import {
  toggleSidebar, openLightbox, closeLightbox, updateLightboxIndex,
  closeMapDialog
} from 'store/modules/site'
import { AppBar, IconButton, Box, Toolbar, Typography, Menu, MenuItem } from '@mui/material'
import { MoreVert, Menu as MenuIcon } from '@mui/icons-material'
import Sidebar from 'components/Sidebar/Sidebar'
import ManholeSearchBar from 'components/ManholeSearch/ManholeSearchBar'
import SuccessDialog from 'components/SuccessDialog/SuccessDialog'
import style from './PageLayout.module.scss'
import logo from 'static/logo.png'
import config from 'config'
import Lightbox from 'react-image-lightbox'
import ConfirmAlert from 'components/ConfirmAlert/ConfirmAlert'
import SendMessageDialog from 'components/SendMessageDialog/SendMessageDialog'
import ResponsiveDialog from 'components/ResponsiveDialog'
import GoogleMap from 'components/GoogleMap'

const propTypes = {
  logout: PropTypes.func,
  children: PropTypes.node,
  hasLoggedIn: PropTypes.bool,
  toggleSidebar: PropTypes.func,
  sidebarIsOpen: PropTypes.bool,
  lightboxIsOpen: PropTypes.bool,
  lightboxImages: PropTypes.array,
  // openLightbox: PropTypes.func,
  closeLightbox: PropTypes.func,
  currentLightboxImage: PropTypes.number,
  updateLightboxIndex: PropTypes.func,
  confirmCallback: PropTypes.func,
  confirmCancelCallback: PropTypes.func,
  confirmDialogMessage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  confirmDialogIsOpen: PropTypes.bool,
  confirmDialogData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}

const ProfileMenu = ({ logout }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <IconButton
        size='large'
        edge='end'
        color='inherit'
        aria-label='open menu'
        aria-controls={open ? 'profile-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        // sx={{ mr: 2 }}
        onClick={handleClick}
        id='profile-menu-button'
      >
        <MoreVert sx={{ color: '#818181' }}/>
      </IconButton>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'profile-menu-button',
        }}
      >
        {/* <MenuItem onClick={handleClose}>Full Screen</MenuItem> */}
        <MenuItem onClick={() => {
          logout()
          handleClose()
        }}>Logout</MenuItem>
      </Menu>
    </div>
  )
}

ProfileMenu.propTypes = {
  logout: PropTypes.func
}

const MapDialog = () => {
  const dispatch = useDispatch()
  const { markers, open } = useSelector((state) => {
    return {
      markers: state.site.mapDialogMarkers,
      open: state.site.mapDialogIsOpen
    }
  })
  return (
    <ResponsiveDialog
      open={open}
      onClose={() => { dispatch(closeMapDialog()) }}
    >
      <GoogleMap markers={markers} />
    </ResponsiveDialog>
  )
}

class PageLayout extends React.Component {
  handleMenuTap = () => {
    this.props.toggleSidebar()
  }

  renderLoggedIn () {
    if (this.props.hasLoggedIn) {
      let topBarLogoUrl = logo
      let topBarLogoStyle = {}
      if (config.topBarLogo) {
        topBarLogoUrl = `${config.apiHost}/${config.topBarLogo}`
        topBarLogoStyle = { maxWidth: '120px', maxHeight: '45px' }
      }
      return (
        <>
          <div id='AppBar'>
            <Box sx={{ flexGrow: 1 }}>
              <AppBar position='fixed' sx={{ backgroundColor: 'white' }}>
                <Toolbar>
                  <IconButton
                    size='large'
                    edge='start'
                    color='inherit'
                    aria-label='open drawer'
                    sx={{ mr: 2 }}
                    onClick={this.handleMenuTap}
                  >
                    <MenuIcon sx={{ color: '#818181' }}/>
                  </IconButton>
                  <Typography
                    variant='h6'
                    noWrap
                    component='div'
                    sx={{ paddingTop: '10px', flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
                  >
                    <img src={topBarLogoUrl} style={topBarLogoStyle} />
                  </Typography>
                  <ManholeSearchBar />
                  <ProfileMenu logout={this.props.logout} />
                </Toolbar>
              </AppBar>
            </Box>
          </div>
          <Sidebar />
        </>
      )
    }
  }

  render () {
    let viewportStyle = style.viewport
    const appStyle = style.appStyle
    if (this.props.sidebarIsOpen) {
      viewportStyle = style.viewportOpen
    }
    return (
      <div className={appStyle}>
        {this.renderLoggedIn()}
        <div className='PageLayout'>
          <div className={viewportStyle}>
            <SuccessDialog />
            {this.props.children}
          </div>
        </div>
        {this.props.lightboxIsOpen && (
          <Lightbox
            mainSrc={this.props.lightboxImages[this.props.currentLightboxImage].src}
            nextSrc={this.props.lightboxImages[(this.props.currentLightboxImage + 1) %
              this.props.lightboxImages.length].src}
            prevSrc={this.props.lightboxImages[(this.props.currentLightboxImage +
              this.props.lightboxImages.length - 1) %
              this.props.lightboxImages.length].src}
            onCloseRequest={() => this.props.closeLightbox()}
            onMovePrevRequest={() => {
              this.props.updateLightboxIndex((this.props.currentLightboxImage +
                this.props.lightboxImages.length - 1) % this.props.lightboxImages.length)
            }
            }
            onMoveNextRequest={() => {
              this.props.updateLightboxIndex((this.props.currentLightboxImage + 1) % this.props.lightboxImages.length)
            }}
            imageTitle={this.props.lightboxImages[this.props.currentLightboxImage].imageTitle}
            imageCaption={this.props.lightboxImages[this.props.currentLightboxImage].imageCaption}
          />
        )}
        <ConfirmAlert
          confirmCallback={this.props.confirmCallback}
          closeCallback={this.props.confirmCancelCallback}
          message={this.props.confirmDialogMessage}
          open={this.props.confirmDialogIsOpen}
          data={this.props.confirmDialogData}
        />
        <SendMessageDialog />
        <MapDialog />
      </div>
    )
  }
}

PageLayout.propTypes = propTypes

const mapStateToProps = (state) => {
  return {
    hasLoggedIn: state.auth.hasLoggedIn,
    sidebarIsOpen: state.site.sidebarIsOpen,
    lightboxIsOpen: state.site.lightboxIsOpen,
    lightboxImages: state.site.lightboxImages,
    currentLightboxImage: state.site.currentLightboxImage,
    confirmDialogIsOpen: state.site.confirmDialogIsOpen,
    confirmDialogMessage: state.site.confirmDialogMessage,
    confirmCallback: state.site.confirmCallback,
    confirmCancelCallback: state.site.confirmCancelCallback,
    confirmDialogData: state.site.confirmDialogData
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({
    logout,
    toggleSidebar,
    openLightbox,
    closeLightbox,
    updateLightboxIndex
  }, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PageLayout)
