import { createAction, handleActions } from 'redux-actions'
import { sendApiRequest } from 'utils/api'

export const SEND_EMAIL = 'SEND_EMAIL'
export const sendEmail = (data) => {
  return (dispatch, getState) => {
    dispatch(createAction(SEND_EMAIL)())
    data.accessToken = getState().auth.account.accessToken
    sendApiRequest('Mail/send', data, 'post').then((response) => {
      if (response.status === 'failure') {
        dispatch(sendEmailError(response))
      }
      if (response.status === 'success') {
        dispatch(sendEmailSuccess(response.data))
      }
    })
  }
}

export const SEND_EMAIL_SUCCESS = 'SEND_EMAIL_SUCCESS'
export const sendEmailSuccess = createAction(SEND_EMAIL_SUCCESS)

export const SEND_EMAIL_ERROR = 'SEND_EMAIL_ERROR'
export const sendEmailError = createAction(SEND_EMAIL_ERROR)

export const SEND_EMAIL_RESET = 'SEND_EMAIL_RESET'
export const sendEmailReset = createAction(SEND_EMAIL_RESET)

const initialState = {
  isSendingEmail: false,
  hasSentEmail: false,
  sendEmailError: {},
  sendEmailSuccess: {}
}

export default handleActions({

  [SEND_EMAIL]: (state) => ({
    ...state,
    isSendingEmail: true,
    hasSentEmail: false
  }),

  [SEND_EMAIL_ERROR]: (state, { payload }) => ({
    ...state,
    isSendingEmail: false,
    hasSentEmail: false,
    sendEmailError: payload
  }),

  [SEND_EMAIL_SUCCESS]: (state, { payload }) => ({
    ...state,
    isSendingEmail: false,
    hasSentEmail: true,
    sendEmailSuccess: payload
  }),

  [SEND_EMAIL_RESET]: (state) => ({
    ...state,
    isSendingEmail: false,
    hasSentEmail: false,
    sendEmailSuccess: {},
    sendEmailError: {}
  }),
}, initialState)
