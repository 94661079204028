import { requireAuth } from 'routes'

export default (store) => ({
  path: '/content/ltareport',
  onEnter: requireAuth(store),
  getComponent (nextState, cb) {
    require.ensure([], (require) => {
      const LTAReport = require('./containers/LTAReportContainer').default
      cb(null, LTAReport)
    }, '')
  }
})
