let config = {}
if (process.env.REACT_APP_CLIENT_ENV) {
  switch (process.env.REACT_APP_ENV) {
    case 'development': {
      config = require(`./deploy/${process.env.REACT_APP_CLIENT_ENV}/config/config.development.json`)
      break
    }
    case 'staging': {
      config = require(`./deploy/${process.env.REACT_APP_CLIENT_ENV}/config/config.staging.json`)
      break
    }
    case 'production': {
      config = require(`./deploy/${process.env.REACT_APP_CLIENT_ENV}/config/config.production.json`)
      break
    }
    default: {
      config = require(`./deploy/${process.env.REACT_APP_CLIENT_ENV}/config/config.development.json`)
    }
  }
} else {
  switch (process.env.REACT_APP_ENV) {
    case 'development': {
      config = require('./config/config.development.json')
      break
    }
    case 'staging': {
      config = require('./config/config.staging.json')
      break
    }
    case 'production': {
      config = require('./config/config.production.json')
      break
    }
    default: {
      config = require('./config/config.development.json')
    }
  }
}
export default config

// if (process.env.CLIENT_ENV) {
//   switch (process.env.NODE_ENV) {
//     case 'development': {
//       config = require(`./deploy/${process.env.CLIENT_ENV}/config/config.development.json`)
//       break
//     }
//     case 'staging': {
//       config = require(`./deploy/${process.env.CLIENT_ENV}/config/config.staging.json`)
//       break
//     }
//     case 'production': {
//       config = require(`./deploy/${process.env.CLIENT_ENV}/config/config.production.json`)
//       break
//     }
//     default: {
//       config = require(`./deploy/${process.env.CLIENT_ENV}/config/config.development.json`)
//     }
//   }
// } else {
//   switch (process.env.NODE_ENV) {
//     case 'development': {
//       config = require('./config/config.development.json')
//       break
//     }
//     case 'staging': {
//       config = require('./config/config.staging.json')
//       break
//     }
//     case 'production': {
//       config = require('./config/config.production.json')
//       break
//     }
//     default: {
//       config = require('./config/config.development.json')
//     }
//   }
// }
// export default config
