import { requireAuth } from 'routes'

export default (store) => ({
  path: '/search/manholes/:id',
  onEnter: requireAuth(store),
  getComponent (nextState, cb) {
    require.ensure([], (require) => {
      const ManholeSearch = require('./containers/ManholeSearchContainer').default
      cb(null, ManholeSearch)
    }, 'manholesearch')
  }
})
