import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import parse from 'autosuggest-highlight/parse'
import throttle from 'lodash/throttle'
import { useJsApiLoader } from '@react-google-maps/api'
import config from 'config'
const { googleMapsApiKey } = config
const libraries = ['places', 'visualization']
const autocompleteService = { current: null }

const GooglePlaceAutocomplete = ({ callback, defaultValue, ...otherProps }) => {
  const [placeValue, setPlaceValue] = useState(null)
  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState([])
  const [disabled, setDisabled] = useState(true)
  const { isLoaded } = useJsApiLoader({
    id: 'google-maps',
    libraries,
    googleMapsApiKey
  })

  useEffect(() => {
    if (defaultValue) {
      setInputValue(defaultValue)
    }
  }, [defaultValue])

  useEffect(() => {
    setDisabled(!isLoaded)
  }, [isLoaded])

  const fetch = useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback)
      }, 200),
    [],
  )

  useEffect(() => {
    let active = true

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService()
    }
    if (!autocompleteService.current) {
      return undefined
    }

    if (inputValue === '') {
      setOptions(placeValue ? [placeValue] : [])
      return undefined
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = []

        if (placeValue) {
          newOptions = [placeValue]
        }

        if (results) {
          newOptions = [...newOptions, ...results]
          if (results[0] && results[0].description && (results[0].description === defaultValue)) {
            setPlaceValue(results[0])
          }
          if (defaultValue && defaultValue !== '') {
            setInputValue(defaultValue)
          }
        }

        setOptions(newOptions)
      }
    })

    return () => {
      active = false
    }
  }, [placeValue, inputValue, fetch])

  return (
    <Autocomplete
      id='google-maps-autocomplete'
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.description
      }
      disabled={disabled}
      fullWidth
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={placeValue}
      onChange={(_event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options)
        setPlaceValue(newValue)
        if (callback) { callback(newValue) }
      }}
      onInputChange={(_event, newInputValue) => {
        setInputValue(newInputValue)
      }}
      renderInput={(params) => (
        <TextField {...params} {...otherProps} />
      )}
      renderOption={(props, option) => {
        const matches = option.structured_formatting.main_text_matched_substrings
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length]),
        )

        return (
          <li {...props}>
            <Grid container alignItems='center'>
              <Grid item>
                <Box
                  component={LocationOnIcon}
                  sx={{ color: 'text.secondary', mr: 2 }}
                />
              </Grid>
              <Grid item xs>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400,
                    }}
                  >
                    {part.text}
                  </span>
                ))}

                <Typography variant='body2' color='text.secondary'>
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        )
      }}
    />
  )
}

GooglePlaceAutocomplete.propTypes = {
  callback: PropTypes.func,
  defaultValue: PropTypes.string
}

export default GooglePlaceAutocomplete
