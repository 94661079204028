import { requireAuth } from 'routes'

export default (store) => ({
  path: '/content/report/get_rectification_works/:type',
  onEnter: requireAuth(store),
  getComponent (nextState, cb) {
    require.ensure([], (require) => {
      const RectificationWorks = require('./containers/RectificationWorksContainer').default
      cb(null, RectificationWorks)
    }, 'recitificationworks')
  }
})
