import { requireAuth } from 'routes'
import { injectReducer } from 'store/reducers'

export default (store) => ({
  childRoutes: [
    {
      path: '/content/nce_report/:type',
      onEnter: requireAuth(store),
      getComponent (nextState, cb) {
        require.ensure([], (require) => {
          const NceReports = require('./containers/NceReportsContainer').default
          const reducer = require('./modules/ncereports').default
          injectReducer(store, { key: 'ncereports', reducer })
          cb(null, NceReports)
        }, 'ncereports')
      }
    }, {
      path: '/content/nce_report/:type/get_more/:nceId',
      onEnter: requireAuth(store),
      getComponent (nextState, cb) {
        require.ensure([], (require) => {
          const NceReports = require('./containers/NceReportsViewContainer').default
          const reducer = require('./modules/ncereports').default
          injectReducer(store, { key: 'ncereports', reducer })
          cb(null, NceReports)
        }, 'ncereportsview')
      }
    }
  ]
})
