import { createAction, handleActions } from 'redux-actions'
import { sendApiRequest } from 'utils/api'
import { getClientId } from 'utils/utils'

export const GET_WORK_COUNT = 'GET_WORK_COUNT'
export const getWorkCount = (type, options = {}, search) => {
  return (dispatch, getState) => {
    dispatch(createAction(GET_WORK_COUNT)())
    const andArray = []
    const clientId = getClientId(getState)
    options.where = {
      and: []
    }
    andArray.push({ clientId })
    if (search) {
      andArray.push(search)
    }
    switch (type) {
      case 'recommended': {
        andArray.push({
          workStatusId: {
            inq: [1, 5, 8, 9],
          },
        })
        break
      }
      case 'approved': {
        andArray.push({
          workStatusId: {
            inq: [2, 6],
          },
        })
        break
      }
      case 'scheduled': {
        andArray.push({
          workStatusId: {
            inq: [3, 7, 11],
          },
        })
        break
      }
      case 'completed': {
        andArray.push({
          workStatusId: {
            inq: [10, 13],
          },
        })
        break
      }
      default: {
        andArray.push({
          workStatusId: 13,
        })
      }
    }
    options.where.and = andArray
    sendApiRequest('Works/count', {
      accessToken: getState().auth.account.accessToken
    }, 'get', options).then((response) => {
      if (response.status === 'failure') {
        dispatch(getWorkCountError(response))
      }
      if (response.status === 'success') {
        dispatch(getWorkCountSuccess(response.data.count))
      }
    })
  }
}

export const GET_WORK_COUNT_SUCCESS = 'GET_WORK_COUNT_SUCCESS'
export const getWorkCountSuccess = createAction(GET_WORK_COUNT_SUCCESS)

export const GET_WORK_COUNT_ERROR = 'GET_WORK_COUNT_ERROR'
export const getWorkCountError = createAction(GET_WORK_COUNT_ERROR)

export const GET_WORKS = 'GET_WORKS'
export const getWorks = (pageSize, pageNumber, type, search, filter) => {
  return (dispatch, getState) => {
    dispatch(createAction(GET_WORKS)())
    let options = {
      filter: {
        order: 'workId DESC',
        limit: pageSize,
        include: 'assetAudit'
      }
    }
    if (filter) { options = Object.assign(options, filter) }
    if (pageNumber !== 1) {
      options.filter.skip = (pageNumber - 1) * pageSize
    }
    if (search) {
      options.filter.search = search
    }
    const clientId = getClientId(getState)
    sendApiRequest(`Works/type/${clientId}/${type}`, {
      accessToken: getState().auth.account.accessToken
    }, 'get', options).then((response) => {
      if (response.status === 'failure') {
        dispatch(getWorksError(response))
      }
      if (response.status === 'success') {
        dispatch(getWorksSuccess(response.data))
      }
    })
  }
}

export const GET_WORKS_SUCCESS = 'GET_WORKS_SUCCESS'
export const getWorksSuccess = createAction(GET_WORKS_SUCCESS)

export const GET_WORKS_ERROR = 'GET_WORKS_ERROR'
export const getWorksError = createAction(GET_WORKS_ERROR)

export const GET_WORKS_IMAGES = 'GET_WORKS_IMAGES'
export const getWorksImages = (id) => {
  return (dispatch, getState) => {
    dispatch(createAction(GET_WORKS_IMAGES)())
    sendApiRequest(`AssetAudits/${id}/photos`, {
      accessToken: getState().auth.account.accessToken
    }, 'get').then((response) => {
      if (response.status === 'failure') {
        dispatch(getWorksImagesError(response))
      }
      if (response.status === 'success') {
        dispatch(getWorksImagesSuccess(response.data))
      }
    })
  }
}

export const GET_WORKS_IMAGES_SUCCESS = 'GET_WORKS_IMAGES_SUCCESS'
export const getWorksImagesSuccess = createAction(GET_WORKS_IMAGES_SUCCESS)

export const GET_WORKS_IMAGES_ERROR = 'GET_WORKS_IMAGES_ERROR'
export const getWorksImagesError = createAction(GET_WORKS_IMAGES_ERROR)

export const AUTHORIZE_WORK_BY_ID = 'AUTHORIZE_WORK_BY_ID'
export const authorizeWorkById = (id) => {
  return (dispatch, getState) => {
    dispatch(createAction(AUTHORIZE_WORK_BY_ID)())
    sendApiRequest(`Works/${id}/authorize`, {
      accessToken: getState().auth.account.accessToken
    }, 'post').then((response) => {
      if (response.status === 'failure') {
        dispatch(authorizeWorkByIdError(response))
      }
      if (response.status === 'success') {
        dispatch(authorizeWorkByIdSuccess(response.data))
      }
    })
  }
}

export const AUTHORIZE_WORK_BY_ID_SUCCESS = 'AUTHORIZE_WORK_BY_ID_SUCCESS'
export const authorizeWorkByIdSuccess = createAction(AUTHORIZE_WORK_BY_ID_SUCCESS)

export const AUTHORIZE_WORK_BY_ID_ERROR = 'AUTHORIZE_WORK_BY_ID_ERROR'
export const authorizeWorkByIdError = createAction(AUTHORIZE_WORK_BY_ID_ERROR)

export const UNAUTHORIZE_WORK_BY_ID = 'UNAUTHORIZE_WORK_BY_ID'
export const unauthorizeWorkById = (id) => {
  return (dispatch, getState) => {
    dispatch(createAction(UNAUTHORIZE_WORK_BY_ID)())
    sendApiRequest(`Works/${id}/unauthorize`, {
      accessToken: getState().auth.account.accessToken
    }, 'post').then((response) => {
      if (response.status === 'failure') {
        dispatch(unauthorizeWorkByIdError(response))
      }
      if (response.status === 'success') {
        dispatch(unauthorizeWorkByIdSuccess(response.data))
      }
    })
  }
}

export const UNAUTHORIZE_WORK_BY_ID_SUCCESS = 'UNAUTHORIZE_WORK_BY_ID_SUCCESS'
export const unauthorizeWorkByIdSuccess = createAction(UNAUTHORIZE_WORK_BY_ID_SUCCESS)

export const UNAUTHORIZE_WORK_BY_ID_ERROR = 'UNAUTHORIZE_WORK_BY_ID_ERROR'
export const unauthorizeWorkByIdError = createAction(UNAUTHORIZE_WORK_BY_ID_ERROR)

export const SCHEDULE_WORK_BY_ID = 'SCHEDULE_WORK_BY_ID'
export const scheduleWorkById = (id, date) => {
  return (dispatch, getState) => {
    dispatch(createAction(SCHEDULE_WORK_BY_ID)())
    sendApiRequest(`Works/${id}/schedule`, {
      accessToken: getState().auth.account.accessToken,
      date
    }, 'post').then((response) => {
      if (response.status === 'failure') {
        dispatch(scheduleWorkByIdError(response))
      }
      if (response.status === 'success') {
        dispatch(scheduleWorkByIdSuccess(response.data))
      }
    })
  }
}

export const SCHEDULE_WORK_BY_ID_SUCCESS = 'SCHEDULE_WORK_BY_ID_SUCCESS'
export const scheduleWorkByIdSuccess = createAction(SCHEDULE_WORK_BY_ID_SUCCESS)

export const SCHEDULE_WORK_BY_ID_ERROR = 'SCHEDULE_WORK_BY_ID_ERROR'
export const scheduleWorkByIdError = createAction(SCHEDULE_WORK_BY_ID_ERROR)

export const UNSCHEDULE_WORK_BY_ID = 'UNSCHEDULE_WORK_BY_ID'
export const unscheduleWorkById = (id) => {
  return (dispatch, getState) => {
    dispatch(createAction(UNSCHEDULE_WORK_BY_ID)())
    sendApiRequest(`Works/${id}/unschedule`, {
      accessToken: getState().auth.account.accessToken
    }, 'post').then((response) => {
      if (response.status === 'failure') {
        dispatch(unscheduleWorkByIdError(response))
      }
      if (response.status === 'success') {
        dispatch(unscheduleWorkByIdSuccess(response.data))
      }
    })
  }
}

export const UNSCHEDULE_WORK_BY_ID_SUCCESS = 'UNSCHEDULE_WORK_BY_ID_SUCCESS'
export const unscheduleWorkByIdSuccess = createAction(UNSCHEDULE_WORK_BY_ID_SUCCESS)

export const UNSCHEDULE_WORK_BY_ID_ERROR = 'UNAUTHORIZE_WORK_BY_ID_ERROR'
export const unscheduleWorkByIdError = createAction(UNSCHEDULE_WORK_BY_ID_ERROR)

export const MARK_WORK_COMPLETE_BY_ID = 'MARK_WORK_COMPLETE_BY_ID'
export const markWorkCompleteById = (id) => {
  return (dispatch, getState) => {
    dispatch(createAction(MARK_WORK_COMPLETE_BY_ID)())
    sendApiRequest(`Works/${id}/markComplete`, {
      accessToken: getState().auth.account.accessToken
    }, 'post').then((response) => {
      if (response.status === 'failure') {
        dispatch(markWorkCompleteByIdError(response))
      }
      if (response.status === 'success') {
        dispatch(markWorkCompleteByIdSuccess(response.data))
      }
    })
  }
}

export const MARK_WORK_COMPLETE_BY_ID_SUCCESS = 'MARK_WORK_COMPLETE_BY_ID_SUCCESS'
export const markWorkCompleteByIdSuccess = createAction(MARK_WORK_COMPLETE_BY_ID_SUCCESS)

export const MARK_WORK_COMPLETE_BY_ID_ERROR = 'MARK_WORK_COMPLETE_BY_ID_ERROR'
export const markWorkCompleteByIdError = createAction(MARK_WORK_COMPLETE_BY_ID_ERROR)

const initialState = {
  workCount: 0,
  isGettingWorks: false,
  hasGottenWorks: true,
  isGettingWorksImages: false,
  hasGottenWorksImages: false,
  works: [],
  worksImages: [],
  selectedWork: {},
  isAuthorizingWork: false,
  hasAuthorizedWork: false,
  isSchedulingWork: false,
  hasScheduledWork: false,
  isUnschedulingWork: false,
  hasUnscheduledWork: false,
  isMarkingWorkComplete: false,
  hasMarkedWorkComplete: false
}

export default handleActions({

  [GET_WORK_COUNT_SUCCESS]: (state, { payload }) => ({
    ...state,
    workCount: payload
  }),

  [GET_WORKS]: (state) => ({
    ...state,
    isGettingWorks: true,
    hasGottenWorks: false
  }),

  [GET_WORKS_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isGettingWorks: false,
      hasGottenWorks: true,
      works: payload
    }
  },

  [GET_WORKS_ERROR]: (state, { payload }) => {
    return {
      ...state,
      isGettingWorks: false,
      hasGottenWorks: false,
      worksError: payload
    }
  },

  [GET_WORKS_IMAGES]: (state) => ({
    ...state,
    isGettingWorksImages: true,
    hasGottenWorksImages: false
  }),

  [GET_WORKS_IMAGES_SUCCESS]: (state, { payload }) => ({
    ...state,
    isGettingWorksImages: false,
    hasGottenWorksImages: true,
    worksImages: payload
  }),

  [AUTHORIZE_WORK_BY_ID]: (state) => ({
    ...state,
    isAuthorizingWork: true,
    hasAuthorizedWork: false
  }),

  [AUTHORIZE_WORK_BY_ID_SUCCESS]: (state) => ({
    ...state,
    isAuthorizingWork: false,
    hasAuthorizedWork: true
  }),

  [UNAUTHORIZE_WORK_BY_ID]: (state) => ({
    ...state,
    isUnauthorizingWork: true,
    hasUnauthorizedWork: false
  }),

  [UNAUTHORIZE_WORK_BY_ID_SUCCESS]: (state) => ({
    ...state,
    isUnauthorizingWork: false,
    hasUnauthorizedWork: true
  }),

  [SCHEDULE_WORK_BY_ID]: (state) => ({
    ...state,
    isSchedulingWork: true,
    hasScheduledWork: false
  }),

  [SCHEDULE_WORK_BY_ID_SUCCESS]: (state) => ({
    ...state,
    isSchedulingWork: false,
    hasScheduledWork: true
  }),

  [UNSCHEDULE_WORK_BY_ID]: (state) => ({
    ...state,
    isUnschedulingWork: true,
    hasUnscheduledWork: false
  }),

  [UNSCHEDULE_WORK_BY_ID_SUCCESS]: (state) => ({
    ...state,
    isUnschedulingWork: false,
    hasUnscheduledWork: true
  }),

  [MARK_WORK_COMPLETE_BY_ID]: (state) => ({
    ...state,
    isMarkingWorkComplete: true,
    hasMarkedWorkComplete: false
  }),

  [MARK_WORK_COMPLETE_BY_ID_SUCCESS]: (state) => ({
    ...state,
    isMarkingWorkComplete: false,
    hasMarkedWorkComplete: true
  })

}, initialState)
