import React from 'react'
// import ReactDOM from 'react-dom'
// import createStore from './store/createStore'
// import { browserHistory } from 'react-router'
// import { syncHistoryWithStore } from 'react-router-redux'
// import injectTapEventPlugin from 'react-tap-event-plugin'
import { createRoot } from 'react-dom/client'
import { sessionService } from 'redux-react-session'
import App from './components/App'
import createStore from 'store/createStore'
import { createBrowserHistory } from 'history'
import { Provider } from 'react-redux'

const store = createStore(window.__INITIAL_STATE__)
const history = createBrowserHistory()
const routes = require('routes/index').default(store) //eslint-disable-line

sessionService.initSessionService(store)
// import './styles/main.scss'

// Store Initialization
// ------------------------------------

// Render Setup
// ------------------------------------
const MOUNT_NODE = document.getElementById('root')

// injectTapEventPlugin()
// sessionService.initSessionService(store)
// const App = require('./components/App').default
// const routes = require('./routes/index').default(store)

const root = createRoot(MOUNT_NODE)

root.render(
  <Provider store={store} history={history} routes={routes}>
    <App store={store} history={history} routes={routes} />
  </Provider>
)
