import { requireAuth } from 'routes'
import { injectReducer } from 'store/reducers'

export default (store) => ({
  childRoutes: [
    {
      path: '/content/spares',
      onEnter: requireAuth(store),
      getComponent (nextState, cb) {
        require.ensure([], (require) => {
          const Spares = require('./containers/SparesContainer').default
          const reducer = require('./modules/spares').default
          injectReducer(store, { key: 'spares', reducer })
          cb(null, Spares)
        }, 'spares')
      }
    }, {
      path: '/content/spares/get_spare_view/:spareId',
      onEnter: requireAuth(store),
      getComponent (nextState, cb) {
        require.ensure([], (require) => {
          const SparesViewContainer = require('./containers/SparesViewContainer').default
          const reducer = require('./modules/spares').default
          injectReducer(store, { key: 'spares', reducer })
          cb(null, SparesViewContainer)
        }, 'sparesview')
      }
    }, {
      path: '/content/spares/get_spare_edit/:spareId',
      onEnter: requireAuth(store),
      getComponent (nextState, cb) {
        require.ensure([], (require) => {
          const SparesEditContainer = require('./containers/SparesEditContainer').default
          const reducer = require('./modules/spares').default
          injectReducer(store, { key: 'spares', reducer })
          cb(null, SparesEditContainer)
        }, 'sparesedit')
      }
    }
  ]
})
