import { requireAuth } from 'routes'

export default (store) => ({
  childRoutes: [
    {
      path: '/content/accounts/map',
      onEnter: requireAuth(store),
      getComponent (_nextState, cb) {
        require.ensure([], (require) => {
          const Accounts = require('./containers/AuditorMapContainer').default
          cb(null, Accounts)
        }, 'accounts-map')
      }
    },
    {
      path: '/content/accounts/map/:id',
      onEnter: requireAuth(store),
      getComponent (_nextState, cb) {
        require.ensure([], (require) => {
          const Accounts = require('./containers/SingleAuditorMap').default
          cb(null, Accounts)
        }, 'accounts-map-single')
      }
    },
    {
      path: '/content/accounts/:role',
      onEnter: requireAuth(store),
      getComponent (_nextState, cb) {
        require.ensure([], (require) => {
          const Accounts = require('./containers/AccountsContainer').default
          cb(null, Accounts)
        }, 'accounts')
      }
    },
  ],
})
