import axios from 'axios'
import config from 'config'
import { sessionService } from 'redux-react-session'
import { browserHistory } from 'react-router'
// import { useDispatch } from 'react-redux'
// import { logout } from 'store/modules/auth'
const {
  // apiHost,
  googleGeocodingApiKey,
  // googleMapsApiKey
} = config

export const sendApiRequest = (urlPart, data, method, options = {}) => {
  // const dispatch = useDispatch()
  let filtered = {}
  let where = {}
  if (Object.hasOwnProperty.call(options, 'filter')) {
    filtered = options.filter
  }
  if (Object.hasOwnProperty.call(options, 'where')) {
    where = options.where
  }
  let url = `/api/${urlPart}?filter=${JSON.stringify(filtered)}&where=${JSON.stringify(where)}`
  if (!method) { method = 'get' }
  if (data.accessToken) {
    url = url + `&access_token=${data.accessToken}`
  }
  if (!data.accessToken && Object.hasOwnProperty.call(options, 'accessToken')) {
    url = url + `&access_token=${options.accessToken}`
  }
  delete data.accessToken

  return axios({
    method: method,
    url,
    data: data
  }).then(function (response) {
    return {
      status: 'success',
      data: response.data
    }
  }).catch(function (error) {
    const err = {
      status: 'failure'
    }
    if (error.response) {
      if (error.response.status === 401) {
        sessionService.deleteUser()
        sessionService.deleteSession()
        // dispatch((logout()))
        browserHistory.push('/login')
      }
      if (error.response.data.error && error.response.data.error.details &&
        error.response.data.error.details.messages) {
        err.formMessages = error.response.data.error.details.messages
      }
      if (error.response.data.error) {
        err.data = error.response.data.error
      }
      return err
    }
  })
}

export const sendBulkApiRequest = (urlPart, data, method, options = {}) => {
  let filtered = {}
  let where = {}
  if (Object.hasOwnProperty.call(options, 'filter')) {
    filtered = options.filter
  }
  if (Object.hasOwnProperty.call(options, 'where')) {
    where = options.where
  }
  let url = `/api/${urlPart}?filter=${JSON.stringify(filtered)}&where=${JSON.stringify(where)}`
  if (!method) { method = 'get' }
  if (data.accessToken) {
    url = url + `&access_token=${data.accessToken}`
  }
  delete data.accessToken
  return axios({
    method: method,
    url,
    data: data.array
  }).then(function (response) {
    return {
      status: 'success',
      data: response.data
    }
  }).catch(function (error) {
    if (error.response) {
      let messages
      if (error.response.data.error && error.response.data.error.details &&
        error.response.data.error.details.messages) {
        messages = error.response.data.error.details.messages
      }
      return {
        status: 'failure',
        data: error.response.data.error,
        formMessages: messages
      }
    }
  })
}

export const sendApiFileRequest = (urlPart, data, method, options = {}) => {
  let filtered = {}
  let where = {}
  if (Object.hasOwnProperty.call(options, 'filter')) {
    filtered = options.filter
  }
  if (Object.hasOwnProperty.call(options, 'where')) {
    where = options.where
  }
  let url = `/api/${urlPart}?filter=${JSON.stringify(filtered)}&where=${JSON.stringify(where)}`
  if (!method) { method = 'get' }
  if (data.accessToken) {
    url = url + `&access_token=${data.accessToken}`
  }
  delete data.accessToken
  return axios({
    method: method,
    url,
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }).then(function (response) {
    return {
      status: 'success',
      data: response.data
    }
  }).catch(function (error) {
    if (error.response) {
      let messages
      if (error.response.data.error && error.response.data.error.details &&
        error.response.data.error.details.messages) {
        messages = error.response.data.error.details.messages
      }
      return {
        status: 'failure',
        data: error.response.data.error,
        formMessages: messages
      }
    }
  })
}

export const getCoordsFromAddress = (address) => {
  const encoded = encodeURIComponent(address)
  const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encoded}&key=${googleGeocodingApiKey}`
  return axios({
    method: 'get',
    url
  }).then(function (response) {
    return {
      status: 'success',
      data: response.data.results[0].geometry.location
    }
  }).catch(function (error) {
    if (error.response) {
      return {
        status: 'failure'
      }
    }
  })
}

export const getAddressFromCoords = (data) => {
  const url = 'https://maps.googleapis.com/maps/api/geocode/json?latlng=' + data.lat +
  ',' + data.lng + '&key=' + googleGeocodingApiKey
  return axios({
    method: 'get',
    url
  }).then(function (response) {
    return {
      status: 'success',
      data: response.data.results[0].formatted_address
    }
  }).catch(function (error) {
    if (error.response) {
      return {
        status: 'failure'
      }
    }
  })
}
export const getPlaceAutocomplete = (data) => {
  const url = 'https://maps.googleapis.com/maps/api/place/autocomplete/json' +
  '?input=' + encodeURIComponent(data) + '&types=geocode&key=AIzaSyAygWqpit9JUAmN_x-jD4n6E8OedGrtSmU'
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url
    }).then(function (response) {
      // const obj = {
      //   status: 'success',
      //   data: response.data.results[0].formatted_address
      // }
      // console.log(response)
      resolve(response)
    }).catch((err) => reject(err))
  })
}
