import { createAction, handleActions } from 'redux-actions'
import { sendApiRequest } from 'utils/api'
import { getClientId } from 'utils/utils'

export const GET_ROUTE_COUNT = 'GET_ROUTE_COUNT'
export const getRouteCount = (search) => {
  return (dispatch, getState) => {
    const clientId = getClientId(getState)
    const options = {
      where: {
        and: [
          { or: [{ clientId }, { clientId: null }] },
        ]
      }
    }
    if (search) {
      options.where.and.push(search)
    }
    dispatch(createAction(GET_ROUTE_COUNT)())
    sendApiRequest('Routes/count', {
      accessToken: getState().auth.account.accessToken
    }, 'get', options).then((response) => {
      if (response.status === 'failure') {
        dispatch(getRouteCountError(response))
      }
      if (response.status === 'success') {
        dispatch(getRouteCountSuccess(response.data.count))
      }
    })
  }
}

export const GET_ROUTE_COUNT_SUCCESS = 'GET_ROUTE_COUNT_SUCCESS'
export const getRouteCountSuccess = createAction(GET_ROUTE_COUNT_SUCCESS)

export const GET_ROUTE_COUNT_ERROR = 'GET_ROUTE_COUNT_ERROR'
export const getRouteCountError = createAction(GET_ROUTE_COUNT_ERROR)

export const GET_ROUTES = 'GET_ROUTES'
export const getRoutes = (pageSize, pageNumber, search) => {
  return (dispatch, getState) => {
    dispatch(createAction(GET_ROUTES)())
    const clientId = getClientId(getState)
    const options = {
      filter: {
        order: 'id DESC',
        where: {
          and: [
            { or: [{ clientId }, { clientId: null }] },
          ]
        }
      }
    }
    if (pageSize !== 0) {
      options.filter.limit = pageSize + 1
    } else {
      options.filter.limit = 0
    }
    if (pageNumber !== 1) {
      options.filter.skip = (pageNumber - 1) * pageSize
    }
    if (search) {
      options.filter.where.and.push(search)
    }
    sendApiRequest('Routes', {
      accessToken: getState().auth.account.accessToken
    }, 'get', options).then((response) => {
      if (response.status === 'failure') {
        dispatch(getRoutesError(response))
      }
      if (response.status === 'success') {
        dispatch(getRoutesSuccess(response.data))
      }
    })
  }
}

export const GET_ROUTES_SUCCESS = 'GET_ROUTES_SUCCESS'
export const getRoutesSuccess = createAction(GET_ROUTES_SUCCESS)

export const GET_ROUTES_ERROR = 'GET_ROUTES_ERROR'
export const getRoutesError = createAction(GET_ROUTES_ERROR)

export const GET_ROUTE_BY_ID = 'GET_ROUTE_BY_ID'
export const getRouteById = (id) => {
  return (dispatch, getState) => {
    dispatch(createAction(GET_ROUTE_BY_ID)())
    sendApiRequest(`Routes/${id}`, {
      accessToken: getState().auth.account.accessToken
    }, 'get').then((response) => {
      if (response.status === 'failure') {
        dispatch(getRouteByIdError(response))
      }
      if (response.status === 'success') {
        dispatch(getRouteByIdSuccess(response.data))
      }
    })
  }
}

export const GET_ROUTE_BY_ID_SUCCESS = 'GET_ROUTE_BY_ID_SUCCESS'
export const getRouteByIdSuccess = createAction(GET_ROUTE_BY_ID_SUCCESS)

export const GET_ROUTE_BY_ID_ERROR = 'GET_ROUTE_BY_ID_ERROR'
export const getRouteByIdError = createAction(GET_ROUTE_BY_ID_ERROR)

export const GET_ROUTE_BY_ID_RESET = 'GET_ROUTE_BY_ID_RESET'
export const getRouteByIdReset = createAction(GET_ROUTE_BY_ID_RESET)

export const DELETE_ROUTE_BY_ID = 'DELETE_ROUTE_BY_ID'
export const deleteRouteById = (id) => {
  return (dispatch, getState) => {
    dispatch(createAction(DELETE_ROUTE_BY_ID)())
    sendApiRequest(`Routes/${id}`, {
      accessToken: getState().auth.account.accessToken
    }, 'delete').then((response) => {
      if (response.status === 'failure') {
        dispatch(deleteRouteByIdError(response.data))
      }
      if (response.status === 'success') {
        dispatch(deleteRouteByIdSuccess(response.data))
      }
    })
  }
}

export const DELETE_ROUTE_BY_ID_SUCCESS = 'DELETE_ROUTE_BY_ID_SUCCESS'
export const deleteRouteByIdSuccess = createAction(DELETE_ROUTE_BY_ID_SUCCESS)

export const DELETE_ROUTE_BY_ID_ERROR = 'DELETE_ROUTE_BY_ID_ERROR'
export const deleteRouteByIdError = createAction(DELETE_ROUTE_BY_ID_ERROR)

export const DELETE_ROUTE_BY_ID_RESET = 'DELETE_ROUTE_BY_ID_RESET'
export const deleteRouteByIdReset = createAction(DELETE_ROUTE_BY_ID_RESET)

export const CREATE_NEW_ROUTE = 'CREATE_NEW_ROUTE'
export const createNewRoute = (data) => {
  return (dispatch, getState) => {
    dispatch(createAction(CREATE_NEW_ROUTE)())
    const clientId = getClientId(getState)
    const routeData = data
    routeData.clientId = clientId
    routeData.accessToken = getState().auth.account.accessToken
    sendApiRequest('Routes', routeData, 'post').then((response) => {
      if (response.status === 'failure') {
        dispatch(createNewRouteError(response))
      }
      if (response.status === 'success') {
        dispatch(createNewRouteSuccess(response.data))
        dispatch(getRoutes())
      }
    })
  }
}

export const CREATE_NEW_ROUTE_SUCCESS = 'CREATE_NEW_ROUTE_SUCCESS'
export const createNewRouteSuccess = createAction(CREATE_NEW_ROUTE_SUCCESS)

export const CREATE_NEW_ROUTE_ERROR = 'CREATE_NEW_ROUTE_ERROR'
export const createNewRouteError = createAction(CREATE_NEW_ROUTE_ERROR)

export const RESET_ROUTE_FORM = 'RESET_ROUTE_FORM'
export const resetRouteForm = createAction(RESET_ROUTE_FORM)

export const OPEN_ROUTE_DIALOG = 'OPEN_ROUTE_DIALOG'
export const openRouteDialog = createAction(OPEN_ROUTE_DIALOG)

export const CLOSE_ROUTE_DIALOG = 'CLOSE_ROUTE_DIALOG'
export const closeRouteDialog = createAction(CLOSE_ROUTE_DIALOG)

export const EDIT_ROUTE = 'EDIT_ROUTE'
export const editRoute = (data) => {
  return (dispatch, getState) => {
    dispatch(createAction(CREATE_NEW_ROUTE)())
    const routeData = data
    routeData.accessToken = getState().auth.account.accessToken
    sendApiRequest(`Routes/${data.routeCode}`, routeData, 'patch').then((response) => {
      if (response.status === 'failure') {
        dispatch(createNewRouteError(response))
      }
      if (response.status === 'success') {
        dispatch(createNewRouteSuccess(response.data))
        dispatch(getRoutes())
      }
    })
  }
}

const initialState = {
  routeCount: 0,
  isGettingRoutes: false,
  hasGottenRoutes: false,
  getRoutesError: {},
  routes: [],
  selectedRoute: {},
  isGettingRoute: false,
  hasGottenRoute: false,
  getRouteError: {},
  isDeletingRoute: false,
  hasDeletedRoute: false,
  deleteRouteSuccess: {},
  deleteRouteError: {},
  isCreatingRoute: false,
  hasCreatedRoute: false,
  createRouteSuccess: {},
  createRouteError: {},
  routeDialogIsOpen: false,
  selectedRouteToEdit: {}
}

export default handleActions({

  [GET_ROUTE_COUNT_SUCCESS]: (state, { payload }) => ({
    ...state,
    routeCount: payload
  }),

  [GET_ROUTES]: (state) => ({
    ...state,
    isGettingRoutes: true,
    hasGottenRoutes: false
  }),

  [GET_ROUTES_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isGettingRoutes: false,
      hasGottenRoutes: true,
      routes: payload
    }
  },

  [GET_ROUTES_ERROR]: (state, { payload }) => {
    return {
      ...state,
      isGettingRoutes: false,
      hasGottenRoutes: false,
      routesError: payload
    }
  },

  [GET_ROUTE_BY_ID]: (state) => ({
    ...state,
    isGettingRoute: true,
    hasGottenRoute: false
  }),

  [GET_ROUTE_BY_ID_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isGettingRoute: false,
      hasGottenRoute: true,
      selectedRoute: payload
    }
  },

  [GET_ROUTE_BY_ID_ERROR]: (state, { payload }) => {
    return {
      ...state,
      isGettingRoute: false,
      hasGottenRoute: false,
      getRouteError: payload
    }
  },
  [GET_ROUTE_BY_ID_RESET]: (state) => ({
    ...state,
    isGettingRoute: false,
    hasGottenRoute: false,
    selectedRoute: {}
  }),

  [DELETE_ROUTE_BY_ID]: (state) => ({
    ...state,
    isDeletingRoute: true,
    hasDeletedRoute: false
  }),

  [DELETE_ROUTE_BY_ID_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isDeletingRoute: false,
      hasDeletedRoute: true,
      deleteRouteSuccess: payload
    }
  },

  [DELETE_ROUTE_BY_ID_ERROR]: (state, { payload }) => {
    return {
      ...state,
      isDeletingRoute: false,
      hasDeletedRoute: false,
      deleteRouteError: payload
    }
  },

  [DELETE_ROUTE_BY_ID_RESET]: (state) => {
    return {
      ...state,
      isDeletingRoute: false,
      hasDeletedRoute: false,
      deleteRouteError: {},
      deleteRouteSuccess: {}
    }
  },

  [OPEN_ROUTE_DIALOG]: (state, { payload }) => {
    return {
      ...state,
      routeDialogIsOpen: true,
      selectedRouteToEdit: payload
    }
  },

  [CLOSE_ROUTE_DIALOG]: (state) => {
    return {
      ...state,
      routeDialogIsOpen: false,
      selectedRouteToEdit: {},
      isCreatingRoute: false,
      hasCreatedRoute: false,
      createRouteSuccess: {},
      createRouteError: {}
    }
  },

  [CREATE_NEW_ROUTE]: (state) => {
    return {
      ...state,
      isCreatingRoute: true,
      hasCreatedRoute: false,
      createRouteSuccess: {},
      createRouteError: {}
    }
  },

  [CREATE_NEW_ROUTE_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isCreatingRoute: false,
      hasCreatedRoute: true,
      createRouteSuccess: payload
    }
  },

  [CREATE_NEW_ROUTE_ERROR]: (state, { payload }) => {
    return {
      ...state,
      isCreatingRoute: false,
      hasCreatedRoute: false,
      createRouteError: payload
    }
  },

}, initialState)
