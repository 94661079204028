import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { browserHistory } from 'react-router'
import { Button, Dialog, DialogContent, DialogActions } from '@mui/material'
import {
  closeSuccessDialog
} from 'store/modules/site'

const propTypes = {
  // params: PropTypes.object,
  successDialogIsOpen: PropTypes.bool,
  successMessage: PropTypes.string,
  successRedirect: PropTypes.string,
  successInitialUrl: PropTypes.string,
  closeSuccessDialog: PropTypes.func
}

class SuccessDialog extends Component {
  constructor (props) {
    super(props)
    this.state = {
      successInitialUrl: '',
      successRedirect: ''
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.successInitialUrl !== prevProps.successInitialUrl && this.props.successInitialUrl !== '') {
      this.setState({ successInitialUrl: this.props.successInitialUrl })
    }
    if (this.props.successRedirect !== prevProps.successRedirect && this.props.successRedirect !== '') {
      this.setState({ successRedirect: this.props.successRedirect })
    }
  }

  redirectSuccessDialog () {
    this.props.closeSuccessDialog()
    if (this.state.successRedirect && this.state.successRedirect !== '') {
      browserHistory.push(this.state.successRedirect)
    }
  }

  closeSuccessDialog () {
    this.props.closeSuccessDialog()
    if (this.state.successInitialUrl && this.state.successInitialUrl !== '') {
      browserHistory.push(this.state.successInitialUrl)
    }
  }

  render () {
    const {
      successDialogIsOpen,
      successMessage
    } = this.props
    return (
      <Dialog
        open={successDialogIsOpen}
        onClose={() => {
          this.closeSuccessDialog()
        }}
      >
        <DialogContent>
          {successMessage}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.redirectSuccessDialog() }>OK</Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = (state) => {
  const {
    successDialogIsOpen,
    successMessage,
    successRedirect
  } = state.site
  return {
    successDialogIsOpen,
    successMessage,
    successRedirect
  }
}
const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({
    closeSuccessDialog
  }, dispatch),
})

SuccessDialog.propTypes = propTypes

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SuccessDialog)
