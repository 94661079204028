import { createAction, handleActions } from 'redux-actions'
import { push } from 'react-router-redux'
import { sendApiRequest } from 'utils/api'
// import { getPermissions } from 'utils/utils'
import { sessionService } from 'redux-react-session'
import { getClientId } from 'utils/utils'

export const REGISTER = 'REGISTER'
export const register = (data) => {
  return (dispatch) => {
    dispatch(createAction(REGISTER)())
    sendApiRequest('Users', data, 'post').then((response) => {
      if (response.status === 'failure') {
        dispatch(registerError(response))
      }
      if (response.status === 'success') {
        dispatch(registerSuccess(response))
      }
    })
  }
}

export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const registerSuccess = createAction(REGISTER_SUCCESS)

export const REGISTER_ERROR = 'REGISTER_ERROR'
export const registerError = createAction(REGISTER_ERROR)

export const LOGIN = 'LOGIN'
export const login = (data) => {
  return (dispatch) => {
    dispatch(createAction(LOGIN)())
    sendApiRequest('Accounts/login', data, 'post').then((response) => {
      if (response && response.status === 'success') {
        dispatch(loginSuccess(response.data))
        dispatch(getUserData(response.data))
        sessionService.saveSession({ id: response.data.id, userId: response.data.userId })
        dispatch(push('/content/dashboard'))
      } else {
        dispatch(loginError(response))
      }
    })
  }
}

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const loginSuccess = createAction(LOGIN_SUCCESS)

export const LOGIN_ERROR = 'LOGIN_ERROR'
export const loginError = (data) => {
  return (dispatch) => {
    dispatch(createAction(LOGIN_ERROR)(data))
    sessionService.deleteSession()
    sessionService.deleteUser()
  }
}

export const GET_USER_DATA = 'GET_USER_DATA'
export const getUserData = (data) => {
  return (dispatch) => {
    dispatch(createAction(GET_USER_DATA)())
    sendApiRequest(`Accounts/${data.userId}`, {
      accessToken: data.id
    }, 'get').then((response) => {
      if (response.status === 'success') {
        dispatch(getUserDataSuccess(response.data))
        sessionService.saveUser(response.data)
        if (response.data.clientRestricted === true &&
          response.data.accountClients &&
          response.data.accountClients[0] &&
          response.data.accountClients[0].clientId) {
          dispatch(getNetwork(response.data.accountClients[0].clientId))
        } else if (response.data.userClientId) {
          dispatch(getNetwork(response.data.userClientId))
        }
      }
    })
  }
}

export const GET_USER_DATA_SUCCESS = 'GET_USER_DATA_SUCCESS'
export const getUserDataSuccess = createAction(GET_USER_DATA_SUCCESS)

export const GET_NETWORK = 'GET_NETWORK'
export const getNetwork = (networkId) => {
  return (dispatch, getState) => {
    dispatch(createAction(GET_NETWORK)())
    sendApiRequest(`Clients/${networkId}`, {
      accessToken: getState().auth.account.accessToken
    }, 'get').then((response) => {
      if (response.status === 'success') {
        dispatch(getNetworkSuccess(response.data))
        dispatch(getMergedPermissions())
      }
    })
  }
}

export const GET_NETWORK_SUCCESS = 'GET_NETWORK_SUCCESS'
export const getNetworkSuccess = createAction(GET_NETWORK_SUCCESS)

export const LOGOUT = 'LOGOUT'
export const logout = () => {
  return (dispatch, getState) => {
    const accessToken = getState().auth.account.accessToken
    dispatch(createAction(LOGOUT)())
    sendApiRequest('Accounts/logout', {
      accessToken
    }, 'post').then(() => {
      sessionService.deleteSession()
      sessionService.deleteUser()
      dispatch(push('/login'))
    })
  }
}

export const GET_MERGED_PERMISSIONS = 'GET_MERGED_PERMISSIONS'
export const getMergedPermissions = () => {
  return (dispatch, getState) => {
    const clientId = getClientId(getState)
    sendApiRequest(`Accounts/Permissions/${clientId}`, {
      accessToken: getState().auth.account.accessToken
    }, 'get').then((response) => {
      if (response.status === 'success') {
        dispatch(createAction(GET_MERGED_PERMISSIONS)(response.data.data))
      }
    })
  }
}

export const GET_MERGED_PERMISSION_KEYS = 'GET_MERGED_PERMISSION_KEYS'
export const getMergedPermissionKeys = () => {
  return (dispatch, getState) => {
    const clientId = getClientId(getState)
    sendApiRequest(`Accounts/Permissions/${clientId}`, {
      accessToken: getState().auth.account.accessToken
    }, 'get').then((response) => {
      if (response.status === 'success') {
        if (response.data && response.data.data) {
          const permKeys = response.data.data.map((perm) => {
            return perm.permKey
          })
          dispatch(createAction(GET_MERGED_PERMISSION_KEYS)(permKeys))
        }
      }
    })
  }
}

export const GET_CURRENT_CLIENT_ID = 'GET_CURRENT_CLIENT_ID'
export const getCurrentClientId = () => {
  return (dispatch, getState) => {
    const clientId = getClientId(getState)
    dispatch(createAction(GET_CURRENT_CLIENT_ID)(clientId))
  }
}

export const GET_CURRENT_CLIENT_INFO = 'GET_CURRENT_CLIENT_INFO'
export const getCurrentClientInfo = () => {
  return (dispatch, getState) => {
    const clientId = getClientId(getState)
    if (clientId !== 0 && getState().auth.account.accessToken) {
      sendApiRequest(`Clients/${clientId}`, {
        accessToken: getState().auth.account.accessToken
      }, 'get').then((response) => {
        if (response.status === 'success') {
          if (response.data) {
            dispatch(createAction(GET_CURRENT_CLIENT_INFO)(response.data))
          }
        }
      })
    }
  }
}

export const RESET_PASSWORD = 'RESET_PASSWORD'
export const resetPassword = (data) => {
  return (dispatch) => {
    dispatch(createAction(RESET_PASSWORD)())
    sendApiRequest('Accounts/password-reset', data, 'post').then((response) => {
      if (response.status === 'failure') {
        dispatch(resetPasswordError(response))
      }
      if (response.status === 'success') {
        dispatch(resetPasswordSuccess())
      }
    })
  }
}

export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const resetPasswordSuccess = createAction(RESET_PASSWORD_SUCCESS)

export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR'
export const resetPasswordError = createAction(RESET_PASSWORD_ERROR)

export const RESET_PASSWORD_FORM = 'RESET_PASSWORD_FORM'
export const resetPasswordForm = createAction(RESET_PASSWORD_FORM)

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const changePassword = (data) => {
  return (dispatch) => {
    dispatch(createAction(CHANGE_PASSWORD)())
    sendApiRequest('Accounts/reset-password', data, 'post').then((response) => {
      if (response.status === 'failure') {
        dispatch(changePasswordError(response))
      }
      if (response.status === 'success') {
        dispatch(changePasswordSuccess())
      }
    })
  }
}

export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS'
export const changePasswordSuccess = createAction(CHANGE_PASSWORD_SUCCESS)

export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR'
export const changePasswordError = createAction(CHANGE_PASSWORD_ERROR)

export const RESET_CHANGE_PASSWORD_FORM = 'RESET_CHANGE_PASSWORD_FORM'
export const resetChangePasswordForm = createAction(RESET_CHANGE_PASSWORD_FORM)

export const ASSUME_USER_BY_ID = 'ASSUME_USER_BY_ID'
export const assumeUserById = (id) => {
  return (dispatch, getState) => {
    dispatch(createAction(ASSUME_USER_BY_ID)())
    const data = {}
    data.accessToken = getState().auth.account.accessToken
    sendApiRequest(`Accounts/assume-user/${id}`, data, 'post').then((response) => {
      if (response.status === 'failure') {
        dispatch(loginError(response))
      }
      if (response.status === 'success') {
        dispatch(loginSuccess(response.data))
        dispatch(getUserData(response.data))
        sessionService.saveSession({ id: response.data.id, userId: response.data.userId })
        dispatch(push('/content/dashboard'))
      }
    })
  }
}

const initialState = {
  isLoggingIn: false,
  hasLoggedIn: false,
  loginErrorMsg: {},
  loginSuccessMsg: {},
  isRegistering: false,
  hasRegistered: false,
  registerError: {},
  registerSuccess: {},
  user: {},
  network: {},
  selectedNetwork: 0,
  permissions: [],
  permKeys: [],
  currentClientId: 0,
  currentClientInfo: {},
  account: {
    accessToken: ''
  },
  isResetPassword: false,
  hasResetPassword: false,
  resetPasswordError: {},
  resetPasswordSuccess: {},
  changePasswordError: {}
}

export default handleActions({

  [LOGIN]: (state) => ({
    ...state,
    isLoggingIn: true,
    hasLoggedIn: false,
    account: { accessToken: '' },
    loginErrorMsg: {}
  }),

  [LOGIN_SUCCESS]: (state, { payload }) => ({
    ...state,
    isLoggingIn: false,
    hasLoggedIn: true,
    account: { accessToken: payload.id, id: payload.userId },
    loginErrorMsg: {}
  }),

  [GET_USER_DATA_SUCCESS]: (state, { payload }) => ({
    ...state,
    user: payload,
    selectedNetwork: payload.userClientId
  }),

  [GET_NETWORK_SUCCESS]: (state, { payload }) => ({
    ...state,
    network: payload
  }),

  [LOGIN_ERROR]: (state, { payload }) => ({
    ...state,
    isLoggingIn: false,
    hasLoggedIn: false,
    account: {},
    loginErrorMsg: payload
  }),

  [REGISTER]: (state) => ({
    ...state,
    isRegistering: true,
    hasRegistered: false,
    registerError: {},
    registerSuccess: {}
  }),

  [REGISTER_SUCCESS]: (state, { payload }) => ({
    ...state,
    isRegistering: false,
    hasRegistered: true,
    registerError: {},
    registerSuccess: payload
  }),

  [REGISTER_ERROR]: (state, { payload }) => ({
    ...state,
    isRegistering: false,
    hasRegistered: false,
    registerError: payload,
    registerSuccess: {}
  }),

  [LOGOUT]: (state) => ({
    ...state,
    isLoggingIn: false,
    hasLoggedIn: false,
    account: { accessToken: '' },
    loginErrorMsg: {}
  }),

  [GET_MERGED_PERMISSIONS]: (state, { payload }) => ({
    ...state,
    permissions: payload
  }),

  [GET_MERGED_PERMISSION_KEYS]: (state, { payload }) => ({
    ...state,
    permKeys: payload
  }),

  [GET_CURRENT_CLIENT_ID]: (state, { payload }) => ({
    ...state,
    currentClientId: payload
  }),

  [GET_CURRENT_CLIENT_INFO]: (state, { payload }) => ({
    ...state,
    currentClientInfo: payload
  }),

  [RESET_PASSWORD]: (state) => ({
    ...state,
    isResetPassword: true,
    hasResetPassword: false,
    resetPasswordError: {},
    resetPasswordSuccess: {}
  }),

  [RESET_PASSWORD_ERROR]: (state, { payload }) => ({
    ...state,
    isResetPassword: false,
    hasResetPassword: false,
    resetPasswordError: payload,
    resetPasswordSuccess: {}
  }),

  [RESET_PASSWORD_SUCCESS]: (state) => ({
    ...state,
    isResetPassword: false,
    hasResetPassword: true,
    resetPasswordError: {},
    resetPasswordSuccess: {}
  }),

  [RESET_PASSWORD_FORM]: (state) => ({
    ...state,
    isResetPassword: false,
    hasResetPassword: false,
    resetPasswordError: {},
    resetPasswordSuccess: {}
  }),

  [CHANGE_PASSWORD]: (state) => ({
    ...state,
    isChangingPassword: true,
    hasChangedPassword: false,
    changePasswordError: {},
    changePasswordSuccess: {}
  }),

  [CHANGE_PASSWORD_ERROR]: (state, { payload }) => ({
    ...state,
    isChangingPassword: false,
    hasChangedPassword: false,
    changePasswordError: payload,
    changePasswordSuccess: {}
  }),

  [CHANGE_PASSWORD_SUCCESS]: (state) => ({
    ...state,
    isChangingPassword: false,
    hasChangedPassword: true,
    changePasswordError: {},
    changePasswordSuccess: {}
  }),

  [RESET_CHANGE_PASSWORD_FORM]: (state) => ({
    ...state,
    isChangingPassword: false,
    hasChangedPassword: false,
    changePasswordError: {},
    changePasswordSuccess: {}
  }),
}, initialState)
