import { createAction, handleActions } from 'redux-actions'
import { sendApiRequest, sendApiFileRequest } from 'utils/api'
import { getClientId } from 'utils/utils'

export const GET_NETWORK_REPORT_FULL_COUNT = 'GET_NETWORK_REPORT_FULL_COUNT'
export const getNetworkReportFullCount = (month, year) => {
  return (dispatch, getState) => {
    dispatch(createAction(GET_NETWORK_REPORT_FULL_COUNT)())
    const clientId = getClientId(getState)
    sendApiRequest(`NetworkReports/count/${clientId}/date/${month}/${year}`, {
      accessToken: getState().auth.account.accessToken
    }, 'get').then((response) => {
      if (response.status === 'failure') {
        dispatch(getNetworkReportFullCountError(response))
      }
      if (response.status === 'success') {
        dispatch(getNetworkReportFullCountSuccess(response.data.count))
      }
    })
  }
}

export const GET_NETWORK_REPORT_FULL_COUNT_SUCCESS = 'GET_NETWORK_REPORT_FULL_COUNT_SUCCESS'
export const getNetworkReportFullCountSuccess = createAction(GET_NETWORK_REPORT_FULL_COUNT_SUCCESS)

export const GET_NETWORK_REPORT_FULL_COUNT_ERROR = 'GET_NETWORK_REPORT_FULL_COUNT_ERROR'
export const getNetworkReportFullCountError = createAction(GET_NETWORK_REPORT_FULL_COUNT_ERROR)

export const GET_NETWORK_REPORT_COUNT = 'GET_NETWORK_REPORT_COUNT'
export const getNetworkReportCount = (month, year, search) => {
  return (dispatch, getState) => {
    dispatch(createAction(GET_NETWORK_REPORT_COUNT)())
    const clientId = getClientId(getState)
    const data = {
      filter: {
        where: {}
      }
    }
    if (search) {
      data.filter.where = search
    }
    sendApiRequest(`NetworkReports/count/${clientId}/date/${month}/${year}`, {
      accessToken: getState().auth.account.accessToken
    }, 'get', data).then((response) => {
      if (response.status === 'failure') {
        dispatch(getNetworkReportCountError(response))
      }
      if (response.status === 'success') {
        dispatch(getNetworkReportCountSuccess(response.data.count))
      }
    })
  }
}

export const GET_NETWORK_REPORT_COUNT_SUCCESS = 'GET_NETWORK_REPORT_COUNT_SUCCESS'
export const getNetworkReportCountSuccess = createAction(GET_NETWORK_REPORT_COUNT_SUCCESS)

export const GET_NETWORK_REPORT_COUNT_ERROR = 'GET_NETWORK_REPORT_COUNT_ERROR'
export const getNetworkReportCountError = createAction(GET_NETWORK_REPORT_COUNT_ERROR)

export const GET_NETWORK_REPORTS = 'GET_NETWORK_REPORTS'
export const getNetworkReports = (month, year, pageSize, pageNumber, search) => {
  return (dispatch, getState) => {
    dispatch(createAction(GET_NETWORK_REPORTS)())
    const options = {
      filter: {
      }
    }
    if (pageSize !== 0) {
      options.filter.limit = pageSize
    } else {
      options.filter.limit = 0
    }
    if (pageNumber !== 1) {
      options.filter.skip = (pageNumber - 1) * pageSize
    } else {
      options.filter.skip = 0
    }
    if (search) {
      options.filter.where = search
      if (Object.prototype.hasOwnProperty.call(search, 'unlocks')) {
        options.filter.unlocks = search.unlocks
        delete options.filter.where.unlocks
      }
    }
    const clientId = getClientId(getState)
    sendApiRequest(`NetworkReports/${clientId}/date/${month}/${year}`, {
      accessToken: getState().auth.account.accessToken
    }, 'get', options).then((response) => {
      if (response.status === 'failure') {
        dispatch(getNetworkReportsError(response))
      }
      if (response.status === 'success') {
        dispatch(getNetworkReportsSuccess(response.data))
      }
    })
  }
}

export const GET_NETWORK_REPORTS_SUCCESS = 'GET_NETWORK_REPORTS_SUCCESS'
export const getNetworkReportsSuccess = createAction(GET_NETWORK_REPORTS_SUCCESS)

export const GET_NETWORK_REPORTS_ERROR = 'GET_NETWORK_REPORTS_ERROR'
export const getNetworkReportsError = createAction(GET_NETWORK_REPORTS_ERROR)

export const GET_NETWORK_REPORT_DATA = 'GET_NETWORK_REPORT_DATA'
export const getNetworkReportData = (month, year, filter) => {
  return (dispatch, getState) => {
    dispatch(createAction(GET_NETWORK_REPORT_DATA)())
    const clientId = getClientId(getState)
    const options = {
      filter: {
        limit: 0,
        skip: 0
      }
    }
    if (filter && filter.unlocks) {
      options.filter.unlocks = filter.unlocks
      // options.filter.where.unlocks = filter.unlocks
      // delete options.filter.where.unlocks
    }
    sendApiRequest(`NetworkReports/${clientId}/date/${month}/${year}`, {
      accessToken: getState().auth.account.accessToken
    }, 'get', options).then((response) => {
      if (response.status === 'failure') {
        dispatch(getNetworkReportDataError(response))
      }
      if (response.status === 'success') {
        dispatch(getNetworkReportDataSuccess(response.data))
      }
    })
  }
}

export const GET_NETWORK_REPORT_DATA_SUCCESS = 'GET_NETWORK_REPORT_DATA_SUCCESS'
export const getNetworkReportDataSuccess = createAction(GET_NETWORK_REPORT_DATA_SUCCESS)

export const GET_NETWORK_REPORT_DATA_ERROR = 'GET_NETWORK_REPORT_DATA_ERROR'
export const getNetworkReportDataError = createAction(GET_NETWORK_REPORT_DATA_ERROR)

export const GET_NETWORK_REPORT_DATA_BY_TYPE = 'GET_NETWORK_REPORT_DATA_BY_TYPE'
export const getNetworkReportDataByType = (type, month, year) => {
  return (dispatch, getState) => {
    dispatch(createAction(GET_NETWORK_REPORT_DATA_BY_TYPE)())
    const clientId = getClientId(getState)
    const options = {
      filter: {
        limit: 0,
        skip: 0
      }
    }
    sendApiRequest(`NetworkReports/find/${clientId}/${type}/${month}/${year}`, {
      accessToken: getState().auth.account.accessToken
    }, 'get', options).then((response) => {
      if (response.status === 'failure') {
        dispatch(getNetworkReportDataByTypeError(response))
      }
      if (response.status === 'success') {
        dispatch(getNetworkReportDataByTypeSuccess(response.data))
      }
    })
  }
}

export const GET_NETWORK_REPORT_DATA_BY_TYPE_SUCCESS = 'GET_NETWORK_REPORT_DATA_BY_TYPE_SUCCESS'
export const getNetworkReportDataByTypeSuccess = createAction(GET_NETWORK_REPORT_DATA_BY_TYPE_SUCCESS)

export const GET_NETWORK_REPORT_DATA_BY_TYPE_ERROR = 'GET_NETWORK_REPORT_DATA_BY_TYPE_ERROR'
export const getNetworkReportDataByTypeError = createAction(GET_NETWORK_REPORT_DATA_BY_TYPE_ERROR)

export const GET_NETWORK_REPORTS_BY_TYPE = 'GET_NETWORK_REPORTS_BY_TYPE'
export const getNetworkReportsByType = (type) => {
  return (dispatch, getState) => {
    dispatch(createAction(GET_NETWORK_REPORTS_BY_TYPE)())
    const clientId = getClientId(getState)
    sendApiRequest(`NetworkReports/${clientId}/${type}`, {
      accessToken: getState().auth.account.accessToken
    }, 'get').then((response) => {
      if (response.status === 'failure') {
        dispatch(getNetworkReportsByTypeError(response))
      }
      if (response.status === 'success') {
        dispatch(getNetworkReportsByTypeSuccess(response.data))
      }
    })
  }
}

export const GET_NETWORK_REPORTS_BY_TYPE_SUCCESS = 'GET_NETWORK_REPORTS_BY_TYPE_SUCCESS'
export const getNetworkReportsByTypeSuccess = createAction(GET_NETWORK_REPORTS_BY_TYPE_SUCCESS)

export const GET_NETWORK_REPORTS_BY_TYPE_ERROR = 'GET_NETWORK_REPORTS_BY_TYPE_ERROR'
export const getNetworkReportsByTypeError = createAction(GET_NETWORK_REPORTS_BY_TYPE_ERROR)

export const GET_ASSET_AUDIT_BY_ID = 'GET_ASSET_AUDIT_BY_ID'
export const getAssetAuditById = (id) => {
  return (dispatch, getState) => {
    dispatch(createAction(GET_ASSET_AUDIT_BY_ID)())
    sendApiRequest(`AssetAudits/${id}`, {
      accessToken: getState().auth.account.accessToken
    }, 'get', { filter: { include: 'work' } }).then((response) => {
      if (response.status === 'failure') {
        dispatch(getAssetAuditByIdError(response))
      }
      if (response.status === 'success') {
        dispatch(getAssetAuditByIdSuccess(response.data))
      }
    })
  }
}

export const GET_ASSET_AUDIT_BY_ID_SUCCESS = 'GET_ASSET_AUDIT_BY_ID_SUCCESS'
export const getAssetAuditByIdSuccess = createAction(GET_ASSET_AUDIT_BY_ID_SUCCESS)

export const GET_ASSET_AUDIT_BY_ID_ERROR = 'GET_ASSET_AUDIT_BY_ID_ERROR'
export const getAssetAuditByIdError = createAction(GET_ASSET_AUDIT_BY_ID_ERROR)

export const EDIT_ASSET_AUDIT_BY_ID = 'EDIT_ASSET_AUDIT_BY_ID'
export const editAssetAuditById = (data) => {
  return (dispatch, getState) => {
    data.accessToken = getState().auth.account.accessToken
    dispatch(createAction(EDIT_ASSET_AUDIT_BY_ID)())
    if (data.photosToDelete && data.photosToDelete.length > 0) {
      data.photosToDelete.forEach((photo) => {
        dispatch(deleteAuditPhoto(photo))
      })
    }
    if (data.workStatusId) {
      dispatch(updateWorkStatusForAudit(data.assetAuditId, data.workStatusId))
    }
    sendApiRequest(`AssetAudits/${data.assetAuditId}`, data, 'patch').then((response) => {
      if (response.status === 'failure') {
        dispatch(editAssetAuditByIdError(response))
      }
      if (response.status === 'success') {
        dispatch(editAssetAuditByIdSuccess(response.data))
      }
    })
  }
}

export const EDIT_ASSET_AUDIT_BY_ID_SUCCESS = 'EDIT_ASSET_AUDIT_BY_ID_SUCCESS'
export const editAssetAuditByIdSuccess = createAction(EDIT_ASSET_AUDIT_BY_ID_SUCCESS)

export const EDIT_ASSET_AUDIT_BY_ID_ERROR = 'GET_ASSET_AUDIT_BY_ID_ERROR'
export const editAssetAuditByIdError = createAction(EDIT_ASSET_AUDIT_BY_ID_ERROR)

export const RESET_ASSET_AUDIT_FORM = 'RESET_ASSET_AUDIT_FORM'
export const resetAssetAuditForm = createAction(RESET_ASSET_AUDIT_FORM)

export const UPLOAD_AUDIT_PHOTO_FILE = 'UPLOAD_AUDIT_PHOTO_FILE'
export const uploadAuditPhotoFile = (data, auditId) => {
  return (dispatch, getState) => {
    dispatch(createAction(UPLOAD_AUDIT_PHOTO_FILE)())
    const assetData = data
    assetData.accessToken = getState().auth.account.accessToken
    sendApiFileRequest(`AuditPhotos/${auditId}/file`, assetData, 'post').then((response) => {
      if (response.status === 'failure') {
        dispatch(uploadAuditPhotoFileError(response))
      }
      if (response.status === 'success') {
        dispatch(uploadAuditPhotoFileSuccess(response.data))
      }
    })
  }
}

export const UPLOAD_AUDIT_PHOTO_FILE_SUCCESS = 'UPLOAD_AUDIT_PHOTO_FILE_SUCCESS'
export const uploadAuditPhotoFileSuccess = createAction(UPLOAD_AUDIT_PHOTO_FILE_SUCCESS)

export const UPLOAD_AUDIT_PHOTO_FILE_ERROR = 'UPLOAD_AUDIT_PHOTO_FILE_ERROR'
export const uploadAuditPhotoFileError = createAction(UPLOAD_AUDIT_PHOTO_FILE_ERROR)

export const DELETE_AUDIT_PHOTO = 'DELETE_AUDIT_PHOTO'
export const deleteAuditPhoto = (auditPhotoId) => {
  return (dispatch, getState) => {
    dispatch(createAction(DELETE_AUDIT_PHOTO)())
    sendApiRequest(`AuditPhotos/${auditPhotoId}`, {
      accessToken: getState().auth.account.accessToken
    }, 'delete').then((response) => {
      if (response.status === 'failure') {
        dispatch(deleteAuditPhotoError(response))
      }
      if (response.status === 'success') {
        dispatch(deleteAuditPhotoSuccess(response.data))
      }
    })
  }
}

export const DELETE_AUDIT_PHOTO_SUCCESS = 'DELETE_AUDIT_PHOTO_SUCCESS'
export const deleteAuditPhotoSuccess = createAction(DELETE_AUDIT_PHOTO_SUCCESS)

export const DELETE_AUDIT_PHOTO_ERROR = 'DELETE_AUDIT_PHOTO_ERROR'
export const deleteAuditPhotoError = createAction(DELETE_AUDIT_PHOTO_ERROR)

export const UPDATE_WORK_STATUS_FOR_AUDIT = 'UPDATE_WORK_STATUS_FOR_AUDIT'
export const updateWorkStatusForAudit = (auditId, workStatusId) => {
  return (dispatch, getState) => {
    dispatch(createAction(UPDATE_WORK_STATUS_FOR_AUDIT)())
    sendApiRequest(`AssetAudits/${auditId}/work`, {
      accessToken: getState().auth.account.accessToken,
      workStatusId
    }, 'put').then((response) => {
      if (response.status === 'failure') {
        dispatch(updateWorkStatusForAuditError(response))
      }
      if (response.status === 'success') {
        dispatch(updateWorkStatusForAuditSuccess(response.data))
      }
    })
  }
}

export const UPDATE_WORK_STATUS_FOR_AUDIT_SUCCESS = 'UPDATE_WORK_STATUS_FOR_AUDIT_SUCCESS'
export const updateWorkStatusForAuditSuccess = createAction(UPDATE_WORK_STATUS_FOR_AUDIT_SUCCESS)

export const UPDATE_WORK_STATUS_FOR_AUDIT_ERROR = 'UPDATE_WORK_STATUS_FOR_AUDIT_ERROR'
export const updateWorkStatusForAuditError = createAction(UPDATE_WORK_STATUS_FOR_AUDIT_ERROR)

export const GET_UNLOCKED_STATE = 'GET_UNLOCKED_STATE'
export const getUnlockedState = (data) => {
  return (dispatch, getState) => {
    dispatch(createAction(GET_UNLOCKED_STATE)())
    const unlockData = data
    unlockData.accessToken = getState().auth.account.accessToken
    unlockData.clientId = getClientId(getState)
    sendApiRequest('Unlocks/state', unlockData, 'post').then((response) => {
      if (response.status === 'failure') {
        dispatch(getUnlockedStateError(response))
      }
      if (response.status === 'success') {
        dispatch(getUnlockedStateSuccess(response.data))
      }
    })
  }
}

export const GET_UNLOCKED_STATE_SUCCESS = 'GET_UNLOCKED_STATE_SUCCESS'
export const getUnlockedStateSuccess = createAction(GET_UNLOCKED_STATE_SUCCESS)

export const GET_UNLOCKED_STATE_ERROR = 'GET_UNLOCKED_STATE_ERROR'
export const getUnlockedStateError = createAction(GET_UNLOCKED_STATE_ERROR)

export const GET_UNLOCKS_BY_DATE = 'GET_UNLOCKS_BY_DATE'
export const getUnlocksByDate = (data) => {
  return (dispatch, getState) => {
    dispatch(createAction(GET_UNLOCKS_BY_DATE)())
    const unlockData = data
    unlockData.accessToken = getState().auth.account.accessToken
    unlockData.clientId = getClientId(getState)
    sendApiRequest('Unlocks/unlocksByDate', unlockData, 'post').then((response) => {
      if (response.status === 'failure') {
        dispatch(getUnlocksByDateError(response))
      }
      if (response.status === 'success') {
        dispatch(getUnlocksByDateSuccess(response.data))
      }
    })
  }
}

export const GET_UNLOCKS_BY_DATE_SUCCESS = 'GET_UNLOCKS_BY_DATE_SUCCESS'
export const getUnlocksByDateSuccess = createAction(GET_UNLOCKS_BY_DATE_SUCCESS)

export const GET_UNLOCKS_BY_DATE_ERROR = 'GET_UNLOCKED_STATE_ERROR'
export const getUnlocksByDateError = createAction(GET_UNLOCKS_BY_DATE_ERROR)

export const UNLOCK_BY_CLIENT_AND_DATE = 'UNLOCK_BY_CLIENT_AND_DATE'
export const unlockByClientAndDate = (data) => {
  return (dispatch, getState) => {
    dispatch(createAction(UNLOCK_BY_CLIENT_AND_DATE)())
    const unlockData = data
    unlockData.accessToken = getState().auth.account.accessToken
    unlockData.clientId = getClientId(getState)
    sendApiRequest('Unlocks/unlock', unlockData, 'post').then((response) => {
      if (response.status === 'failure') {
        dispatch(unlockByClientAndDateError(response))
      }
      if (response.status === 'success') {
        dispatch(unlockByClientAndDateSuccess(response.data))
      }
    })
  }
}

export const UNLOCK_BY_CLIENT_AND_DATE_SUCCESS = 'UNLOCK_BY_CLIENT_AND_DATE_SUCCESS'
export const unlockByClientAndDateSuccess = createAction(UNLOCK_BY_CLIENT_AND_DATE_SUCCESS)

export const UNLOCK_BY_CLIENT_AND_DATE_ERROR = 'UNLOCK_BY_CLIENT_AND_DATE_ERROR'
export const unlockByClientAndDateError = createAction(UNLOCK_BY_CLIENT_AND_DATE_ERROR)

export const GET_ASSET_AUDIT_CATEGORIES = 'GET_ASSET_AUDIT_CATEGORIES'
export const getAssetAuditCategories = () => {
  return (dispatch, getState) => {
    dispatch(createAction(GET_ASSET_AUDIT_CATEGORIES)())
    sendApiRequest('Mobile/AssetAudit/FaultCategories', {
      accessToken: getState().auth.account.accessToken
    }, 'get').then((response) => {
      if (response.status === 'failure') {
        dispatch(getAssetAuditCategoriesError(response))
      }
      if (response.status === 'success') {
        dispatch(getAssetAuditCategoriesSuccess(response.data.data))
      }
    })
  }
}

export const GET_ASSET_AUDIT_CATEGORIES_SUCCESS = 'GET_ASSET_AUDIT_CATEGORIES_SUCCESS'
export const getAssetAuditCategoriesSuccess = createAction(GET_ASSET_AUDIT_CATEGORIES_SUCCESS)

export const GET_ASSET_AUDIT_CATEGORIES_ERROR = 'GET_ASSET_AUDIT_CATEGORIES_ERROR'
export const getAssetAuditCategoriesError = createAction(GET_ASSET_AUDIT_CATEGORIES_ERROR)

export const DELETE_ASSET_AUDIT_BY_ID = 'DELETE_ASSET_AUDIT_BY_ID'
export const deleteAssetAuditById = (auditId) => {
  return (dispatch, getState) => {
    dispatch(createAction(DELETE_ASSET_AUDIT_BY_ID)())
    sendApiRequest(`AssetAudits/${auditId}`, {
      accessToken: getState().auth.account.accessToken
    }, 'delete').then((response) => {
      if (response.status === 'failure') {
        dispatch(deleteAssetAuditByIdError(response))
      }
      if (response.status === 'success') {
        dispatch(deleteAssetAuditByIdSuccess(response.data))
      }
    })
  }
}

export const DELETE_ASSET_AUDIT_BY_ID_SUCCESS = 'DELETE_ASSET_AUDIT_BY_ID_SUCCESS'
export const deleteAssetAuditByIdSuccess = createAction(DELETE_ASSET_AUDIT_BY_ID_SUCCESS)

export const DELETE_ASSET_AUDIT_BY_ID_ERROR = 'DELETE_AUDIT_PHOTO_ERROR'
export const deleteAssetAuditByIdError = createAction(DELETE_ASSET_AUDIT_BY_ID_ERROR)

export const GET_NETWORK_REPORTS_BY_TYPE_AND_DATE_RANGE = 'GET_NETWORK_REPORTS_BY_TYPE_AND_DATE_RANGE'
export const getNetworkReportsByTypeAndDateRange = (type, month, year) => {
  return (dispatch, getState) => {
    dispatch(createAction(GET_NETWORK_REPORTS_BY_TYPE_AND_DATE_RANGE)())
    const clientId = getClientId(getState)
    sendApiRequest(`NetworkReports/find/${clientId}/${type}/${month}/${year}`, {
      accessToken: getState().auth.account.accessToken
    }, 'get').then((response) => {
      if (response.status === 'failure') {
        dispatch(getNetworkReportsByTypeAndDateRangeError(response))
      }
      if (response.status === 'success') {
        dispatch(getNetworkReportsByTypeAndDateRangeSuccess(response.data))
      }
    })
  }
}

export const GET_NETWORK_REPORTS_BY_TYPE_AND_DATE_RANGE_SUCCESS = 'GET_NETWORK_REPORTS_BY_TYPE_AND_DATE_RANGE_SUCCESS'
export const getNetworkReportsByTypeAndDateRangeSuccess =
createAction(GET_NETWORK_REPORTS_BY_TYPE_AND_DATE_RANGE_SUCCESS)

export const GET_NETWORK_REPORTS_BY_TYPE_AND_DATE_RANGE_ERROR = 'GET_NETWORK_REPORTS_BY_TYPE_AND_DATE_RANGE_ERROR'
export const getNetworkReportsByTypeAndDateRangeError = createAction(GET_NETWORK_REPORTS_BY_TYPE_AND_DATE_RANGE_ERROR)

export const GET_UNAUDITED_ASSETS_BY_DATE_RANGE = 'GET_UNAUDITED_ASSETS_BY_DATE_RANGE'
export const getUnauditedAssetsByDateRange = (month, year) => {
  return (dispatch, getState) => {
    dispatch(createAction(GET_UNAUDITED_ASSETS_BY_DATE_RANGE)())
    const clientId = getClientId(getState)
    sendApiRequest(`NetworkReports/unauditedAssetsReport/${clientId}/date/${month}/${year}`, {
      accessToken: getState().auth.account.accessToken
    }, 'get').then((response) => {
      if (response.status === 'failure') {
        dispatch(getUnauditedAssetsByDateRangeError(response))
      }
      if (response.status === 'success') {
        dispatch(getUnauditedAssetsByDateRangeSuccess(response.data))
      }
    })
  }
}

export const GET_UNAUDITED_ASSETS_BY_DATE_RANGE_SUCCESS = 'GET_UNAUDITED_ASSETS_BY_DATE_RANGE_SUCCESS'
export const getUnauditedAssetsByDateRangeSuccess =
createAction(GET_UNAUDITED_ASSETS_BY_DATE_RANGE_SUCCESS)

export const GET_UNAUDITED_ASSETS_BY_DATE_RANGE_ERROR = 'GET_UNAUDITED_ASSETS_BY_DATE_RANGE_ERROR'
export const getUnauditedAssetsByDateRangeError = createAction(GET_UNAUDITED_ASSETS_BY_DATE_RANGE_ERROR)

const initialState = {
  networkReportCount: 0,
  networkReportFullCount: 0,
  isGettingNetworkReports: false,
  hasGottenNetworkReports: false,
  getNetworkReportsError: {},
  networkReports: [],
  isGettingNetworkReportsByType: false,
  hasGottenNetworkReportsByType: false,
  getNetworkReportsByTypeError: {},
  networkReportsByType: [],
  isGettingSelectedAssetAudit: false,
  hasGottenSelectedAssetAudit: false,
  selectedAssetAudit: {},
  selectedAssetAuditError: {},
  isEditingAssetAudit: false,
  hasEditedAssetAudit: false,
  editAssetAuditSuccess: {},
  editAssetAuditError: {},
  isUploadingAuditPhotoFile: false,
  hasUploadedAuditPhotoFile: false,
  uploadAuditPhotoFileSuccess: {},
  uploadAuditPhotoFileError: {},
  isUnlocked: false,
  isUnlockingReport: false,
  hasUnlockedReport: false,
  unlockSuccess: {},
  unlockError: {},
  isGettingUnlocksByDate: false,
  hasGottenUnlocksByDate: false,
  unlocks: {},
  assetAuditCategories: [],
  isDeletingAssetAudit: false,
  hasDeletedAssetAudit: false,
  deleteAssetAuditError: {},
  deleteAssetAuditSuccess: {},
  isGettingNetworkReportsByTypeAndDateRange: false,
  hasGottenNetworkReportsByTypeAndDateRange: false,
  networkReportsByTypeAndDateRangeError: {},
  networkReportsByTypeAndDateRange: [],
  isGettingNetworkReportData: false,
  hasGottenNetworkReportData: false,
  networkReportData: [],
  isGettingUnauditedAssets: false,
  hasGottenUnauditedAssets: false,
  unauditedAssetsError: {},
  unauditedAssets: []
}

export default handleActions({

  [GET_NETWORK_REPORT_COUNT_SUCCESS]: (state, { payload }) => ({
    ...state,
    networkReportCount: payload
  }),

  [GET_NETWORK_REPORT_FULL_COUNT_SUCCESS]: (state, { payload }) => ({
    ...state,
    networkReportFullCount: payload
  }),

  [GET_NETWORK_REPORTS]: (state) => ({
    ...state,
    isGettingNetworkReports: true,
    hasGottenNetworkReports: false
  }),

  [GET_NETWORK_REPORTS_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isGettingNetworkReports: false,
      hasGottenNetworkReports: true,
      networkReports: payload
    }
  },

  [GET_NETWORK_REPORTS_ERROR]: (state, { payload }) => {
    return {
      ...state,
      isGettingNetworkReports: false,
      hasGottenNetworkReports: false,
      networkReportsError: payload
    }
  },

  [GET_NETWORK_REPORT_DATA]: (state) => ({
    ...state,
    isGettingNetworkReportData: true,
    hasGottenNetworkReportData: false,
    networkReportData: []
  }),

  [GET_NETWORK_REPORT_DATA_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isGettingNetworkReportData: false,
      hasGottenNetworkReportData: true,
      networkReportData: payload
    }
  },

  [GET_NETWORK_REPORT_DATA_ERROR]: (state, { payload }) => {
    return {
      ...state,
      isGettingNetworkReportData: false,
      hasGottenNetworkReportData: false,
      networkReportDataError: payload,
      networkReportData: []
    }
  },

  [GET_NETWORK_REPORTS_BY_TYPE]: (state) => ({
    ...state,
    isGettingNetworkReportsByType: true,
    hasGottenNetworkReportsByType: false
  }),

  [GET_NETWORK_REPORTS_BY_TYPE_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isGettingNetworkReportsByType: false,
      hasGottenNetworkReportsByType: true,
      networkReportsByType: payload
    }
  },

  [GET_NETWORK_REPORTS_BY_TYPE_ERROR]: (state, { payload }) => {
    return {
      ...state,
      isGettingNetworkReportsByType: false,
      hasGottenNetworkReportsByType: false,
      networkReportsByTypeError: payload
    }
  },

  [GET_ASSET_AUDIT_BY_ID]: (state) => {
    return {
      ...state,
      isGettingSelectedAssetAudit: true,
      hasGottenSelectedAssetAudit: false,
      selectedAssetAudit: {},
      selectedAssetAuditError: {}
    }
  },

  [GET_ASSET_AUDIT_BY_ID_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isGettingSelectedAssetAudit: false,
      hasGottenSelectedAssetAudit: true,
      selectedAssetAudit: payload,
      selectedAssetAuditError: {}
    }
  },

  [GET_ASSET_AUDIT_BY_ID_ERROR]: (state, { payload }) => {
    return {
      ...state,
      isGettingSelectedAssetAudit: false,
      hasGottenSelectedAssetAudit: false,
      selectedAssetAudit: {},
      selectedAssetAuditError: payload
    }
  },

  [EDIT_ASSET_AUDIT_BY_ID]: (state) => {
    return {
      ...state,
      isEditingAssetAudit: true,
      hasEditedAssetAudit: false,
      editAssetAuditSuccess: {},
      editAssetAuditError: {}
    }
  },

  [EDIT_ASSET_AUDIT_BY_ID_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isEditingAssetAudit: false,
      hasEditedAssetAudit: true,
      editAssetAuditSuccess: payload,
      editAssetAuditError: {}
    }
  },

  [EDIT_ASSET_AUDIT_BY_ID_ERROR]: (state, { payload }) => {
    return {
      ...state,
      isEditingAssetAudit: false,
      hasEditedAssetAudit: false,
      editAssetAuditSuccess: {},
      editAssetAuditError: payload
    }
  },

  [RESET_ASSET_AUDIT_FORM]: (state) => {
    return {
      ...state,
      isEditingAssetAudit: false,
      hasEditedAssetAudit: false,
      editAssetAuditSuccess: {},
      editAssetAuditError: {}
    }
  },

  [UPLOAD_AUDIT_PHOTO_FILE]: (state) => {
    return {
      ...state,
      isUploadingAuditPhotoFile: true,
      hasUploadedAuditPhotoFile: false,
      uploadAuditPhotoFileSuccess: {},
      uploadAuditPhotoFileError: {}
    }
  },

  [UPLOAD_AUDIT_PHOTO_FILE_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isUploadingAuditPhotoFile: false,
      hasUploadedAuditPhotoFile: true,
      uploadAuditPhotoFileSuccess: payload,
      uploadAuditPhotoFileError: {}
    }
  },

  [UPLOAD_AUDIT_PHOTO_FILE_ERROR]: (state, { payload }) => {
    return {
      ...state,
      isUploadingAuditPhotoFile: false,
      hasUploadedAuditPhotoFile: false,
      uploadAuditPhotoFileSuccess: {},
      uploadAuditPhotoFileError: payload
    }
  },

  [GET_UNLOCKED_STATE_SUCCESS]: (state, { payload }) => {
    const isUnlocked = payload.unlocks
    return {
      ...state,
      isUnlocked,
      isUnlockingReport: false,
      hasUnlockedReport: false
    }
  },

  [GET_UNLOCKS_BY_DATE]: (state) => {
    return {
      ...state,
      isGettingUnlocksByDate: true,
      hasGottenUnlocksByDate: false,
      unlocks: {}
    }
  },

  [GET_UNLOCKS_BY_DATE_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isGettingUnlocksByDate: false,
      hasGottenUnlocksByDate: true,
      unlocks: payload
    }
  },

  [UNLOCK_BY_CLIENT_AND_DATE]: (state) => {
    return {
      ...state,
      isUnlocked: false,
      isUnlockingReport: true,
      hasUnlockedReport: false,
      unlockSuccess: {},
      unlockError: {}
    }
  },

  [UNLOCK_BY_CLIENT_AND_DATE_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isUnlocked: false,
      isUnlockingReport: false,
      hasUnlockedReport: true,
      unlockSuccess: payload,
      unlockError: {}
    }
  },

  [UNLOCK_BY_CLIENT_AND_DATE_ERROR]: (state, { payload }) => {
    return {
      ...state,
      isUnlocked: false,
      isUnlockingReport: false,
      hasUnlockedReport: false,
      unlockSuccess: {},
      unlockError: payload
    }
  },

  [GET_ASSET_AUDIT_CATEGORIES_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      assetAuditCategories: payload
    }
  },

  [DELETE_ASSET_AUDIT_BY_ID]: (state) => {
    return {
      ...state,
      isDeletingAssetAudit: true,
      hasDeletedAssetAudit: false,
      deleteAssetAuditError: {},
      deleteAssetAuditSuccess: {}
    }
  },

  [DELETE_ASSET_AUDIT_BY_ID_ERROR]: (state, { payload }) => {
    return {
      ...state,
      isDeletingAssetAudit: true,
      hasDeletedAssetAudit: false,
      deleteAssetAuditError: payload,
      deleteAssetAuditSuccess: {}
    }
  },

  [DELETE_ASSET_AUDIT_BY_ID_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isDeletingAssetAudit: false,
      hasDeletedAssetAudit: true,
      deleteAssetAuditError: {},
      deleteAssetAuditSuccess: payload
    }
  },

  [GET_NETWORK_REPORTS_BY_TYPE_AND_DATE_RANGE]: (state) => ({
    ...state,
    isGettingNetworkReportsByTypeAndDateRange: true,
    hasGottenNetworkReportsByTypeAndDateRange: false,
    networkReportsByTypeAndDateRange: []
  }),

  [GET_NETWORK_REPORTS_BY_TYPE_AND_DATE_RANGE_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isGettingNetworkReportsByTypeAndDateRange: false,
      hasGottenNetworkReportsByTypeAndDateRange: true,
      networkReportsByTypeAndDateRange: payload
    }
  },

  [GET_NETWORK_REPORTS_BY_TYPE_AND_DATE_RANGE_ERROR]: (state, { payload }) => {
    return {
      ...state,
      isGettingNetworkReportsByTypeAndDateRange: false,
      hasGottenNetworkReportsByTypeAndDateRange: false,
      networkReportsByTypeAndDateRangeError: payload,
      networkReportsByTypeAndDateRange: []
    }
  },

  [GET_UNAUDITED_ASSETS_BY_DATE_RANGE]: (state) => ({
    ...state,
    isGettingUnauditedAssets: true,
    hasGottenUnauditedAssets: false,
    unauditedAssets: []
  }),

  [GET_UNAUDITED_ASSETS_BY_DATE_RANGE_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isGettingUnauditedAssets: false,
      hasGottenUnauditedAssets: true,
      unauditedAssets: payload
    }
  },

  [GET_UNAUDITED_ASSETS_BY_DATE_RANGE_ERROR]: (state, { payload }) => {
    return {
      ...state,
      isGettingUnauditedAssets: false,
      hasGottenUnauditedAssets: false,
      unauditedAssetsError: payload,
      unauditedAssets: []
    }
  }

}, initialState)
