import React from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import {
  sendMessageToAuditor
} from 'store/modules/accounts'
import { closeSendMessageDialog } from 'store/modules/site'
import ResponsiveDialog from 'components/ResponsiveDialog'
import { FormTextField, FormSubmitButton, FormError, FormSuccess } from 'components/Forms/FormFields'
import { Formik, Form } from 'formik'

const SendMessageForm = ({ dispatch, selectedAccount }) => {
  return (
    <>
      <Formik
        initialValues={{
          message: ''
        }}
        onSubmit={(values, actions) => {
          setTimeout(() => {
            actions.setSubmitting(true)
            const data = {
              userId: selectedAccount.userId,
              message: values.message
            }
            dispatch(sendMessageToAuditor(data))
            actions.setSubmitting(false)
          }, 200)
        }}
      >
        <Form>
          <FormTextField minRows={5} name='message' label='Message' multiline />
          <FormSubmitButton>Send</FormSubmitButton>
        </Form>
      </Formik>
    </>
  )
}

SendMessageForm.propTypes = {
  dispatch: PropTypes.func,
  selectedAccount: PropTypes.object
}

const SendMessageDialog = () => {
  const dispatch = useDispatch()
  const {
    error,
    open,
    selectedAccount,
    hasSentMessageToAuditor
  } = useSelector((state) => {
    return {
      error: state.accounts.sendMessageToAuditorError,
      open: state.site.sendMessageDialogIsOpen,
      selectedAccount: state.site.sendMessageAccount,
      hasSentMessageToAuditor: state.accounts.hasSentMessageToAuditor
    }
  })
  let title = 'Send Message'
  if (selectedAccount.username) { title += ` to ${selectedAccount.username}` }
  return (
    <ResponsiveDialog title={title}
      open={open} onClose={() => dispatch(closeSendMessageDialog()) }>
    <>
      <FormError error={error} />
      <FormSuccess message='Message sent' open={hasSentMessageToAuditor} />
      <SendMessageForm selectedAccount={selectedAccount} dispatch={dispatch} />
    </>
  </ResponsiveDialog>
  )
}

export default SendMessageDialog
