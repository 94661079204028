import { requireAuth } from 'routes'
import { injectReducer } from 'store/reducers'

export default (store) => ({
  childRoutes: [
    {
      path: '/content/documents',
      onEnter: requireAuth(store),
      getComponent (nextState, cb) {
        require.ensure([], (require) => {
          const DocumentsContainer = require('./containers/DocumentsContainer').default
          const reducer = require('./modules/documents').default
          injectReducer(store, { key: 'documents', reducer })
          cb(null, DocumentsContainer)
        }, 'documents')
      }
    }
  ]
})
