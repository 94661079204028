import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { MenuItem, FormControl, Select, InputLabel, InputBase } from '@mui/material'
import { styled } from '@mui/material/styles'

const propTypes = {
  getNetworks: PropTypes.func,
  networks: PropTypes.array,
  adminSelectedNetwork: PropTypes.number,
  adminSelectNetwork: PropTypes.func
}

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
    border: 'none'
  },
  '& .MuiInputBase-input': {
    position: 'relative',
    border: 'none',
    '&:focus': {
      boxShadow: 'none',
      border: 'none'
    },
  },
}))

const NetworkSelect = ({
  getNetworks, networks,
  adminSelectNetwork, adminSelectedNetwork
}) => {
  const [selectedNetwork, setSelectedNetwork] = useState('')

  useEffect(() => {
    getNetworks()
  }, [])

  useEffect(() => {
    if (networks && networks.length > 0) {
      setSelectedNetwork(adminSelectedNetwork)
    }
  }, [adminSelectedNetwork, networks])

  const handleNetworkChange = (e) => {
    adminSelectNetwork(e.target.value)
  }

  return (
    <FormControl variant='standard' sx={{ marginTop: '15px' }}>
      <InputLabel sx={{
        color: 'white',
        '&.Mui-focused': { color: 'white' }
      }}>Select Network</InputLabel>
      <Select
        sx={{
          width: '210px',
          color: 'white',
          border: 'none',
        }}
        onChange={handleNetworkChange}
        value={selectedNetwork}
        input={<BootstrapInput />}>
        {networks.map((network) => {
          return (
            <MenuItem key={`network-${network.clientId}`}
              value={network.clientId}
              >{network.clientName}</MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

NetworkSelect.propTypes = propTypes

export default NetworkSelect
