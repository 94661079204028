// We only need to import the modules necessary for initial render
import CoreLayout from '../layouts/PageLayout/PageLayout'
import LoginRoute from './Login'
// import RegisterRoute from './Register'
import AccountsRoute from './Accounts'
import SparesRoute from './Spares'
import RoutesRoute from './Routes'
import AssetsRoute from './Assets'
import NetworksRoute from './Networks'
import DashboardRoute from './Dashboard'
import NetworkReportsRoute from './NetworkReports'
import NceReportsRoute from './NceReports'
import ManholesRoute from './Manholes'
import UtilityRequestsRoute from './UtilityRequests'
import PlannedWorksRoute from './PlannedWorks'
import RectificationWorksRoute from './RectificationWorks'
import SitesReportsRoute from './SitesReports'
import LTAReportRoute from './LTAReport'
import SearchRoute from './Search'
import ConstructionOnNetworkRoute from './ConstructionOnNetwork'
import DocumentManagementRoute from './DocumentManagement'
import { sessionService } from 'redux-react-session'
import { loginSuccess, getUserDataSuccess, getNetwork, logout } from 'store/modules/auth'
import { adminSelectNetwork } from 'store/modules/networks'
import { closeSidebar } from 'store/modules/site'

/*  Note: Instead of using JSX, we recommend using react-router
    PlainRoute objects to build route definitions.   */

export const requireAuth = (store) => (nextState, replace, callback) => {
  const getSession = sessionService.loadSession()
    .then(currentSession => {
      return currentSession
    })
    .catch(err => {
      return err
    })
  const getUser = sessionService.loadUser()
    .then(currentUser => {
      return currentUser
    })
    .catch(err => {
      return err
    })
  Promise.all([getSession, getUser]).then(res => {
    if (res[0] !== 'Session not found' ||
      res[1] !== 'User not found') {
      store.dispatch(loginSuccess(res[0]))
      store.dispatch(getUserDataSuccess(res[1]))
      if ({}.hasOwnProperty.call(res[0], 'adminClientId') &&
        res[1].userType === 'Admin') {
        store.dispatch(adminSelectNetwork(res[0].adminClientId))
      } else if (res[1].clientRestricted === true && res[1].accountClients &&
        res[1].accountClients[0] && res[1].accountClients[0].clientId) {
        store.dispatch(adminSelectNetwork(res[1].accountClients[0].clientId))
        store.dispatch(getNetwork(res[1].accountClients[0].clientId))
      } else if ({}.hasOwnProperty.call(res[1], 'userClientId') &&
        res[1].userClientId !== 0) {
        store.dispatch(getNetwork(res[1].userClientId))
      }
      callback()
    } else {
      if (store.getState().auth.account.accessToken === '' ||
      !store.getState().auth.account.accessToken) {
        store.dispatch(logout())
        store.dispatch(closeSidebar())
        replace('/login')
        callback()
      } else {
        callback()
      }
    }
  })
}

export const requireAdmin = (store) => (nextState, replace) => {
  if (!store.getState().auth.account.userType === 'Admin') {
    replace('/admin')
  }
}

export const createRoutes = (store) => ({
  path: '/',
  component   : CoreLayout,
  indexRoute: { onEnter: (nextState, replace) => replace('/content/dashboard') },
  childRoutes : [
    DashboardRoute(store),
    LoginRoute(store),
    SparesRoute(store),
    AssetsRoute(store),
    RoutesRoute(store),
    NetworksRoute(store),
    NetworkReportsRoute(store),
    NceReportsRoute(store),
    ManholesRoute(store),
    UtilityRequestsRoute(store),
    RectificationWorksRoute(store),
    PlannedWorksRoute(store),
    LTAReportRoute(store),
    SitesReportsRoute(store),
    AccountsRoute(store),
    SearchRoute(store),
    DocumentManagementRoute(store),
    ConstructionOnNetworkRoute(store),
    // RegisterRoute(store)
  ]
})

export default createRoutes
