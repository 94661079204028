import React from 'react'
import PropTypes from 'prop-types'
import { Button, Dialog, DialogContent, DialogActions } from '@mui/material'
import { closeConfirmDialog } from 'store/modules/site'
import { useDispatch } from 'react-redux'

const ConfirmAlert = ({ open, message, confirmCallback, cancelCallback, data }) => {
  const dispatch = useDispatch()
  const handleConfirm = (data) => {
    confirmCallback(data)
  }

  const handleCancel = (data) => {
    if (cancelCallback) {
      cancelCallback(data)
    }
    dispatch(closeConfirmDialog())
  }

  const handleClose = () => {
    dispatch(closeConfirmDialog())
  }
  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose()
      }}
    >
      <DialogContent>
        {message}
      </DialogContent>
      <DialogActions>
        <Button variant='text' onClick={() => handleCancel(data) }>Cancel</Button>
        <Button variant='contained' onClick={() => handleConfirm(data) }>Confirm</Button>
      </DialogActions>
    </Dialog>
  )
}

ConfirmAlert.propTypes = {
  confirmCallback: PropTypes.func,
  cancelCallback: PropTypes.func,
  // closeCallback: PropTypes.func,
  open: PropTypes.bool,
  // value: PropTypes.oneOfType([
  //   PropTypes.string,
  //   PropTypes.object
  // ]),
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  data: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
}

export default ConfirmAlert
