import { requireAuth } from 'routes'

export default (store) => ({
  path: '/content/routes',
  onEnter: requireAuth(store),
  getComponent (nextState, cb) {
    require.ensure([], (require) => {
      const Routes = require('./containers/RoutesContainer').default
      cb(null, Routes)
    }, 'routes')
  }
})
