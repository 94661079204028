/* eslint max-len: 0 */ // --> disable max length
import moment from 'moment-timezone'
import config from 'config'
const { client, timezone } = config

export const getDateFilter = (date, opts) => {
  let format = 'DD-MM-YYYY'
  let monthFormat = 'MM-YYYY'
  if (opts.format === 'reverse') {
    format = 'YYYY-MM-DD'
    monthFormat = 'YYYY-MM'
  }
  let selectedDate = moment(date, format).startOf('day')
  if (!selectedDate._isValid) {
    selectedDate = moment(date, monthFormat).startOf('month')
  }
  let endDate = moment(date, format).endOf('day')
  if (!endDate._isValid) {
    endDate = moment(date, monthFormat).endOf('month')
  }
  if (opts && opts.utc) {
    selectedDate = moment.utc(date, format).startOf('day')
    if (!selectedDate._isValid) {
      selectedDate = moment.utc(date, monthFormat).startOf('month')
    }
    endDate = moment.utc(date, format).endOf('day')
    if (!endDate._isValid) {
      endDate = moment.utc(date, monthFormat).endOf('month')
    }
  }
  return { between: [selectedDate, endDate] }
}

export const getFormattedDate = (reqDate) => {
  if (moment(reqDate).isValid()) {
    if (client !== 'redeye' && client !== 'redeye-demo') {
      return moment(reqDate).tz(timezone).format('DD/MM/YYYY')
    } else {
      return moment(reqDate).format('DD/MM/YYYY')
    }
  } return false
}

export const getAssetAuditWorkType = (assetAudit) => {
  for (const key in assetAudit) {
    if (assetAudit[key] === 1) {
      const text = key.split(/(?=[A-Z])/).join(' ')
      return text.charAt(0).toUpperCase() + text.slice(1)
    }
  }
}

export const getClientId = (getState) => {
  const userType = getState().auth.user.userType
  const clientId = getState().networks.adminSelectedNetwork
  const networkId = getState().auth.selectedNetwork
  if (userType !== 'User') {
    return clientId
  } else {
    return networkId
  }
}

export const getPermissions = (getState) => {
  const clientPermissions = getState().auth.network.permissions
  const userPermissions = getState().auth.user.permissions
  if (clientPermissions && userPermissions) {
    if (userPermissions.length > 0) {
      const permissions = Object.assign(clientPermissions, userPermissions)
      return permissions
    } else {
      return clientPermissions
    }
  }
}

export const getServiceRequestEmailBody = (clientName, roadName) => {
  const body = `<b>Operator:</b> ${clientName}<br />
  <b>Location:</b> ${roadName}<br />
  <b>Date:</b> ${new Date()}<br />

  <p>During inspection, <b>RED Technologies (${clientName} Preventive Maintenance)</b> inspector found that <b>HSC Pipeline Engineering Pte Ltd</b> is carrying earthwork within the vicinity of the <b>${clientName} Ltd</b> underground network. Please find the attached photos of activities at the construction site. who is the contractor working on site which is within the vicinity of the ${clientName} underground network. Please find the attached photo taken on site.</p>

  <p>From our records, no Notice for Commencement of Earthwork (NCE) was submitted to ${clientName} Ltd. Please be reminded that it is your company's responsibility to apply for NCE's when working near or along underground assets and ensure that all <b>${clientName} Manholes are not covered with earth, construction material, equipment or machinery</b> to avoid any damages to the manhole cover, concrete or numbering during your works. I found so, ${clientName} has to rectify it which the cost borne by your company.</p>

  <p>In addition, a copy of the approval which is valid In addition, a copy of the approval which is valid for <b>3</b> months must be kept on site and complied with when requested for inspection. If not ${clientName} shall bring this matter to your Principal, <b>SP Telecom</b> and also to the relevant Authorities.</p>

  <p>Please note that Under Section 32 of the Telecommunications Act 1999 (Chapter 323, The "Act"), it shall be the duty of the person who carries out any earthworks to enquire before earthworks. Any person who digs, bores, trenches, grades, excavates or breaks any ground with any mechanical equipment or  explosive or allows his employee or agent to do so without first ascertaining the location of any telecommunication cable belonging to or under the management or control of any telecommunication system licensee that may be interfered with shall be guilty of an offence and shall be liable on conviction to a fine not exceeding <b>$100,000</b> or <b>to imprisonment for a term not exceeding 3 years or to both.</b></p>

  <p>In addition, your attention is drawn to Section 49(1) & (2) of the Act where any person who, in the course of carrying out any earthworks, damages or suffers to be damaged any cable of a telecommunication system belonging to or under the management or control of a telecommunication system licensee shall be guilty of an offence and shall be liable on conviction to a <b>fine not exceeding $1 million or to imprisonment for a term not exceeding 5 years or to both</b>.</p>

  <p>Therefore, you are requested to halt the earthwork activities with immediate effect and follow up with the necessary legal action and requirements. You are also required to purchase ${clientName} as-build drawings through this website http://beforeudig.com.sg to determine if there are any underground pipelines belonging to our clients.</p>

  <p>We would like to advise if you intend to expose or have exposed the cables, pipes, manholes or any other equipment, please contact RED Technologies for assistance. RED Technologies will provide the necessary help and assistance where appropriate.</p>

  <p>Should you have any queries please do not hesitate to contact me.</p>`
  return body
}

export const getEmailTemplate = (body) => {
  const template = `<html>
    <body style="padding:0px;margin:0px;">
      <table width="100%" style="font-family: Helvetica; font-size: 15px;">
        <tr>
          <td>
            <div style="background-color:#ecf0f1">
              <center>
                <div style="max-width: 500px; text-align:left; padding: 10px;">
                  <img src="https://redeye.quixotic.com.sg/local_assets/img/quixotic_logo.png">
                </div>
              </center>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <center>
              <div style="max-width: 500px; text-align: left; padding: 10px;">
                ${body}
                <hr>
                <i><small>CAUTION: "This email and any attachments are confidential. They may contain legally privileged information and material that is copyright of RED Technologies (S) Pte Ltd or third parties. Do not use, transmit, distribute or commercialise the material without authorisation. We use virus scanning software but exclude liability for viruses, defects or similar in any email or attachments. We will not be liable for any loss or damage due to the reliance of this email. If you have received this message in error please notify the sender of this message immediately.”</small></i>
              </div>
            </center>
          </td>
        </tr>
      </table>
    </body>
  </html>`
  return template
}

export const getServiceRequestEmailFooter = () => {
  const footer = `Thanks & Regards, <br><br>
    <b>Firdaus Mokhtar <br> NCE Engineer </b>

    <br><br>

    RED Technologies (S) Pte Ltd <br>
    Phone : (65) 6846 1784<br>
    Fax : (65) 6846 1795<br>
    Mobile : (65) 84987505<br>
    Mailto: firdausmokhtar@redtechgroup.com<br><br>
    Address: 100 Lorong 23, Geylang, #09-01, D'Centennial, Singapore 388398 <br>
    Website: www.redtechgroup.com<br>`
  return footer
}
