import { combineReducers } from 'redux'
// import locationReducer from './location'
import { sessionReducer } from 'redux-react-session'
import { responsiveStateReducer as browser } from 'redux-responsive'
import auth from 'store/modules/auth'
import networks from 'store/modules/networks'
import routes from 'store/modules/routes'
import site from 'store/modules/site'
import email from 'store/modules/email'
import networkreports from 'store/modules/networkreports'
import ltareports from 'store/modules/ltareport'
import assets from 'store/modules/assets'
import works from 'store/modules/works'
import accounts from 'store/modules/accounts'
import constructiononnetwork from 'store/modules/constructiononnetwork'
import sitesreports from 'store/modules/sitesreports'
import { routerReducer } from 'react-router-redux'

export const makeRootReducer = (asyncReducers) => {
  // Add async reducers here
  return combineReducers({
    // location: locationReducer,
    routing: routerReducer,
    session: sessionReducer,
    browser,
    auth,
    site,
    networks,
    email,
    routes,
    networkreports,
    ltareports,
    assets,
    works,
    accounts,
    constructiononnetwork,
    sitesreports,
    ...asyncReducers
  })
}

export const injectReducer = (store, { key, reducer }) => {
  if (Object.hasOwnProperty.call(store.asyncReducers, key)) return
  store.asyncReducers[key] = reducer
  store.replaceReducer(makeRootReducer(store.asyncReducers))
}

export default makeRootReducer
