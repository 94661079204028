import { requireAuth } from 'routes'
import { injectReducer } from 'store/reducers'

export default (store) => ({
  path: '/content/utility',
  onEnter: requireAuth(store),
  getComponent (nextState, cb) {
    require.ensure([], (require) => {
      const UtilityRequests = require('./containers/UtilityRequestsContainer').default
      const reducer = require('./modules/utilityrequests').default
      injectReducer(store, { key: 'utilityrequests', reducer })
      cb(null, UtilityRequests)
    }, 'utilityrequests')
  }
})
