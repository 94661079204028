import { createAction, handleActions } from 'redux-actions'
import { sendApiRequest } from 'utils/api'
import { getClientId } from 'utils/utils'

export const GET_LTA_REPORT_DATA = 'GET_LTA_REPORT_DATA'
export const getLtaReportData = (month, year) => {
  return (dispatch, getState) => {
    dispatch(createAction(GET_LTA_REPORT_DATA)())
    const clientId = getClientId(getState)
    sendApiRequest(`NetworkReports/lta/${clientId}/date/${month}/${year}`, {
      accessToken: getState().auth.account.accessToken
    }, 'get').then((response) => {
      if (response.status === 'failure') {
        dispatch(getLtaReportDataError(response.data))
      }
      if (response.status === 'success') {
        const res = response.data.map((item) => {
          const date = new Date(Math.max.apply(null, item.values.map(function (e) {
            return new Date(e.inspectedDate)
          })))
          const manholeArray = []
          const manholeSidewalk = []
          const manholeCarriageWay = []
          const manholeFootpath = []
          item.values.forEach((val) => {
            const asset = val.assetNo.toUpperCase()
            if (val.location === 1) {
              manholeSidewalk.push(asset)
            } else if (val.location === 2) {
              manholeCarriageWay.push(asset)
            } else if (val.location === 3) {
              manholeFootpath.push(asset)
            } else {
              manholeArray.push(asset)
            }
          })
          // const uniqueUnassigned =
          // manholeArray.filter(function (item, i, ar) { return ar.indexOf(item) === i })
          const uniqueSidewalk =
          manholeSidewalk.filter(function (item, i, ar) { return ar.indexOf(item) === i })
          const uniqueCarriageway =
          manholeCarriageWay.filter(function (item, i, ar) { return ar.indexOf(item) === i })
          const uniqueFootpath =
          manholeFootpath.filter(function (item, i, ar) { return ar.indexOf(item) === i })
          return {
            'Road Name': item.road,
            'Manhole(s) on Sidewalk': uniqueSidewalk.length,
            'Manhole(s) on Carriageway': uniqueCarriageway.length,
            'Manhole(s) on Footpath': uniqueFootpath.length,
            'Date of Inspection': date
          }
        })
        dispatch(getLtaReportDataSuccess(res))
      }
    })
  }
}

export const GET_LTA_REPORT_DATA_SUCCESS = 'GET_LTA_REPORT_DATA_SUCCESS'
export const getLtaReportDataSuccess = createAction(GET_LTA_REPORT_DATA_SUCCESS)

export const GET_LTA_REPORT_DATA_ERROR = 'GET_LTA_REPORT_DATA_ERROR'
export const getLtaReportDataError = createAction(GET_LTA_REPORT_DATA_ERROR)

const initialState = {
  isGettingLtaData: false,
  hasGottenLtaData: false,
  ltaData: []
}

export default handleActions({

  [GET_LTA_REPORT_DATA]: (state) => ({
    ...state,
    isGettingLtaData: true,
    hasGottenLtaData: false
  }),

  [GET_LTA_REPORT_DATA_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      ltaData: payload,
      isGettingLtaData: false,
      hasGottenLtaData: true
    }
  },

  [GET_LTA_REPORT_DATA_ERROR]: (state, { payload }) => {
    return {
      ...state,
      ltaDataError: payload,
      isGettingLtaData: false
    }
  },

}, initialState)
