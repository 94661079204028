import { createAction, handleActions } from 'redux-actions'
import { sendApiRequest, sendApiFileRequest } from 'utils/api'
import { getClientId } from 'utils/utils'

export const GET_ASSET_COUNT = 'GET_ASSET_COUNT'
export const getAssetCount = (options = {}, search) => {
  return (dispatch, getState) => {
    dispatch(createAction(GET_ASSET_COUNT)())
    const clientId = getClientId(getState)
    options.where = {
      and: [
        { clientId }
      ]
    }
    if (search) {
      options.where.and.push(search)
    }
    sendApiRequest('Assets/count', {
      accessToken: getState().auth.account.accessToken
    }, 'get', options).then((response) => {
      if (response.status === 'failure') {
        dispatch(getAssetCountError(response))
      }
      if (response.status === 'success') {
        dispatch(getAssetCountSuccess(response.data.count))
      }
    })
  }
}

export const GET_ASSET_COUNT_SUCCESS = 'GET_ASSET_COUNT_SUCCESS'
export const getAssetCountSuccess = createAction(GET_ASSET_COUNT_SUCCESS)

export const GET_ASSET_COUNT_ERROR = 'GET_ASSET_COUNT_ERROR'
export const getAssetCountError = createAction(GET_ASSET_COUNT_ERROR)

export const GET_ASSETS = 'GET_ASSETS'
export const getAssets = (pageSize, pageNumber, search) => {
  return (dispatch, getState) => {
    dispatch(createAction(GET_ASSETS)())
    const clientId = getClientId(getState)
    const options = {
      filter: {
        order: 'assetId DESC',
        where: {
          and: [
            { clientId }
          ]
        }
      }
    }
    if (pageSize !== 0) {
      options.filter.limit = pageSize
    }
    if (pageNumber !== 1) {
      options.filter.skip = (pageNumber - 1) * pageSize
    }
    if (search) {
      options.filter.where.and.push(search)
    }

    sendApiRequest('Assets', {
      accessToken: getState().auth.account.accessToken
    }, 'get', options).then((response) => {
      if (response.status === 'failure') {
        dispatch(getAssetsError(response))
      }
      if (response.status === 'success') {
        dispatch(getAssetsSuccess(response.data))
      }
    })
  }
}

export const GET_ASSETS_SUCCESS = 'GET_ASSETS_SUCCESS'
export const getAssetsSuccess = createAction(GET_ASSETS_SUCCESS)

export const GET_ASSETS_ERROR = 'GET_ASSETS_ERROR'
export const getAssetsError = createAction(GET_ASSETS_ERROR)

export const GET_ALL_ASSETS = 'GET_ALL_ASSETS'
export const getAllAssets = (search, fields) => {
  return (dispatch, getState) => {
    dispatch(createAction(GET_ALL_ASSETS)())
    const clientId = getClientId(getState)
    const options = {
      filter: {
        order: 'assetId DESC',
        where: {
          and: [
            { clientId }
          ]
        }
      }
    }
    if (search) {
      options.filter.where.and.push(search)
    }
    if (fields) {
      options.filter.fields = fields
    }

    sendApiRequest('Assets', {
      accessToken: getState().auth.account.accessToken
    }, 'get', options).then((response) => {
      if (response.status === 'failure') {
        dispatch(getAllAssetsError(response))
      }
      if (response.status === 'success') {
        dispatch(getAllAssetsSuccess(response.data))
      }
    })
  }
}

export const GET_ALL_ASSETS_SUCCESS = 'GET_ALL_ASSETS_SUCCESS'
export const getAllAssetsSuccess = createAction(GET_ALL_ASSETS_SUCCESS)

export const GET_ALL_ASSETS_ERROR = 'GET_ALL_ASSETS_ERROR'
export const getAllAssetsError = createAction(GET_ALL_ASSETS_ERROR)

export const GET_VIP_ASSETS = 'GET_VIP_ASSETS'
export const getVipAssets = (options = { filter: {} }) => {
  return (dispatch, getState) => {
    dispatch(createAction(GET_VIP_ASSETS)())
    const clientId = getClientId(getState)
    options.filter.where = {
      clientId,
      vip: 'Yes'
    }
    sendApiRequest('Assets', {
      accessToken: getState().auth.account.accessToken
    }, 'get', options).then((response) => {
      if (response.status === 'failure') {
        dispatch(getVipAssetsError(response))
      }
      if (response.status === 'success') {
        dispatch(getVipAssetsSuccess(response.data))
      }
    })
  }
}

export const GET_VIP_ASSETS_SUCCESS = 'GET_VIP_ASSETS_SUCCESS'
export const getVipAssetsSuccess = createAction(GET_VIP_ASSETS_SUCCESS)

export const GET_VIP_ASSETS_ERROR = 'GET_VIP_ASSETS_ERROR'
export const getVipAssetsError = createAction(GET_VIP_ASSETS_ERROR)

export const GET_ASSET_BY_ID = 'GET_ASSET_BY_ID'
export const getAssetById = (id) => {
  return (dispatch, getState) => {
    dispatch(createAction(GET_ASSET_BY_ID)())
    sendApiRequest(`Assets/${id}`, {
      accessToken: getState().auth.account.accessToken
    }, 'get').then((response) => {
      if (response.status === 'failure') {
        dispatch(getAssetByIdError(response))
      }
      if (response.status === 'success') {
        dispatch(getAssetByIdSuccess(response.data))
      }
    })
  }
}

export const GET_ASSET_BY_ID_SUCCESS = 'GET_ASSET_BY_ID_SUCCESS'
export const getAssetByIdSuccess = createAction(GET_ASSET_BY_ID_SUCCESS)

export const GET_ASSET_BY_ID_ERROR = 'GET_ASSET_BY_ID_ERROR'
export const getAssetByIdError = createAction(GET_ASSET_BY_ID_ERROR)

export const GET_ASSET_BY_NAME = 'GET_ASSET_BY_NAME'
export const getAssetByName = (assetNo) => {
  return (dispatch, getState) => {
    dispatch(createAction(GET_ASSET_BY_NAME)())
    sendApiRequest('Assets', {
      accessToken: getState().auth.account.accessToken
    }, 'get', { filter: { where: { assetNo } } }).then((response) => {
      if (response.status === 'failure') {
        dispatch(getAssetByIdError(response))
      }
      if (response.status === 'success') {
        dispatch(getAssetByIdSuccess(response.data[0]))
      }
    })
  }
}

export const GET_ASSETS_LIKE_NAME = 'GET_ASSETS_LIKE_NAME'
export const getAssetsLikeName = (assetNo) => {
  return (dispatch, getState) => {
    const clientId = getClientId(getState)
    dispatch(createAction(GET_ASSETS_LIKE_NAME)())
    const filter = {
      filter: {
        limit: 5,
        where: {
          and: [
            {
              assetNo: {
                like: `%25${assetNo}%25`
              }
            },
            { clientId }
          ]
        }
      }
    }
    sendApiRequest('Assets', {
      accessToken: getState().auth.account.accessToken
    }, 'get', filter).then((response) => {
      if (response.status === 'failure') {
        dispatch(getAssetsLikeNameError(response))
      }
      if (response.status === 'success') {
        dispatch(getAssetsLikeNameSuccess(response.data))
      }
    })
  }
}

export const GET_ASSETS_LIKE_NAME_SUCCESS = 'GET_ASSETS_LIKE_NAME_SUCCESS'
export const getAssetsLikeNameSuccess = createAction(GET_ASSETS_LIKE_NAME_SUCCESS)

export const GET_ASSETS_LIKE_NAME_ERROR = 'GET_ASSETS_LIKE_NAME_ERROR'
export const getAssetsLikeNameError = createAction(GET_ASSETS_LIKE_NAME_ERROR)

export const GET_ASSET_BY_ID_RESET = 'GET_ASSET_BY_ID_RESET'
export const getAssetByIdReset = createAction(GET_ASSET_BY_ID_RESET)

export const DELETE_ASSET_BY_ID = 'DELETE_ASSET_BY_ID'
export const deleteAssetById = (id) => {
  return (dispatch, getState) => {
    dispatch(createAction(DELETE_ASSET_BY_ID)())
    sendApiRequest(`Assets/${id}`, {
      accessToken: getState().auth.account.accessToken
    }, 'delete').then((response) => {
      if (response.status === 'failure') {
        dispatch(deleteAssetByIdError(response.data))
      }
      if (response.status === 'success') {
        dispatch(deleteAssetByIdSuccess(response.data))
      }
    })
  }
}

export const DELETE_ASSET_BY_ID_SUCCESS = 'DELETE_ASSET_BY_ID_SUCCESS'
export const deleteAssetByIdSuccess = createAction(DELETE_ASSET_BY_ID_SUCCESS)

export const DELETE_ASSET_BY_ID_ERROR = 'DELETE_ASSET_BY_ID_ERROR'
export const deleteAssetByIdError = createAction(DELETE_ASSET_BY_ID_ERROR)

export const DELETE_ASSET_BY_ID_RESET = 'DELETE_ASSET_BY_ID_RESET'
export const deleteAssetByIdReset = createAction(DELETE_ASSET_BY_ID_RESET)

export const RESET_ASSET_FORM = 'RESET_ASSET_FORM'
export const resetAssetForm = createAction(RESET_ASSET_FORM)

export const OPEN_ASSET_DIALOG = 'OPEN_ASSET_DIALOG'
export const openAssetDialog = createAction(OPEN_ASSET_DIALOG)

export const CLOSE_ASSET_DIALOG = 'CLOSE_ASSET_DIALOG'
export const closeAssetDialog = createAction(CLOSE_ASSET_DIALOG)

export const GET_ASSET_LOCATIONS = 'GET_ASSET_LOCATIONS'
export const getAssetLocations = () => {
  return (dispatch, getState) => {
    dispatch(createAction(GET_ASSET_LOCATIONS)())
    sendApiRequest('AssetLocations', {
      accessToken: getState().auth.account.accessToken
    }, 'get').then((response) => {
      if (response.status === 'failure') {
        dispatch(getAssetLocationsError(response))
      }
      if (response.status === 'success') {
        dispatch(getAssetLocationsSuccess(response.data))
      }
    })
  }
}

export const GET_ASSET_LOCATIONS_SUCCESS = 'GET_ASSET_LOCATIONS_SUCCESS'
export const getAssetLocationsSuccess = createAction(GET_ASSET_LOCATIONS_SUCCESS)

export const GET_ASSET_LOCATIONS_ERROR = 'GET_ASSET_LOCATIONS_ERROR'
export const getAssetLocationsError = createAction(GET_ASSET_LOCATIONS_ERROR)

export const CREATE_NEW_ASSET = 'CREATE_NEW_ASSET'
export const createNewAsset = (data) => {
  return (dispatch, getState) => {
    dispatch(createAction(CREATE_NEW_ASSET)())
    const assetData = data
    assetData.accessToken = getState().auth.account.accessToken
    sendApiRequest('Assets', assetData, 'post').then((response) => {
      if (response.status === 'failure') {
        dispatch(createNewAssetError(response))
      }
      if (response.status === 'success') {
        dispatch(createNewAssetSuccess(response.data))
      }
    })
  }
}

export const CREATE_NEW_ASSET_SUCCESS = 'CREATE_NEW_ASSET_SUCCESS'
export const createNewAssetSuccess = createAction(CREATE_NEW_ASSET_SUCCESS)

export const CREATE_NEW_ASSET_ERROR = 'CREATE_NEW_ASSET_ERROR'
export const createNewAssetError = createAction(CREATE_NEW_ASSET_ERROR)

export const EDIT_ASSET = 'EDIT_ASSET'
export const editAsset = (data) => {
  return (dispatch, getState) => {
    dispatch(createAction(EDIT_ASSET)())
    const assetData = data
    assetData.accessToken = getState().auth.account.accessToken
    sendApiRequest(`Assets/${assetData.assetId}`, assetData, 'patch').then((response) => {
      if (response.status === 'failure') {
        dispatch(createNewAssetError(response))
      }
      if (response.status === 'success') {
        dispatch(createNewAssetSuccess(response.data))
      }
    })
  }
}

export const UPLOAD_ASSET_FILE = 'UPLOAD_ASSET_FILE'
export const uploadAssetFile = (data, id) => {
  return (dispatch, getState) => {
    dispatch(createAction(UPLOAD_ASSET_FILE)())
    const assetData = data
    assetData.accessToken = getState().auth.account.accessToken
    sendApiFileRequest(`Assets/${id}/file`, assetData, 'post').then((response) => {
      if (response.status === 'failure') {
        dispatch(uploadAssetFileError(response))
      }
      if (response.status === 'success') {
        dispatch(uploadAssetFileSuccess(response.data))
      }
    })
  }
}

export const UPLOAD_ASSET_FILE_SUCCESS = 'UPLOAD_ASSET_FILE_SUCCESS'
export const uploadAssetFileSuccess = createAction(UPLOAD_ASSET_FILE_SUCCESS)

export const UPLOAD_ASSET_FILE_ERROR = 'UPLOAD_ASSET_FILE_ERROR'
export const uploadAssetFileError = createAction(UPLOAD_ASSET_FILE_ERROR)

export const GET_ASSET_AUDITS = 'GET_ASSET_AUDITS'
export const getAssetAudits = (search) => {
  return (dispatch, getState) => {
    dispatch(createAction(GET_ASSET_AUDITS)())
    sendApiRequest('AssetAudits', {
      accessToken: getState().auth.account.accessToken
    }, 'get', search).then((response) => {
      if (response.status === 'failure') {
        dispatch(getAssetAuditsError(response))
      }
      if (response.status === 'success') {
        dispatch(getAssetAuditsSuccess(response.data))
      }
    })
  }
}

export const GET_ASSET_AUDITS_SUCCESS = 'GET_ASSET_AUDITS_SUCCESS'
export const getAssetAuditsSuccess = createAction(GET_ASSET_AUDITS_SUCCESS)

export const GET_ASSET_AUDITS_ERROR = 'GET_ASSET_AUDITS_ERROR'
export const getAssetAuditsError = createAction(GET_ASSET_AUDITS_ERROR)

export const GET_ASSET_WORKS = 'GET_ASSET_WORKS'
export const getAssetWorks = (search) => {
  return (dispatch, getState) => {
    dispatch(createAction(GET_ASSET_WORKS)())
    sendApiRequest('Works', {
      accessToken: getState().auth.account.accessToken
    }, 'get', search).then((response) => {
      if (response.status === 'failure') {
        dispatch(getAssetWorksError(response))
      }
      if (response.status === 'success') {
        dispatch(getAssetWorksSuccess(response.data))
      }
    })
  }
}

export const GET_ASSET_WORKS_SUCCESS = 'GET_ASSET_WORKS_SUCCESS'
export const getAssetWorksSuccess = createAction(GET_ASSET_WORKS_SUCCESS)

export const GET_ASSET_WORKS_ERROR = 'GET_ASSET_WORKS_ERROR'
export const getAssetWorksError = createAction(GET_ASSET_WORKS_ERROR)

export const GET_ASSET_CWORKS = 'GET_ASSET_CWORKS'
export const getAssetCWorks = (search) => {
  return (dispatch, getState) => {
    dispatch(createAction(GET_ASSET_CWORKS)())
    sendApiRequest('ContractorWorks', {
      accessToken: getState().auth.account.accessToken
    }, 'get', search).then((response) => {
      if (response.status === 'failure') {
        dispatch(getAssetCWorksError(response))
      }
      if (response.status === 'success') {
        dispatch(getAssetCWorksSuccess(response.data))
      }
    })
  }
}

export const GET_ASSET_CWORKS_SUCCESS = 'GET_ASSET_CWORKS_SUCCESS'
export const getAssetCWorksSuccess = createAction(GET_ASSET_CWORKS_SUCCESS)

export const GET_ASSET_CWORKS_ERROR = 'GET_ASSET_CWORKS_ERROR'
export const getAssetCWorksError = createAction(GET_ASSET_CWORKS_ERROR)

export const GET_ASSET_MUC = 'GET_ASSET_MUC'
export const getAssetMuc = (search) => {
  return (dispatch, getState) => {
    dispatch(createAction(GET_ASSET_CWORKS)())
    sendApiRequest('Manholes', {
      accessToken: getState().auth.account.accessToken
    }, 'get', search).then((response) => {
      if (response.status === 'failure') {
        dispatch(getAssetMucError(response))
      }
      if (response.status === 'success') {
        dispatch(getAssetMucSuccess(response.data))
      }
    })
  }
}

export const GET_ASSET_MUC_SUCCESS = 'GET_ASSET_MUC_SUCCESS'
export const getAssetMucSuccess = createAction(GET_ASSET_MUC_SUCCESS)

export const GET_ASSET_MUC_ERROR = 'GET_ASSET_MUC_ERROR'
export const getAssetMucError = createAction(GET_ASSET_MUC_ERROR)

const initialState = {
  assetCount: 0,
  isGettingAssets: false,
  hasGottenAssets: false,
  getAssetsError: {},
  assets: [],
  selectedAsset: {},
  isGettingAsset: false,
  hasGottenAsset: false,
  getAssetError: {},
  isDeletingAsset: false,
  hasDeletedAsset: false,
  deleteAssetSuccess: {},
  deleteAssetError: {},
  assetDialogIsOpen: false,
  selectedAssetToEdit: {},
  isCreatingAsset: false,
  hasCreatedAsset: false,
  createAssetSuccess: {},
  createAssetError: {},
  assetLocations: [],
  isGettingVipAssets: false,
  hasGottenVipAssets: false,
  vipAssets: [],
  isUploadingAssetFile: false,
  hasUploadedAssetFile: false,
  uploadAssetFileSuccess: {},
  uploadAssetFileError: {},
  isGettingAssetsLike: false,
  hasGottenAssetsLike: false,
  assetsLike: [],
  isGettingAssetAudits: false,
  hasGottenAssetAudits: false,
  selectedAssetAudits: [],
  isGettingAssetWorks: false,
  hasGottenAssetWorks: false,
  selectedAssetWorks: [],
  isGettingAssetCWorks: false,
  hasGottenAssetCWorks: false,
  selectedAssetCWorks: [],
  isGettingAssetMuc: false,
  hasGottenAssetMuc: false,
  selectedAssetMuc: [],
  isGettingAllAssets: false,
  hasGottenAllAssets: false,
  allAssets: []
}

export default handleActions({

  [GET_ASSET_COUNT_SUCCESS]: (state, { payload }) => ({
    ...state,
    assetCount: payload
  }),

  [GET_ASSETS]: (state) => ({
    ...state,
    isGettingAssets: true,
    hasGottenAssets: false
  }),

  [GET_ASSETS_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isGettingAssets: false,
      hasGottenAssets: true,
      assets: payload
    }
  },

  [GET_ASSETS_ERROR]: (state, { payload }) => {
    return {
      ...state,
      isGettingAssets: false,
      hasGottenAssets: false,
      assetsError: payload
    }
  },

  [GET_ALL_ASSETS]: (state) => ({
    ...state,
    isGettingAllAssets: true,
    hasGottenAllAssets: false
  }),

  [GET_ALL_ASSETS_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isGettingAllAssets: false,
      hasGottenAllAssets: true,
      allAssets: payload
    }
  },

  [GET_ASSET_BY_ID]: (state) => ({
    ...state,
    isGettingAsset: true,
    hasGottenAsset: false
  }),

  [GET_ASSET_BY_ID_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isGettingAsset: false,
      hasGottenAsset: true,
      selectedAsset: payload
    }
  },

  [GET_ASSET_BY_ID_ERROR]: (state, { payload }) => {
    return {
      ...state,
      isGettingAsset: false,
      hasGottenAsset: false,
      getAssetError: payload
    }
  },
  [GET_ASSET_BY_ID_RESET]: (state) => ({
    ...state,
    isGettingAsset: false,
    hasGottenAsset: false,
    selectedAsset: {}
  }),

  [DELETE_ASSET_BY_ID]: (state) => ({
    ...state,
    isDeletingAsset: true,
    hasDeletedAsset: false
  }),

  [DELETE_ASSET_BY_ID_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isDeletingAsset: false,
      hasDeletedAsset: true,
      deleteAssetSuccess: payload
    }
  },

  [DELETE_ASSET_BY_ID_ERROR]: (state, { payload }) => {
    return {
      ...state,
      isDeletingAsset: false,
      hasDeletedAsset: false,
      deleteAssetError: payload
    }
  },

  [DELETE_ASSET_BY_ID_RESET]: (state) => {
    return {
      ...state,
      isDeletingAsset: false,
      hasDeletedAsset: false,
      deleteAssetError: {},
      deleteAssetSuccess: {}
    }
  },

  [OPEN_ASSET_DIALOG]: (state, { payload }) => {
    return {
      ...state,
      assetDialogIsOpen: true,
      selectedAssetToEdit: payload
    }
  },

  [CLOSE_ASSET_DIALOG]: (state) => {
    return {
      ...state,
      assetDialogIsOpen: false,
      selectedAssetToEdit: {},
      isCreatingAsset: false,
      hasCreatedAsset: false,
      createAssetSuccess: {},
      createAssetError: {}
    }
  },

  [GET_ASSET_LOCATIONS_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      assetLocations: payload
    }
  },

  [CREATE_NEW_ASSET]: (state) => {
    return {
      ...state,
      isCreatingAsset: true,
      hasCreatedAsset: false,
      createAssetSuccess: {},
      createAssetError: {}
    }
  },

  [CREATE_NEW_ASSET_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isCreatingAsset: false,
      hasCreatedAsset: true,
      createAssetSuccess: payload
    }
  },

  [CREATE_NEW_ASSET_ERROR]: (state, { payload }) => {
    return {
      ...state,
      isCreatingAsset: false,
      hasCreatedAsset: false,
      createAssetError: payload
    }
  },

  [RESET_ASSET_FORM]: (state) => {
    return {
      ...state,
      isCreatingAsset: false,
      hasCreatedAsset: false,
      createAssetSuccess: {},
      createAssetError: {}
    }
  },

  [EDIT_ASSET]: (state) => {
    return {
      ...state,
      isCreatingAsset: true,
      hasCreatedAsset: false,
      createAssetSuccess: {},
      createAssetError: {}
    }
  },

  [GET_VIP_ASSETS]: (state) => ({
    ...state,
    isGettingVipAssets: true,
    hasGottenVipAssets: false
  }),

  [GET_VIP_ASSETS_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isGettingVipAssets: false,
      hasGottenVipAssets: true,
      vipAssets: payload
    }
  },

  [GET_VIP_ASSETS_ERROR]: (state, { payload }) => {
    return {
      ...state,
      isGettingVipAssets: false,
      hasGottenVipAssets: false,
      vipAssetsError: payload
    }
  },

  [UPLOAD_ASSET_FILE]: (state) => {
    return {
      ...state,
      isUploadingAssetFile: true,
      hasUploadedAssetFile: false,
      uploadAssetFileSuccess: {},
      uploadAssetFileError: {}
    }
  },

  [UPLOAD_ASSET_FILE_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isUploadingAssetFile: false,
      hasUploadedAssetFile: true,
      uploadAssetFileSuccess: payload,
      uploadAssetFileError: {}
    }
  },

  [UPLOAD_ASSET_FILE_ERROR]: (state, { payload }) => {
    return {
      ...state,
      isUploadingAssetFile: false,
      hasUploadedAssetFile: false,
      uploadAssetFileSuccess: {},
      uploadAssetFileError: payload
    }
  },

  [GET_ASSETS_LIKE_NAME]: (state) => {
    return {
      ...state,
      isGettingAssetsLike: true,
      hasGottenAssetsLike: false,
      assetsLike: []
    }
  },

  [GET_ASSETS_LIKE_NAME_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isGettingAssetsLike: false,
      hasGottenAssetsLike: true,
      assetsLike: payload
    }
  },

  [GET_ASSET_AUDITS]: (state) => {
    return {
      ...state,
      isGettingAssetAudits: true,
      hasGottenAssetAudits: false,
      selectedAssetAudits: []
    }
  },

  [GET_ASSET_AUDITS_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isGettingAssetAudits: false,
      hasGottenAssetAudits: true,
      selectedAssetAudits: payload
    }
  },

  [GET_ASSET_WORKS]: (state) => {
    return {
      ...state,
      isGettingAssetWorks: true,
      hasGottenAssetWorks: false,
      selectedAssetWorks: []
    }
  },

  [GET_ASSET_WORKS_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isGettingAssetWorks: false,
      hasGottenAssetWorks: true,
      selectedAssetWorks: payload
    }
  },

  [GET_ASSET_CWORKS]: (state) => {
    return {
      ...state,
      isGettingAssetCWorks: true,
      hasGottenAssetCWorks: false,
      selectedAssetCWorks: []
    }
  },

  [GET_ASSET_CWORKS_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isGettingAssetCWorks: false,
      hasGottenAssetCWorks: true,
      selectedAssetCWorks: payload
    }
  },

  [GET_ASSET_MUC]: (state) => {
    return {
      ...state,
      isGettingAssetMuc: true,
      hasGottenAssetMuc: false,
      selectedAssetMuc: []
    }
  },

  [GET_ASSET_MUC_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isGettingAssetMuc: false,
      hasGottenAssetMuc: true,
      selectedAssetMuc: payload
    }
  },

}, initialState)
