export default () => ({
  childRoutes: [
    {
      path: 'login',
      getComponent (_nextState, cb) {
        require.ensure([], (require) => {
          const Login = require('./containers/LoginContainer').default
          cb(null, Login)
        }, 'login')
      }
    },
    {
      path: 'reset-password',
      getComponent (_nextState, cb) {
        require.ensure([], (require) => {
          const ResetPassword = require('./containers/ResetPasswordContainer').default
          cb(null, ResetPassword)
        }, 'reset-password')
      }
    },
    {
      path: 'change-password',
      getComponent (_nextState, cb) {
        require.ensure([], (require) => {
          const ChangePassword = require('./containers/ChangePasswordContainer').default
          cb(null, ChangePassword)
        }, 'change-password')
      }
    }
  ]
})
