import { requireAuth } from 'routes'
import { injectReducer } from 'store/reducers'

export default (store) => ({
  childRoutes: [
    {
      path: '/content/muc/:type',
      onEnter: requireAuth(store),
      getComponent (nextState, cb) {
        require.ensure([], (require) => {
          const Manholes = require('./containers/Manholes').default
          const reducer = require('./modules/manholes').default
          injectReducer(store, { key: 'manholes', reducer })
          cb(null, Manholes)
        }, 'manholes')
      }
    }, {
      path: '/content/manholes/get_muc_view/:manholeId',
      onEnter: requireAuth(store),
      getComponent (nextState, cb) {
        require.ensure([], (require) => {
          const ManholesViewContainer = require('./containers/ManholesViewContainer').default
          const reducer = require('./modules/manholes').default
          injectReducer(store, { key: 'manholes', reducer })
          cb(null, ManholesViewContainer)
        }, 'manholesview')
      }
    }, {
      path: '/content/manholes/get_muc_edit/:manholeId',
      onEnter: requireAuth(store),
      getComponent (nextState, cb) {
        require.ensure([], (require) => {
          const ManholesEditContainer = require('./containers/ManholesEditContainer').default
          const reducer = require('./modules/manholes').default
          injectReducer(store, { key: 'manholes', reducer })
          cb(null, ManholesEditContainer)
        }, 'manholesedit')
      }
    }
  ]
})
