import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import style from 'components/Sidebar/Sidebar.module.scss'

const propTypes = {
  user: PropTypes.object
}

class UserInformation extends Component {
  render () {
    return (
      <div className={style.UserInformation}>
        <h2 className={style.UserInformation_username}>
          {this.props.user.username}
        </h2>
        <div className={style.UserInformation_email}>
          {this.props.user.email}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    network: state.auth.network
  }
}

UserInformation.propTypes = propTypes

export default connect(
  mapStateToProps
)(UserInformation)
