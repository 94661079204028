import { requireAuth } from 'routes'
import { injectReducer } from 'store/reducers'

export default (store) => ({
  path: '/content/npw',
  onEnter: requireAuth(store),
  getComponent (nextState, cb) {
    require.ensure([], (require) => {
      const PlannedWorks = require('./containers/PlannedWorksContainer').default
      const reducer = require('./modules/plannedworks').default
      injectReducer(store, { key: 'plannedworks', reducer })
      cb(null, PlannedWorks)
    }, '')
  }
})
