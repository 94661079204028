import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import {
  GoogleMap, useJsApiLoader, Marker,
  InfoWindow, Polyline, HeatmapLayer, Circle
} from '@react-google-maps/api'
import { TextField, MenuItem } from '@mui/material'
import config from 'config'
const { googleMapsApiKey, mapCenter } = config
const defaultZoom = 12
const libraries = ['places', 'visualization']
const typeOptions = {
  markers: 'Markers',
  polyline: 'Polyline',
  heatmap: 'Heatmap'
}

const MapComponent = ({ markers, zoom, containerStyle, selectType, center, mapType }) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-maps',
    libraries,
    googleMapsApiKey
  })

  const mapContainerStyle = {
    width: '100%',
    minHeight: '400px',
    ...containerStyle
  }
  const [map, setMap] = useState(null)
  const [mapMarkers, setMapMarkers] = useState([])
  const [mapZoom, setMapZoom] = useState(defaultZoom)
  const [type, setType] = useState('markers')
  const [activeMarker, setActiveMarker] = useState(null)
  const [googleMapCenter, setGoogleMapCenter] = useState(mapCenter)

  useEffect(() => {
    if (map && isLoaded) {
      setMapMarkers(markers)
      if (markers.length > 0) {
        const bounds = new window.google.maps.LatLngBounds()
        markers.forEach((m) => {
          const p = { lat: parseFloat(m.lat), lng: parseFloat(m.lng) }
          bounds.extend(p)
        })
        map.fitBounds(bounds)
        const z = map.getZoom()
        if (z > mapZoom) { map.setZoom(mapZoom) }
      }
    }
  }, [markers, isLoaded, map])

  useEffect(() => {
    if (mapType) { setType(mapType) }
  }, [mapType])

  useEffect(() => {
    if (zoom) { setMapZoom(zoom) }
  }, [zoom])

  useEffect(() => {
    if (center && center.lat && center.lng) { setGoogleMapCenter(center) }
  }, [center])

  const onLoad = useCallback((map) => {
    setMap(map)
    setActiveMarker(null)
  }, [])

  const onUnmount = useCallback(() => {
    setMap(null)
    setActiveMarker(null)
  }, [])

  let mapElement = ''

  let content = ''

  switch (type) {
    case 'polyline': {
      content = <Polyline path={markers} />
      break
    }
    case 'heatmap': {
      const heatmapMarkers = markers.map((m) => {
        // eslint-disable-next-line
        return new google.maps.LatLng(m.lat, m.lng)
      })
      content = <HeatmapLayer data={heatmapMarkers} />
      break
    }
    case 'distance': {
      const data = markers.map((m, idx) => {
        return <Marker
          onClick={() => {
            setActiveMarker(`map-marker-${idx}`)
          }}
          key={`map-marker-${idx}`}
          position={{ lat: parseFloat(m.lat), lng: parseFloat(m.lng) }}
          {...m}
          >
            {activeMarker && activeMarker === `map-marker-${idx}` && m.info
              ? <InfoWindow
              onCloseClick={() => setActiveMarker(null)}>{m.info}</InfoWindow>
              : '' }
          </Marker>
      })
      const circleConfig = {
        center,
        radius: 5000,
        options: {
          strokeColor: '#FF0000',
          strokeOpacity: 0,
          strokeWeight: 0,
          fillColor: '#BF360D',
          fillOpacity: 0.2
        }
      }
      content = <><Circle {...circleConfig} />{data}</>
      map.setCenter(center)
      map.setZoom(13)
      break
    }
    case 'markers':
    default : {
      content = mapMarkers.map((m, idx) => {
        return <Marker
          onClick={() => {
            setActiveMarker(`map-marker-${idx}`)
          }}
          key={`map-marker-${idx}`}
          position={{ lat: parseFloat(m.lat), lng: parseFloat(m.lng) }}
          {...m}
          >
            {activeMarker && activeMarker === `map-marker-${idx}` && m.info
              ? <InfoWindow
              onCloseClick={() => setActiveMarker(null)}>{m.info}</InfoWindow>
              : '' }
          </Marker>
      })
    }
  }

  if (isLoaded) {
    mapElement = (
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={googleMapCenter}
        zoom={mapZoom}
        onLoad={onLoad}
        onUnmount={onUnmount}
        onClick={() => {
          setActiveMarker(null)
        }}
      >
        {content}
      </GoogleMap>
    )
  }
  return (
    <>
      {selectType === true
        ? <div className='tableActions'><TextField select
            sx={{ marginBottom: '10px' }}
            name='type' label='Type' value={type} onChange={(e) => {
              setType(e.target.value)
              setActiveMarker(null)
            }}>
          {Object.keys(typeOptions).map((k) => {
            return <MenuItem key={`type-select-${k}`} value={k}>{typeOptions[k]}</MenuItem>
          })}
        </TextField></div>
        : ''}
      {mapElement}
    </>
  )
}

MapComponent.propTypes = {
  markers: PropTypes.array.isRequired,
  zoom: PropTypes.number,
  containerStyle: PropTypes.object,
  selectType: PropTypes.bool,
  center: PropTypes.object,
  mapType: PropTypes.string
}

export default MapComponent
