import * as React from 'react'
import { PropTypes } from 'prop-types'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

const ResponsiveDialog = ({ title, open, actions, onClose, content, children }) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  const handleClose = () => {
    onClose()
  }
  const titleWithClose = (<div>{title}
    <div style={{
      float: 'right',
      paddingRight: '10px',
      fontWeight: 100,
      cursor: 'pointer'
    }}><a onClick={handleClose}>x</a></div>
  </div>)

  return (
    <div>
      <Dialog
        fullWidth={true}
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby='responsive-dialog-title'
      >
        <DialogTitle id='responsive-dialog-title'>
          {titleWithClose}
        </DialogTitle>
        <DialogContent>
          {content ? <DialogContentText>{content}</DialogContentText> : ''}
          {children}
        </DialogContent>
        {actions
          ? <DialogActions>
            {actions}
          </DialogActions>
          : ''}
      </Dialog>
    </div>
  )
}

ResponsiveDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  content: PropTypes.object,
  actions: PropTypes.array,
  title: PropTypes.string,
  children: PropTypes.object,
  // width: PropTypes.number
}

export default ResponsiveDialog
