import { createAction, handleActions } from 'redux-actions'
import { sendApiRequest, getAddressFromCoords } from 'utils/api'
import { getClientId } from 'utils/utils'

export const GET_CONSTRUCTION_ON_NETWORK_COUNT = 'GET_CONSTRUCTION_ON_NETWORK_COUNT'
export const getConstructionOnNetworkCount = (search) => {
  return (dispatch, getState) => {
    const clientId = getClientId(getState)
    const options = {
      where: {
        and: [
          { or: [{ clientId }, { clientId: null }] },
          { closed: false }
        ]
      }
    }
    if (search) {
      options.where.and.push(search)
    }
    dispatch(createAction(GET_CONSTRUCTION_ON_NETWORK_COUNT)())
    sendApiRequest('ConstructionOnNetwork/count', {
      accessToken: getState().auth.account.accessToken
    }, 'get', options).then((response) => {
      if (response.status === 'failure') {
        dispatch(getConstructionOnNetworkCountError(response))
      }
      if (response.status === 'success') {
        dispatch(getConstructionOnNetworkCountSuccess(response.data.count))
      }
    })
  }
}

export const GET_CONSTRUCTION_ON_NETWORK_COUNT_SUCCESS = 'GET_CONSTRUCTION_ON_NETWORK_COUNT_SUCCESS'
export const getConstructionOnNetworkCountSuccess = createAction(GET_CONSTRUCTION_ON_NETWORK_COUNT_SUCCESS)

export const GET_CONSTRUCTION_ON_NETWORK_COUNT_ERROR = 'GET_CONSTRUCTION_ON_NETWORK_COUNT_ERROR'
export const getConstructionOnNetworkCountError = createAction(GET_CONSTRUCTION_ON_NETWORK_COUNT_ERROR)

export const GET_CONSTRUCTION_ON_NETWORK = 'GET_CONSTRUCTION_ON_NETWORK'
export const getConstructionOnNetwork = (pageSize, pageNumber, search) => {
  return (dispatch, getState) => {
    dispatch(createAction(GET_CONSTRUCTION_ON_NETWORK)())
    const clientId = getClientId(getState)
    const options = {
      filter: {
        order: 'id DESC',
        where: {
          and: [
            { or: [{ clientId }, { clientId: null }] },
            { closed: false }
          ]
        }
      }
    }
    if (pageSize !== 0) {
      options.filter.limit = pageSize + 1
    } else {
      options.filter.limit = 0
    }
    if (pageNumber !== 1) {
      options.filter.skip = (pageNumber - 1) * pageSize
    }
    if (search) {
      options.filter.where.and.push(search)
    }
    sendApiRequest('ConstructionOnNetwork', {
      accessToken: getState().auth.account.accessToken
    }, 'get', options).then((response) => {
      if (response.status === 'failure') {
        dispatch(getConstructionOnNetworkError(response))
      }
      if (response.status === 'success') {
        dispatch(getConstructionOnNetworkSuccess(response.data))
      }
    })
  }
}

export const GET_CONSTRUCTION_ON_NETWORK_SUCCESS = 'GET_CONSTRUCTION_ON_NETWORK_SUCCESS'
export const getConstructionOnNetworkSuccess = createAction(GET_CONSTRUCTION_ON_NETWORK_SUCCESS)

export const GET_CONSTRUCTION_ON_NETWORK_ERROR = 'GET_CONSTRUCTION_ON_NETWORK_ERROR'
export const getConstructionOnNetworkError = createAction(GET_CONSTRUCTION_ON_NETWORK_ERROR)

export const GET_CONSTRUCTION_ON_NETWORK_BY_ID = 'GET_CONSTRUCTION_ON_NETWORK_BY_ID'
export const getConstructionOnNetworkById = (id) => {
  return (dispatch, getState) => {
    dispatch(createAction(GET_CONSTRUCTION_ON_NETWORK_BY_ID)())
    sendApiRequest(`ConstructionOnNetwork/${id}`, {
      accessToken: getState().auth.account.accessToken
    }, 'get').then((response) => {
      if (response.status === 'failure') {
        dispatch(getConstructionOnNetworkByIdError(response))
      }
      if (response.status === 'success') {
        dispatch(getConstructionOnNetworkByIdSuccess(response.data))
      }
    })
  }
}

export const GET_CONSTRUCTION_ON_NETWORK_BY_ID_SUCCESS = 'GET_CONSTRUCTION_ON_NETWORK_BY_ID_SUCCESS'
export const getConstructionOnNetworkByIdSuccess = createAction(GET_CONSTRUCTION_ON_NETWORK_BY_ID_SUCCESS)

export const GET_CONSTRUCTION_ON_NETWORK_BY_ID_ERROR = 'GET_CONSTRUCTION_ON_NETWORK_BY_ID_ERROR'
export const getConstructionOnNetworkByIdError = createAction(GET_CONSTRUCTION_ON_NETWORK_BY_ID_ERROR)

export const GET_CONSTRUCTION_ON_NETWORK_BY_ID_RESET = 'GET_CONSTRUCTION_ON_NETWORK_BY_ID_RESET'
export const getConstructionOnNetworkByIdReset = createAction(GET_CONSTRUCTION_ON_NETWORK_BY_ID_RESET)

export const DELETE_CONSTRUCTION_ON_NETWORK_BY_ID = 'DELETE_CONSTRUCTION_ON_NETWORK_BY_ID'
export const deleteConstructionOnNetworkById = (id) => {
  return (dispatch, getState) => {
    dispatch(createAction(DELETE_CONSTRUCTION_ON_NETWORK_BY_ID)())
    sendApiRequest(`ConstructionOnNetwork/${id}`, {
      accessToken: getState().auth.account.accessToken
    }, 'delete').then((response) => {
      if (response.status === 'failure') {
        dispatch(deleteConstructionOnNetworkByIdError(response.data))
      }
      if (response.status === 'success') {
        dispatch(deleteConstructionOnNetworkByIdSuccess(response.data))
      }
    })
  }
}

export const DELETE_CONSTRUCTION_ON_NETWORK_BY_ID_SUCCESS = 'DELETE_CONSTRUCTION_ON_NETWORK_BY_ID_SUCCESS'
export const deleteConstructionOnNetworkByIdSuccess = createAction(DELETE_CONSTRUCTION_ON_NETWORK_BY_ID_SUCCESS)

export const DELETE_CONSTRUCTION_ON_NETWORK_BY_ID_ERROR = 'DELETE_CONSTRUCTION_ON_NETWORK_BY_ID_ERROR'
export const deleteConstructionOnNetworkByIdError = createAction(DELETE_CONSTRUCTION_ON_NETWORK_BY_ID_ERROR)

export const DELETE_CONSTRUCTION_ON_NETWORK_BY_ID_RESET = 'DELETE_CONSTRUCTION_ON_NETWORK_BY_ID_RESET'
export const deleteConstructionOnNetworkByIdReset = createAction(DELETE_CONSTRUCTION_ON_NETWORK_BY_ID_RESET)

export const CREATE_NEW_CONSTRUCTION_ON_NETWORK = 'CREATE_NEW_CONSTRUCTION_ON_NETWORK'
export const createNewConstructionOnNetwork = (data) => {
  return (dispatch, getState) => {
    dispatch(createAction(CREATE_NEW_CONSTRUCTION_ON_NETWORK)())
    const clientId = getClientId(getState)
    const constructionOnNetworkData = data
    constructionOnNetworkData.clientId = clientId
    constructionOnNetworkData.accessToken = getState().auth.account.accessToken
    sendApiRequest('ConstructionOnNetwork', constructionOnNetworkData, 'post').then((response) => {
      if (response.status === 'failure') {
        dispatch(createNewConstructionOnNetworkError(response))
      }
      if (response.status === 'success') {
        dispatch(createNewConstructionOnNetworkSuccess(response.data))
        dispatch(getConstructionOnNetwork())
      }
    })
  }
}

export const CREATE_NEW_CONSTRUCTION_ON_NETWORK_SUCCESS = 'CREATE_NEW_CONSTRUCTION_ON_NETWORK_SUCCESS'
export const createNewConstructionOnNetworkSuccess = createAction(CREATE_NEW_CONSTRUCTION_ON_NETWORK_SUCCESS)

export const CREATE_NEW_CONSTRUCTION_ON_NETWORK_ERROR = 'CREATE_NEW_CONSTRUCTION_ON_NETWORK_ERROR'
export const createNewConstructionOnNetworkError = createAction(CREATE_NEW_CONSTRUCTION_ON_NETWORK_ERROR)

export const RESET_CONSTRUCTION_ON_NETWORK_FORM = 'RESET_CONSTRUCTION_ON_NETWORK_FORM'
export const resetConstructionOnNetworkForm = createAction(RESET_CONSTRUCTION_ON_NETWORK_FORM)

export const OPEN_CONSTRUCTION_ON_NETWORK_DIALOG = 'OPEN_CONSTRUCTION_ON_NETWORK_DIALOG'
export const openConstructionOnNetworkDialog = createAction(OPEN_CONSTRUCTION_ON_NETWORK_DIALOG)

export const CLOSE_CONSTRUCTION_ON_NETWORK_DIALOG = 'CLOSE_CONSTRUCTION_ON_NETWORK_DIALOG'
export const closeConstructionOnNetworkDialog = createAction(CLOSE_CONSTRUCTION_ON_NETWORK_DIALOG)

export const EDIT_CONSTRUCTION_ON_NETWORK = 'EDIT_CONSTRUCTION_ON_NETWORK'
export const editConstructionOnNetwork = (data) => {
  return (dispatch, getState) => {
    dispatch(createAction(CREATE_NEW_CONSTRUCTION_ON_NETWORK)())
    const constructionOnNetworkData = data
    constructionOnNetworkData.accessToken = getState().auth.account.accessToken
    sendApiRequest(`ConstructionOnNetwork/${data.id}`,
      constructionOnNetworkData, 'patch').then((response) => {
      if (response.status === 'failure') {
        dispatch(createNewConstructionOnNetworkError(response))
      }
      if (response.status === 'success') {
        dispatch(createNewConstructionOnNetworkSuccess(response.data))
        dispatch(getConstructionOnNetwork())
      }
    })
  }
}

export const REVERSE_GEOCODE_BY_COORDS = 'REVERSE_GEOCODE_BY_COORDS'
export const reverseGeocodeByCoords = (data) => {
  return (dispatch) => { // eslint-disable-next-line
    getAddressFromCoords(data).then((response) => {
      if (response.status === 'success') {
        dispatch(createAction(REVERSE_GEOCODE_BY_COORDS)(response.data))
      }
    })
  }
}

const initialState = {
  constructionOnNetworkCount: 0,
  isGettingConstructionOnNetwork: false,
  hasGottenConstructionOnNetwork: false,
  getConstructionOnNetworkError: {},
  constructionOnNetwork: [],
  selectedConstructionOnNetwork: {},
  isGettingConstructionOnNetworkById: false,
  hasGottenConstructionOnNetworkById: false,
  getConstructionOnNetworkByIdError: {},
  isDeletingConstructionOnNetwork: false,
  hasDeletedConstructionOnNetwork: false,
  deleteConstructionOnNetworkSuccess: {},
  deleteConstructionOnNetworkError: {},
  isCreatingConstructionOnNetwork: false,
  hasCreatedConstructionOnNetwork: false,
  createConstructionOnNetworkSuccess: {},
  createConstructionOnNetworkError: {},
  constructionOnNetworkDialogIsOpen: false,
  selectedConstructionOnNetworkToEdit: {},
  reverseGeocodeCoords: {}
}

export default handleActions({

  [GET_CONSTRUCTION_ON_NETWORK_COUNT_SUCCESS]: (state, { payload }) => ({
    ...state,
    constructionOnNetworkCount: payload
  }),

  [GET_CONSTRUCTION_ON_NETWORK]: (state) => ({
    ...state,
    isGettingConstructionOnNetwork: true,
    hasGottenConstructionOnNetwork: false
  }),

  [GET_CONSTRUCTION_ON_NETWORK_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isGettingConstructionOnNetwork: false,
      hasGottenConstructionOnNetwork: true,
      constructionOnNetwork: payload
    }
  },

  [GET_CONSTRUCTION_ON_NETWORK_ERROR]: (state, { payload }) => {
    return {
      ...state,
      isGettingConstructionOnNetwork: false,
      hasGottenConstructionOnNetwork: false,
      constructionOnNetworkError: payload
    }
  },

  [GET_CONSTRUCTION_ON_NETWORK_BY_ID]: (state) => ({
    ...state,
    isGettingConstructionOnNetworkById: true,
    hasGottenConstructionOnNetworkById: false
  }),

  [GET_CONSTRUCTION_ON_NETWORK_BY_ID_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isGettingConstructionOnNetworkById: false,
      hasGottenConstructionOnNetworkById: true,
      selectedConstructionOnNetwork: payload
    }
  },

  [GET_CONSTRUCTION_ON_NETWORK_BY_ID_ERROR]: (state, { payload }) => {
    return {
      ...state,
      isGettingConstructionOnNetworkById: false,
      hasGottenConstructionOnNetworkById: false,
      getConstructionOnNetworkError: payload
    }
  },
  [GET_CONSTRUCTION_ON_NETWORK_BY_ID_RESET]: (state) => ({
    ...state,
    isGettingConstructionOnNetworkById: false,
    hasGottenConstructionOnNetworkById: false,
    selectedConstructionOnNetwork: {}
  }),

  [DELETE_CONSTRUCTION_ON_NETWORK_BY_ID]: (state) => ({
    ...state,
    isDeletingConstructionOnNetwork: true,
    hasDeletedConstructionOnNetwork: false
  }),

  [DELETE_CONSTRUCTION_ON_NETWORK_BY_ID_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isDeletingConstructionOnNetwork: false,
      hasDeletedConstructionOnNetwork: true,
      deleteConstructionOnNetworkSuccess: payload
    }
  },

  [DELETE_CONSTRUCTION_ON_NETWORK_BY_ID_ERROR]: (state, { payload }) => {
    return {
      ...state,
      isDeletingConstructionOnNetwork: false,
      hasDeletedConstructionOnNetwork: false,
      deleteConstructionOnNetworkError: payload
    }
  },

  [DELETE_CONSTRUCTION_ON_NETWORK_BY_ID_RESET]: (state) => {
    return {
      ...state,
      isDeletingConstructionOnNetwork: false,
      hasDeletedConstructionOnNetwork: false,
      deleteConstructionOnNetworkError: {},
      deleteConstructionOnNetworkSuccess: {}
    }
  },

  [OPEN_CONSTRUCTION_ON_NETWORK_DIALOG]: (state, { payload }) => {
    return {
      ...state,
      constructionOnNetworkDialogIsOpen: true,
      selectedConstructionOnNetworkToEdit: payload
    }
  },

  [CLOSE_CONSTRUCTION_ON_NETWORK_DIALOG]: (state) => {
    return {
      ...state,
      constructionOnNetworkDialogIsOpen: false,
      selectedConstructionOnNetworkToEdit: {},
      isCreatingConstructionOnNetwork: false,
      hasCreatedConstructionOnNetwork: false,
      createConstructionOnNetworkSuccess: {},
      createConstructionOnNetworkError: {}
    }
  },

  [CREATE_NEW_CONSTRUCTION_ON_NETWORK]: (state) => {
    return {
      ...state,
      isCreatingConstructionOnNetwork: true,
      hasCreatedConstructionOnNetwork: false,
      createConstructionOnNetworkSuccess: {},
      createConstructionOnNetworkError: {}
    }
  },

  [CREATE_NEW_CONSTRUCTION_ON_NETWORK_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isCreatingConstructionOnNetwork: false,
      hasCreatedConstructionOnNetwork: true,
      createConstructionOnNetworkSuccess: payload
    }
  },

  [CREATE_NEW_CONSTRUCTION_ON_NETWORK_ERROR]: (state, { payload }) => {
    return {
      ...state,
      isCreatingConstructionOnNetwork: false,
      hasCreatedConstructionOnNetwork: false,
      createConstructionOnNetworkError: payload
    }
  },

  [REVERSE_GEOCODE_BY_COORDS]: (state, { payload }) => {
    return {
      ...state,
      reverseGeocodeCoords: payload
    }
  },

  [RESET_CONSTRUCTION_ON_NETWORK_FORM]: (state) => {
    return {
      ...state,
      isGettingConstructionOnNetworkById: false,
      hasGottenConstructionOnNetworkById: false,
      selectedConstructionOnNetwork: {}
    }
  },

}, initialState)
