import { requireAuth } from 'routes'

export default (store) => ({
  childRoutes: [
    {
      path: '/content/report/get_network_report/:month/:year',
      onEnter: requireAuth(store),
      getComponent (nextState, cb) {
        require.ensure([], (require) => {
          const NetworkReports = require('./containers/NetworkReports').default
          cb(null, NetworkReports)
        }, 'networkreports')
      }
    },
    {
      path: '/content/report/edit_audit/audit/:id',
      onEnter: requireAuth(store),
      getComponent (nextState, cb) {
        require.ensure([], (require) => {
          const AssetAuditEdit = require('./containers/AssetAuditEditContainer').default
          cb(null, AssetAuditEdit)
        }, 'networkreports')
      }
    }
  ]
})
