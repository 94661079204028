import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { browserHistory } from 'react-router'
import {
  Drawer,
  ListItemText,
  ListItemIcon,
  Collapse,
  List,
  ListItemButton
} from '@mui/material'
import {
  openSidebar,
  closeSidebar,
  openPermissionDeniedDialog,
  closePermissionDeniedDialog
} from 'store/modules/site'
import { getNetworks, adminSelectNetwork } from 'store/modules/networks'
import { getMergedPermissionKeys } from 'store/modules/auth'
import UserInformation from 'components/UserInformation/UserInformation'
import NetworkSelect from 'components/NetworkSelect/NetworkSelect'
import PermissionDeniedDialog from 'components/PermissionDeniedDialog/PermissionDeniedDialog'
import {
  ExpandLess,
  ExpandMore,
  Home,
  Domain,
  AirportShuttle,
  ViewList,
  FolderOpen,
  InsertDriveFile,
  QuestionAnswer,
  DateRange,
  BorderAll,
  Map as MapIcon,
  RecentActors,
  Public as PublicIcon,
  FolderShared,
  Place
} from '@mui/icons-material'

import style from './Sidebar.module.scss'
import config from 'config'
const { adminFunctions, sidebarOptions } = config

const propTypes = {
  user: PropTypes.object,
  sidebarIsOpen: PropTypes.bool,
  isBigScreen: PropTypes.bool,
  openSidebar: PropTypes.func,
  closeSidebar: PropTypes.func,
  getMergedPermissionKeys: PropTypes.func,
  permKeys: PropTypes.array,
  permissionDeniedDialogIsOpen: PropTypes.bool,
  openPermissionDeniedDialog: PropTypes.func,
  closePermissionDeniedDialog: PropTypes.func,
  getNetworks: PropTypes.func,
  networks: PropTypes.array,
  adminSelectedNetwork: PropTypes.number,
  adminSelectNetwork: PropTypes.func
}

// const listItemStyle = {
//   marginLeft: '-10px',
//   color: '#ffccbc',
//   fontSize: '13px',
//   lineHeight: '1.45'
// }
const menuIconColor = '#ffccbc'

const MenuWithNested = ({
  disabled, callback, title, icon, items, visible,
  handleDisabled
}) => {
  const [open, setOpen] = React.useState(false)

  const handleOpenMenu = () => {
    if (disabled === true) {
      handleDisabled()
    } else {
      setOpen(!open)
      if (callback) { callback() }
    }
  }
  const expand = (open, items, disabled) => {
    if (items && items.length > 0 && !disabled) {
      if (open) { return <ExpandLess /> } else { return <ExpandMore /> }
    } else {
      return ''
    }
  }
  if (visible) {
    return (
      <List>
        <ListItemButton key={title} onClick={handleOpenMenu}>
          {icon ? (<ListItemIcon>{icon}</ListItemIcon>) : ''}
          <ListItemText primary={title} color={menuIconColor} />
          {expand(open, items, disabled)}
        </ListItemButton>
        {items && items.length > 0
          ? <Collapse in={open} timeout='auto' unmountOnExit>
            <List component='div' disablePadding>
              {items}
            </List>
          </Collapse>
          : ''}
      </List>
    )
  } else return ''
}

MenuWithNested.propTypes = {
  items: PropTypes.array,
  title: PropTypes.string,
  // enabled: PropTypes.bool,
  icon: PropTypes.object,
  // nestedItems: PropTypes.array,
  callback: PropTypes.func,
  disabled: PropTypes.bool,
  handleDisabled: PropTypes.func,
  visible: PropTypes.bool
}

class Sidebar extends Component {
  constructor (props) {
    super(props)
    this.state = {
      assets: false,
      members: false,
      manholes: false,
      incidents: false,
      works: false,
      nce: false,
      reports: false,
      reportsChild: '',
      dashboard: false,
      dashboardChild: ''
    }
  }

  componentDidMount () {
    if (this.props.isBigScreen) {
      this.props.openSidebar()
    }
    this.props.getMergedPermissionKeys()
  }

  handleChildItemOpen (id, type) {
    if (type === 'dashboard') {
      if (id === this.state.dashboardChild) {
        return true
      } else {
        return false
      }
    }
    if (type === 'reports') {
      if (id === this.state.reportsChild) {
        return true
      } else {
        return false
      }
    }
  }

  handleNestedChildItem (id, type) {
    if (type === 'dashboard') {
      if (this.state.dashboardChild === id) {
        this.setState({ dashboardChild: '' })
      } else {
        this.setState({ dashboardChild: id })
      }
    }
    if (type === 'reports') {
      if (this.state.reportsChild === id) {
        this.setState({ reportsChild: '' })
      } else {
        this.setState({ reportsChild: id })
      }
    }
  }

  handleReportsChildItem (id) {
    this.setState({ reportsChild: id })
  }

  handleParentMenuItemClick (item) {
    const currentState = this.state[item]
    const obj = {}
    if (currentState === false) {
      obj[item] = true
    } else {
      obj[item] = false
    }
    this.setState(obj)
  }

  componentDidUpdate (prevProps) {
    if (this.props.isBigScreen !== prevProps.isBigScreen) {
      if (this.props.isBigScreen === false) {
        this.props.closeSidebar()
      }
      if (this.props.isBigScreen === true) {
        this.props.openSidebar()
      }
    }
  }

  renderAdminNetworkChange () {
    return (
      <div>
        <NetworkSelect
          networks={this.props.networks}
          adminSelectedNetwork={this.props.adminSelectedNetwork}
          adminSelectNetwork={this.props.adminSelectNetwork}
          getNetworks={this.props.getNetworks}
        />
      </div>
    )
    // if (adminFunctions.enabled.networks) {
    //   if (this.props.user && this.props.user.userType &&
    //     this.props.user.userType === 'Admin') {
    //     return (
    //       <div>
    //         <NetworkSelect />
    //       </div>
    //     )
    //   }
    // }
  }

  renderDateList (limit, urlPart, type) {
    const today = new Date()
    const menuArray = []
    for (let count = 0; count < limit; count++) {
      const d = new Date(today.getFullYear() - count, 0)
      const label = d.getFullYear().toString()
      const key = type + label
      menuArray.push(<MenuWithNested title={label} key={key}
        items={this.renderMonthList(d, urlPart)} visible enabled />)
    }
    return menuArray
  }

  renderMonthList (date, urlPart) {
    const monthNames = ['January', 'February', 'March', 'April', 'May',
      'June', 'July', 'August', 'September', 'October', 'November', 'December']
    const today = new Date()
    const menuArray = []
    let limit = 12
    const year = date.getFullYear()
    if (year === today.getFullYear()) {
      limit = today.getMonth() + 1
    }
    for (let count = 0; count < limit; count++) {
      const label = monthNames[count]
      const formattedMonth = ('0' + (count + 1)).slice(-2)
      const url = `${urlPart}/${formattedMonth}/${year}`
      menuArray.unshift(<MenuWithNested key={url}
      title={label} callback={() => { browserHistory.push(url) }} visible enabled />)
    }
    return menuArray
  }

  handlePermissionDenied = () => {
    this.props.openPermissionDeniedDialog()
  }

  // renderLtaReport () {
  //   if (this.props.permKeys.includes('export_lta_report') ||
  //   this.props.user.userType === 'Admin') {
  //     return (
  //       <ListItem
  //         style={listItemStyle}
  //         leftIcon={<Domain color={menuIconColor} />}
  //         containerElement={<Link to='/content/ltareport' />}
  //         primaryText='Generate LTA Report'
  //       />
  //     )
  //   } else {
  //     return (
  //       <ListItem
  //         style={listItemStyle}
  //         leftIcon={<Domain color={menuIconColor} />}
  //         onClick={this.handlePermissionDenied}
  //         primaryText='Generate LTA Report'
  //       />
  //     )
  //   }
  // }

  render () {
    const siteWorks = 'Sites Works'
    const workflow = 'Network Activity'
    const drawerWidth = 256
    const drawerStyle = {
      top: '65px',
      backgroundColor: '#bf360c',
      color: 'white',
      paddingBottom: '80px',
      paddingLeft: '10px',
      width: drawerWidth,
      boxSizing: 'border-box'
    }
    let numberOfYears = 2
    if (sidebarOptions && sidebarOptions.titles) {
      if (sidebarOptions.titles.networkReportYears) {
        numberOfYears = sidebarOptions.titles.networkReportYears
      }
    }
    let networkReportsTitle = 'Network Audit'
    if (sidebarOptions && sidebarOptions.titles) {
      if (sidebarOptions.titles.networkReportsTitle) {
        networkReportsTitle = sidebarOptions.titles.networkReportsTitle
      }
    }
    const worksArray = [
      <MenuWithNested
        key='rec-1'
        title='Recommended'
        disabled={!this.props.permKeys.includes('review_recom_works') ||
        !this.props.user.userType === 'Admin'}
        callback={() => browserHistory.push('/content/report/get_rectification_works/recommended')}
        handleDisabled={this.handlePermissionDenied}
        visible
      />,
      <MenuWithNested
        key='rec-2'
        title='Requested Quotes'
        disabled={!this.props.permKeys.includes('review_req_quotes') ||
        !this.props.user.userType === 'Admin'}
        callback={() => browserHistory.push('/content/report/get_rectification_works/approved')}
        handleDisabled={this.handlePermissionDenied}
        visible
      />,
      <MenuWithNested
        key='rec-3'
        title='Scheduled'
        disabled={!this.props.permKeys.includes('review_sched_works') ||
        !this.props.user.userType === 'Admin'}
        callback={() => browserHistory.push('/content/report/get_rectification_works/scheduled')}
        handleDisabled={this.handlePermissionDenied}
        visible
      />,
      <MenuWithNested
        key='rec-4'
        title='Completed'
        disabled={!this.props.permKeys.includes('review_comp_work') ||
        !this.props.user.userType === 'Admin'}
        callback={() => browserHistory.push('/content/report/get_rectification_works/completed')}
        handleDisabled={this.handlePermissionDenied}
        visible
      />
    ]
    const reportsArray = [
      <MenuWithNested
        key='report-1'
        title='Incidents'
        disabled={!this.props.permKeys.includes('review_new_sr') ||
        !this.props.user.userType === 'Admin'}
        callback={() => browserHistory.push('/content/service_request/get_requests/new')}
        handleDisabled={this.handlePermissionDenied}
        visible
      />,
      <MenuWithNested
        key='report-2'
        title='Scheduled Follow-ups'
        disabled={!this.props.permKeys.includes('review_sched_sr') ||
        !this.props.user.userType === 'Admin'}
        callback={() => browserHistory.push('/content/service_request/get_requests/scheduled')}
        handleDisabled={this.handlePermissionDenied}
        visible
      />,
      <MenuWithNested
        key='report-3'
        title='Completed Follow-ups'
        disabled={!this.props.permKeys.includes('review_comp_sr') ||
        !this.props.user.userType === 'Admin'}
        callback={() => browserHistory.push('/content/service_request/get_requests/completed')}
        handleDisabled={this.handlePermissionDenied}
        visible
      />,
      <MenuWithNested
        key='report-4'
        title='Closed'
        disabled={!this.props.permKeys.includes('review_closed_sr') ||
        !this.props.user.userType === 'Admin'}
        callback={() => browserHistory.push('/content/service_request/get_requests/closed')}
        handleDisabled={this.handlePermissionDenied}
        visible
      />
    ]
    const nceArray = [
      <MenuWithNested
        key='nce-1'
        title='OnNet Construction With Map'
        disabled={adminFunctions.disabled.onNetConstruction}
        handleDisabled={this.handlePermissionDenied}
        callback={() => browserHistory.push('/content/construction_on_network')}
        visible={adminFunctions.enabled.onNetConstruction ||
          adminFunctions.disabled.onNetConstruction || adminFunctions.enabled.onNetConstructionPreview}
      />,
      <MenuWithNested
        key='nce-2'
        title='NCE Online With Map'
        disabled={!this.props.permKeys.includes('review_nce') ||
        !this.props.user.userType === 'Admin'}
        handleDisabled={this.handlePermissionDenied}
        callback={() => browserHistory.push('/content/nce_report/online')}
        visible={adminFunctions.enabled.nceLegacy}
      />,
      <MenuWithNested
        key='nce-3'
        title='NCE Legacy Reports'
        disabled={!this.props.permKeys.includes('review_nce') ||
        !this.props.user.userType === 'Admin'}
        handleDisabled={this.handlePermissionDenied}
        callback={() => browserHistory.push('/content/nce_report/reports')}
        visible={adminFunctions.enabled.nceLegacy}
      />,
    ]
    const mucArray = [
      <MenuWithNested
        key='muc-1'
        title='Live Sites'
        disabled={!this.props.permKeys.includes('review_muc') &&
        !this.props.user.userType === 'Admin'}
        callback={() => browserHistory.push('/content/muc/open')}
        visible={adminFunctions.enabled.manholesOnSites}
        handleDisabled={this.handlePermissionDenied}
      />,
      <MenuWithNested
        key='muc-2'
        title='Closed Sites'
        disabled={!this.props.permKeys.includes('review_muc') &&
        !this.props.user.userType === 'Admin'}
        callback={() => browserHistory.push('/content/muc/closed')}
        visible={adminFunctions.enabled.manholesOnSites}
        handleDisabled={this.handlePermissionDenied}
      />
    ]
    return (
      <div id='Sidebar'>
        <Drawer
          variant={this.props.sidebarIsOpen ? 'permanent' : 'temporary'}
          hideBackdrop
          sx={{
            width: drawerWidth,
            top: '65px',
            '& .MuiDrawer-paper': drawerStyle,
          }}
          open={this.props.sidebarIsOpen}
          >
          {this.renderAdminNetworkChange()}
          <UserInformation />
          <MenuWithNested
            title='Dashboard'
            items={this.renderDateList(numberOfYears, '/content/dashboard/index', 'dashboard')}
            icon={<Home sx={{ color: menuIconColor }} />}
            enabled={adminFunctions.enabled.dashboard}
            disabled={adminFunctions.disabled.dashboard}
            handleDisabled={this.handlePermissionDenied}
            callback={() => browserHistory.push('/content/dashboard')}
            visible
          />
          <div className={style.menuDividerLabel}>{siteWorks}</div>
          <MenuWithNested
            title={networkReportsTitle}
            items={this.renderDateList(numberOfYears, '/content/report/get_network_report', 'reports')}
            icon={<Domain sx={{ color: menuIconColor }} />}
            enabled={adminFunctions.enabled.dashboard}
            disabled={adminFunctions.disabled.dashboard}
            handleDisabled={this.handlePermissionDenied}
            visible
          />
          <MenuWithNested
            title='Rectification Works'
            icon={<AirportShuttle sx={{ color: menuIconColor }} />}
            enabled={adminFunctions.enabled.recommendedWorks}
            disabled={adminFunctions.disabled.recommendedWorks}
            handleDisabled={this.handlePermissionDenied}
            items={worksArray}
            visible
          />
          <MenuWithNested
            title='Incidents'
            icon={<ViewList sx={{ color: menuIconColor }} />}
            enabled={adminFunctions.enabled.incidents}
            disabled={adminFunctions.disabled.incidents}
            handleDisabled={this.handlePermissionDenied}
            items={reportsArray}
            visible={adminFunctions.enabled.incidents}
          />
        <div className={style.menuDividerLabel}>{workflow}</div>
        <MenuWithNested
          title={(adminFunctions.enabled.onNetConstruction || adminFunctions.enabled.onNetConstructionPreview ||
            adminFunctions.disabled.onNetConstruction)
            ? 'OnNet Construction With Map'
            : 'NCE Online With Map'}
          icon={<FolderOpen sx={{ color: menuIconColor }} />}
          enabled={adminFunctions.enabled.onNetConstruction ||
            adminFunctions.disabled.onNetConstruction ||
            adminFunctions.enabled.onNetConstructionPreview ||
            adminFunctions.enabled.nceOnline}
          handleDisabled={this.handlePermissionDenied}
          items={nceArray}
          visible={adminFunctions.enabled.onNetConstruction ||
            adminFunctions.enabled.onNetConstructionPreview ||
            adminFunctions.disabled.onNetConstruction ||
            adminFunctions.enabled.nceOnline}
        />
        <MenuWithNested
          title='Manholes On Construction Sites'
          icon={<InsertDriveFile sx={{ color: menuIconColor }} />}
          enabled={this.props.permKeys.includes('review_muc') ||
          this.props.user.userType === 'Admin'}
          handleDisabled={this.handlePermissionDenied}
          items={mucArray}
          visible={adminFunctions.enabled.manholesOnSites}
        />
        <MenuWithNested
          title='Utility Requests'
          icon={<QuestionAnswer sx={{ color: menuIconColor }} />}
          enabled={this.props.permKeys.includes('review_utility') ||
          this.props.user.userType === 'Admin'}
          handleDisabled={this.handlePermissionDenied}
          visible={adminFunctions.enabled.utilityRequests}
          disabled={adminFunctions.disabled.utilityRequests}
          callback={() => browserHistory.push('/content/utility')}
        />
        <MenuWithNested
          title='Planned Work'
          icon={<DateRange sx={{ color: menuIconColor }} />}
          enabled={this.props.permKeys.includes('review_npw') ||
          this.props.user.userType === 'Admin'}
          handleDisabled={this.handlePermissionDenied}
          visible={adminFunctions.enabled.plannedWork}
          disabled={adminFunctions.disabled.plannedWork}
          callback={() => browserHistory.push('/content/npw')}
        />
        <MenuWithNested
          title='Inventory'
          icon={<BorderAll sx={{ color: menuIconColor }} />}
          enabled={this.props.permKeys.includes('review_spares') ||
          this.props.user.userType === 'Admin'}
          handleDisabled={this.handlePermissionDenied}
          visible={adminFunctions.enabled.spares}
          disabled={adminFunctions.disabled.spares}
          callback={() => browserHistory.push('/content/spares')}
        />
        <MenuWithNested
          title='Document Management'
          icon={<FolderShared sx={{ color: menuIconColor }} />}
          enabled={this.props.permKeys.includes('review_documents') ||
          this.props.user.userType === 'Admin'}
          handleDisabled={this.handlePermissionDenied}
          visible={adminFunctions.enabled.documentManagement}
          disabled={adminFunctions.disabled.documentManagement}
          callback={() => browserHistory.push('/content/documents')}
        />
        <MenuWithNested
          title='Patrol Officer Locator'
          icon={<Place sx={{ color: menuIconColor }} />}
          enabled={this.props.user.userType === 'Admin'}
          callback={() => browserHistory.push('/content/accounts/map')}
          visible={this.props.user.userType === 'Admin'}
        />
        <div className={style.menuDividerLabel}>Settings</div>
        <MenuWithNested
          title='Assets & Routes'
          icon={<MapIcon sx={{ color: menuIconColor }} />}
          enabled={this.props.permKeys.includes('view_assets') ||
          this.props.user.userType === 'Admin'}
          handleDisabled={this.handlePermissionDenied}
          visible={adminFunctions.enabled.assets}
          disabled={adminFunctions.disabled.assets}
          callback={() => browserHistory.push('/content/asset')}
          items={[
            <MenuWithNested
              key='assets-1'
              title='Assets'
              enabled={this.props.permKeys.includes('view_assets') ||
              this.props.user.userType === 'Admin'}
              callback={() => browserHistory.push('/content/asset')}
              visible={adminFunctions.enabled.assets}
            disabled={adminFunctions.disabled.assets}
            />,
            <MenuWithNested
              key='assets-2'
              title='Routes'
              enabled={this.props.permKeys.includes('view_routes') ||
              this.props.user.userType === 'Admin'}
              callback={() => browserHistory.push('/content/routes')}
              visible={adminFunctions.enabled.routes}
              disabled={adminFunctions.disabled.routes}
            />
          ]}
        />
        <MenuWithNested
          title='Members'
          icon={<RecentActors sx={{ color: menuIconColor }} />}
          enabled={this.props.user.userType === 'Admin'}
          handleDisabled={this.handlePermissionDenied}
          visible={adminFunctions.enabled.administrators ||
          adminFunctions.enabled.networkManagers}
          items={[
            <MenuWithNested
              key='members-1'
              title='Administrators'
              enabled={adminFunctions.enabled.administrators &&
              this.props.user.userType === 'Admin'}
              callback={() => browserHistory.push('/content/accounts/admin')}
              visible={adminFunctions.enabled.administrators}
              disabled={adminFunctions.disabled.administrators}
            />,
            <MenuWithNested
              key='members-2'
              title='Auditors'
              enabled={adminFunctions.enabled.auditors &&
              this.props.user.userType === 'Admin'}
              callback={() => browserHistory.push('/content/accounts/auditor')}
              visible={adminFunctions.enabled.auditors}
              disabled={adminFunctions.disabled.auditors}
            />,
            <MenuWithNested
              key='members-3'
              title='Network Managers'
              enabled={adminFunctions.enabled.networkManagers &&
              this.props.user.userType === 'Admin'}
              callback={() => browserHistory.push('/content/accounts/user')}
              visible={adminFunctions.enabled.networkManagers}
              disabled={adminFunctions.disabled.networkManagers}
            />,
            <MenuWithNested
              key='members-4'
              title='Subusers'
              enabled={adminFunctions.enabled.subusers &&
              this.props.user.userType === 'Admin'}
              callback={() => browserHistory.push('/content/accounts/subuser')}
              visible={adminFunctions.enabled.subusers}
              disabled={adminFunctions.disabled.subusers}
            />
          ]}
        />
        <MenuWithNested
          title='Networks'
          icon={<PublicIcon sx={{ color: menuIconColor }} />}
          enabled={adminFunctions.enabled.networks &&
          this.props.user.userType === 'Admin'}
          callback={() => browserHistory.push('/content/network')}
          visible={adminFunctions.enabled.networks}
          disabled={adminFunctions.disabled.networks}
        />
        </Drawer>
        <PermissionDeniedDialog
          open={this.props.permissionDeniedDialogIsOpen}
          closeCallback={this.props.closePermissionDeniedDialog}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    network: state.auth.network,
    sidebarIsOpen: state.site.sidebarIsOpen,
    isMobile: state.browser.lessThan.medium,
    isBigScreen: state.browser.greaterThan.medium,
    permKeys: state.auth.permKeys,
    permissionDeniedDialogIsOpen: state.site.permissionDeniedDialogIsOpen,
    networks: state.networks.networks,
    adminSelectedNetwork: state.networks.adminSelectedNetwork
  }
}
const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({
    openSidebar,
    closeSidebar,
    getMergedPermissionKeys,
    openPermissionDeniedDialog,
    closePermissionDeniedDialog,
    getNetworks,
    adminSelectNetwork
  }, dispatch),
})

Sidebar.propTypes = propTypes

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Sidebar)
