import { requireAuth } from 'routes'

export default (store) => ({
  childRoutes: [
    {
      path: '/content/service_request/get_requests/:type',
      onEnter: requireAuth(store),
      getComponent (nextState, cb) {
        require.ensure([], (require) => {
          const SitesReports = require('./containers/SitesReportsContainer').default
          cb(null, SitesReports)
        }, 'sitereports')
      }
    },
    {
      path: '/content/service_request/post_send_email/:id',
      onEnter: requireAuth(store),
      getComponent (nextState, cb) {
        require.ensure([], (require) => {
          const SitesReportsMail = require('./containers/SitesReportsMail').default
          cb(null, SitesReportsMail)
        }, 'sitereportsmail')
      }
    },
    {
      path: '/content/service_request/edit/:id',
      onEnter: requireAuth(store),
      getComponent (nextState, cb) {
        require.ensure([], (require) => {
          const SitesReportsEdit = require('./containers/SiteReportsEditContainer').default
          cb(null, SitesReportsEdit)
        }, 'sitereportsedit')
      }
    }
  ]
})
