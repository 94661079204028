import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Dialog, DialogContent, DialogActions } from '@mui/material'
class PermissionDeniedDialog extends Component {
  handleConfirm = () => {
    // this.props.confirmCallback(this.props.value)
    this.props.closeCallback()
  }

  handleCancel = () => {
    if (this.props.cancelCallback) {
      this.props.cancelCallback()
    }
    this.props.closeCallback()
  }

  handleClose = () => {
    this.props.closeCallback()
  }

  render () {
    let message = `You do not have permission to access this function.
    Please contact us to upgrade your account.`
    if (this.props.message) { message = this.props.message }
    return (
      <Dialog
        open={this.props.open}
        onClose={() => {
          this.handleClose()
        }}
      >
        <DialogContent>
          {message}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.handleConfirm() }>Close</Button>
        </DialogActions>
      </Dialog>
    )
  }
}

PermissionDeniedDialog.propTypes = {
  // confirmCallback: PropTypes.func,
  cancelCallback: PropTypes.func,
  closeCallback: PropTypes.func,
  open: PropTypes.bool,
  // value: PropTypes.oneOfType([
  //   PropTypes.string,
  //   PropTypes.object
  // ]),
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ])
}

export default PermissionDeniedDialog
