import { createAction, handleActions } from 'redux-actions'
import { sendApiRequest } from 'utils/api'
import { getClientId } from 'utils/utils'

export const GET_ACCOUNTS = 'GET_ACCOUNTS'
export const getAccounts = (role) => {
  return (dispatch, getState) => {
    dispatch(createAction(GET_ACCOUNTS)())
    const userType = role.charAt(0).toUpperCase() + role.slice(1)
    const clientId = getClientId(getState)
    const options = {
      filter: {
        where: {
          userClientId: clientId
        }
      }
    }
    sendApiRequest(`Accounts/${userType}`, {
      accessToken: getState().auth.account.accessToken,
    }, 'get', options).then((response) => {
      if (response.status === 'failure') {
        dispatch(getAccountsError(response))
      }
      if (response.status === 'success') {
        dispatch(getAccountsSuccess(response.data))
      }
    })
  }
}

export const GET_ACCOUNTS_SUCCESS = 'GET_ACCOUNTS_SUCCESS'
export const getAccountsSuccess = createAction(GET_ACCOUNTS_SUCCESS)

export const GET_ACCOUNTS_ERROR = 'GET_ACCOUNTS_ERROR'
export const getAccountsError = createAction(GET_ACCOUNTS_ERROR)

export const OPEN_ACCOUNT_DIALOG = 'OPEN_ACCOUNT_DIALOG'
export const openAccountDialog = (data) => {
  return (dispatch) => {
    dispatch(createAction(OPEN_ACCOUNT_DIALOG)(data))
  }
}

export const CLOSE_ACCOUNT_DIALOG = 'CLOSE_ACCOUNT_DIALOG'
export const closeAccountDialog = createAction(CLOSE_ACCOUNT_DIALOG)

export const CREATE_NEW_ACCOUNT = 'CREATE_NEW_ACCOUNT'
export const createNewAccount = (data) => {
  return (dispatch, getState) => {
    dispatch(createAction(CREATE_NEW_ACCOUNT)())
    // const userClientId = getClientId(getState)
    const accountData = data
    const accessToken = getState().auth.account.accessToken
    accountData.accessToken = accessToken
    // accountData.userClientId = userClientId
    sendApiRequest('Accounts', accountData, 'post').then((response) => {
      if (response.status === 'failure') {
        dispatch(createNewAccountError(response))
      }
      if (response.status === 'success') {
        if (response.data.userId) {
          data.permIds.forEach((id) => {
            sendApiRequest(`Accounts/${response.data.userId}/clientPermissions`, {
              permId: id,
              accessToken
            }, 'post')
          })
          dispatch(createNewAccountSuccess(response.data))
        }
      }
    })
  }
}

export const CREATE_NEW_ACCOUNT_SUCCESS = 'CREATE_NEW_ACCOUNT_SUCCESS'
export const createNewAccountSuccess = createAction(CREATE_NEW_ACCOUNT_SUCCESS)

export const CREATE_NEW_ACCOUNT_ERROR = 'CREATE_NEW_ACCOUNT_ERROR'
export const createNewAccountError = createAction(CREATE_NEW_ACCOUNT_ERROR)

export const RESET_ACCOUNT_FORM = 'RESET_ACCOUNT_FORM'
export const resetAccountForm = createAction(RESET_ACCOUNT_FORM)

export const GET_ACCOUNT_BY_ID = 'GET_ACCOUNT_BY_ID'
export const getAccountById = (id) => {
  return (dispatch, getState) => {
    dispatch(createAction(GET_ACCOUNT_BY_ID)())
    sendApiRequest(`Accounts/${id}`, {
      accessToken: getState().auth.account.accessToken
    }, 'get').then((response) => {
      if (response.status === 'success') {
        dispatch(getAccountByIdSuccess(response.data))
      }
    })
  }
}

export const GET_ACCOUNT_BY_ID_SUCCESS = 'GET_ACCOUNT_BY_ID_SUCCESS'
export const getAccountByIdSuccess = createAction(GET_ACCOUNT_BY_ID_SUCCESS)

export const OPEN_EDIT_ACCOUNT_DIALOG = 'OPEN_EDIT_ACCOUNT_DIALOG'
export const openEditAccountDialog = (data) => {
  return (dispatch) => {
    dispatch(createAction(OPEN_EDIT_ACCOUNT_DIALOG)(data))
    dispatch(getAccountById(data))
  }
}

export const CLOSE_EDIT_ACCOUNT_DIALOG = 'CLOSE_EDIT_ACCOUNT_DIALOG'
export const closeEditAccountDialog = createAction(CLOSE_EDIT_ACCOUNT_DIALOG)

export const RESET_EDIT_ACCOUNT_FORM = 'RESET_EDIT_ACCOUNT_FORM'
export const resetEditAccountForm = createAction(RESET_EDIT_ACCOUNT_FORM)

export const EDIT_ACCOUNT = 'EDIT_ACCOUNT'
export const editAccount = (data) => {
  return (dispatch, getState) => {
    dispatch(createAction(EDIT_ACCOUNT)())
    const accountData = data
    data.accessToken = getState().auth.account.accessToken
    sendApiRequest(`Accounts/${data.userId}`, accountData, 'patch').then((response) => {
      if (response.status === 'failure') {
        dispatch(editAccountError(response))
      }
      if (response.status === 'success') {
        dispatch(editAccountSuccess(response.data))
      }
    })
  }
}

export const EDIT_ACCOUNT_SUCCESS = 'EDIT_ACCOUNT_SUCCESS'
export const editAccountSuccess = createAction(EDIT_ACCOUNT_SUCCESS)

export const EDIT_ACCOUNT_ERROR = 'EDIT_ACCOUNT_ERROR'
export const editAccountError = createAction(EDIT_ACCOUNT_ERROR)

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const changePassword = (data) => {
  return (dispatch, getState) => {
    dispatch(createAction(CHANGE_PASSWORD)())
    const accountData = data
    data.accessToken = getState().auth.account.accessToken
    sendApiRequest('Accounts/change-password', accountData, 'post').then((response) => {
      if (response.status === 'failure') {
        dispatch(editAccountError(response))
      }
      if (response.status === 'success') {
        dispatch(editAccountSuccess({}))
      }
    })
  }
}

export const EDIT_ACCOUNT_PERMISSIONS = 'EDIT_ACCOUNT_PERMISSIONS'
export const editAccountPermissions = (data) => {
  return (dispatch, getState) => {
    const accessToken = getState().auth.account.accessToken
    dispatch(createAction(EDIT_ACCOUNT_PERMISSIONS)())
    sendApiRequest(`Accounts/${data.userId}/clientPermissions`, {
      accessToken
    }, 'delete').then((response) => {
      if (response.status === 'success') {
        data.permIds.forEach((id) => {
          sendApiRequest(`Accounts/${data.userId}/clientPermissions`, {
            permId: id,
            accessToken
          }, 'post').then((response) => {
            console.log(response)
          })
        })
        dispatch(editAccountPermissionsSuccess())
      }
    })
  }
}

export const EDIT_ACCOUNT_PERMISSIONS_SUCCESS = 'EDIT_ACCOUNT_PERMISSIONS_SUCCESS'
export const editAccountPermissionsSuccess = createAction(EDIT_ACCOUNT_PERMISSIONS_SUCCESS)

export const EDIT_ACCOUNT_PERMISSIONS_ERROR = 'EDIT_ACCOUNT_PERMISSIONS_ERROR'
export const editAccountPermissionsError = createAction(EDIT_ACCOUNT_PERMISSIONS_ERROR)

export const DELETE_ACCOUNT_BY_ID = 'DELETE_ACCOUNT_BY_ID'
export const deleteAccountById = (id) => {
  return (dispatch, getState) => {
    dispatch(createAction(DELETE_ACCOUNT_BY_ID)())
    const accessToken = getState().auth.account.accessToken
    sendApiRequest(`Accounts/${id}/clientPermissions`, {
      accessToken
    }, 'delete').then(() => {
      sendApiRequest(`Accounts/${id}`, {
        accessToken
      }, 'delete').then((response) => {
        if (response.status === 'failure') {
          dispatch(deleteAccountByIdError(response.data))
        }
        if (response.status === 'success') {
          dispatch(deleteAccountByIdSuccess(response.data))
        }
      })
    })
  }
}

export const DELETE_ACCOUNT_BY_ID_SUCCESS = 'DELETE_ACCOUNT_BY_ID_SUCCESS'
export const deleteAccountByIdSuccess = createAction(DELETE_ACCOUNT_BY_ID_SUCCESS)

export const DELETE_ACCOUNT_BY_ID_ERROR = 'DELETE_ACCOUNT_BY_ID_ERROR'
export const deleteAccountByIdError = createAction(DELETE_ACCOUNT_BY_ID_ERROR)

export const DELETE_ACCOUNT_BY_ID_RESET = 'DELETE_ACCOUNT_BY_ID_RESET'
export const deleteAccountByIdReset = createAction(DELETE_ACCOUNT_BY_ID_RESET)

export const GET_ACCOUNT_PERMISSIONS = 'GET_ACCOUNT_PERMISSIONS'
export const getAccountPermissions = (id) => {
  return (dispatch, getState) => {
    dispatch(createAction(GET_ACCOUNT_PERMISSIONS)())
    sendApiRequest(`Accounts/${id}/Permissions/Network`, {
      accessToken: getState().auth.account.accessToken
    }, 'get').then((response) => {
      if (response.status === 'failure') {
        dispatch(getAccountPermissionsError(response))
      }
      if (response.status === 'success') {
        dispatch(getAccountPermissionsSuccess(response.data.data))
      }
    })
  }
}

export const GET_ACCOUNT_PERMISSIONS_SUCCESS = 'GET_ACCOUNT_PERMISSIONS_SUCCESS'
export const getAccountPermissionsSuccess = createAction(GET_ACCOUNT_PERMISSIONS_SUCCESS)

export const GET_ACCOUNT_PERMISSIONS_ERROR = 'GET_ACCOUT_PERMISSIONS_ERROR'
export const getAccountPermissionsError = createAction(GET_ACCOUNT_PERMISSIONS_ERROR)

export const GET_AUDITOR_CURRENT_LOCATIONS = 'GET_AUDITOR_CURRENT_LOCATIONS'
export const getAuditorCurrentLocations = () => {
  return (dispatch, getState) => {
    dispatch(createAction(GET_AUDITOR_CURRENT_LOCATIONS)())
    sendApiRequest('Accounts/locations', {
      accessToken: getState().auth.account.accessToken
    }, 'get').then((response) => {
      if (response.status === 'failure') {
        dispatch(getAuditorCurrentLocationsError(response))
      }
      if (response.status === 'success') {
        dispatch(getAuditorCurrentLocationsSuccess(response.data))
      }
    })
  }
}

export const GET_AUDITOR_CURRENT_LOCATIONS_SUCCESS = 'GET_AUDITOR_CURRENT_LOCATIONS_SUCCESS'
export const getAuditorCurrentLocationsSuccess = createAction(GET_AUDITOR_CURRENT_LOCATIONS_SUCCESS)

export const GET_AUDITOR_CURRENT_LOCATIONS_ERROR = 'GET_AUDITOR_CURRENT_LOCATIONS_ERROR'
export const getAuditorCurrentLocationsError = createAction(GET_AUDITOR_CURRENT_LOCATIONS_ERROR)

export const GET_AUDITOR_LOCATIONS_BY_ID_AND_DATE = 'GET_AUDITOR_LOCATIONS_BY_ID_AND_DATE'
export const getAuditorLocationsByIdAndDate = (data) => {
  return (dispatch, getState) => {
    data.accessToken = getState().auth.account.accessToken
    dispatch(createAction(GET_AUDITOR_LOCATIONS_BY_ID_AND_DATE)())
    sendApiRequest('Accounts/locations', data, 'post').then((response) => {
      if (response.status === 'failure') {
        dispatch(getAuditorLocationsByIdAndDateError(response))
      }
      if (response.status === 'success') {
        dispatch(getAuditorLocationsByIdAndDateSuccess(response.data))
      }
    })
  }
}

export const GET_AUDITOR_LOCATIONS_BY_ID_AND_DATE_SUCCESS = 'GET_AUDITOR_LOCATIONS_BY_ID_AND_DATE_SUCCESS'
export const getAuditorLocationsByIdAndDateSuccess = createAction(GET_AUDITOR_LOCATIONS_BY_ID_AND_DATE_SUCCESS)

export const GET_AUDITOR_LOCATIONS_BY_ID_AND_DATE_ERROR = 'GET_AUDITOR_LOCATIONS_BY_ID_AND_DATE_ERROR'
export const getAuditorLocationsByIdAndDateError = createAction(GET_AUDITOR_LOCATIONS_BY_ID_AND_DATE_ERROR)

export const SEND_MESSAGE_TO_AUDITOR = 'SEND_MESSAGE_TO_AUDITOR'
export const sendMessageToAuditor = (data) => {
  return (dispatch, getState) => {
    data.accessToken = getState().auth.account.accessToken
    dispatch(createAction(SEND_MESSAGE_TO_AUDITOR)())
    sendApiRequest(`AuditorLocations/message/${data.userId}`, data, 'post').then((response) => {
      if (response.status === 'failure') {
        dispatch(sendMessageToAuditorError(response))
      }
      if (response.status === 'success') {
        dispatch(sendMessageToAuditorSuccess(response.data))
      }
    })
  }
}

export const SEND_MESSAGE_TO_AUDITOR_SUCCESS = 'SEND_MESSAGE_TO_AUDITOR_SUCCESS'
export const sendMessageToAuditorSuccess = createAction(SEND_MESSAGE_TO_AUDITOR_SUCCESS)

export const SEND_MESSAGE_TO_AUDITOR_ERROR = 'SEND_MESSAGE_TO_AUDITOR_ERROR'
export const sendMessageToAuditorError = createAction(SEND_MESSAGE_TO_AUDITOR_ERROR)

const initialState = {
  isGettingAccounts: false,
  hasGottenAccounts: false,
  accounts: [],
  accountsError: {},
  hasCreatedAccount: false,
  isCreatingAccount: false,
  createAccountSuccess: {},
  createAccountError: {},
  accountDialogIsOpen: false,
  editAccountDialogIsOpen: false,
  selectedAccount: {},
  isEditingAccount: false,
  hasEditedAccount: false,
  editAccountSuccess: {},
  editAccountError: {},
  isDeletingAccount: false,
  hasDeletedAccount: false,
  deleteAccountError: {},
  deleteAccountSuccess: {},
  selectedAccountPermissions: [],
  isEditingAccountPermissions: false,
  hasEditedAccountPermissions: false,
  editAccountPermissionsSuccess: {},
  auditorCurrentLocations: [],
  isGettingAuditorCurrentLocations: false,
  hasGottenAuditorCurrentLocations: false,
  auditorLocations: [],
  isGettingAuditorLocations: false,
  hasGottenAuditorLocations: false,
  isSendingMessageToAuditor: false,
  hasSentMessageToAuditor: false,
  sendMessageToAuditorError: {}
}

export default handleActions({

  [GET_ACCOUNTS]: (state) => ({
    ...state,
    isGettingAccounts: true,
    hasGottenAccounts: true,
    accounts: []
  }),

  [GET_ACCOUNTS_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isGettingAccounts: false,
      hasGottenAccounts: true,
      accounts: payload
    }
  },

  [GET_ACCOUNTS_ERROR]: (state, { payload }) => {
    return {
      ...state,
      isGettingAccounts: false,
      hasGottenAccounts: true,
      accountsError: payload
    }
  },

  [OPEN_ACCOUNT_DIALOG]: (state, { payload }) => {
    return {
      ...state,
      accountDialogIsOpen: true,
      accountDialogRole: payload
    }
  },

  [CLOSE_ACCOUNT_DIALOG]: (state) => {
    return {
      ...state,
      accountDialogIsOpen: false,
      isCreatingAccount: false,
      hasCreatedAccount: false,
      createAccountSuccess: {},
      createAccountError: {}
    }
  },

  [RESET_ACCOUNT_FORM]: (state) => {
    return {
      ...state,
      isCreatingAccount: false,
      hasCreatedAccount: false,
      createAccountSuccess: {},
      createAccountError: {}
    }
  },

  [CREATE_NEW_ACCOUNT]: (state) => {
    return {
      ...state,
      isCreatingAccount: true,
      hasCreatedAccount: false,
      createAccountSuccess: {},
      createAccountError: {}
    }
  },

  [CREATE_NEW_ACCOUNT_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isCreatingAccount: false,
      hasCreatedAccount: true,
      createAccountSuccess: payload
    }
  },

  [CREATE_NEW_ACCOUNT_ERROR]: (state, { payload }) => {
    return {
      ...state,
      isCreatingAccount: false,
      hasCreatedAccount: false,
      createAccountError: payload
    }
  },

  [OPEN_EDIT_ACCOUNT_DIALOG]: (state) => {
    return {
      ...state,
      editAccountDialogIsOpen: true
    }
  },

  [CLOSE_EDIT_ACCOUNT_DIALOG]: (state) => {
    return {
      ...state,
      editAccountDialogIsOpen: false,
      selectedAccount: {},
      isEditingAccount: false,
      hasEditedAccount: false,
      editAccountSuccess: {},
      editAccountError: {}
    }
  },

  [GET_ACCOUNT_BY_ID_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      selectedAccount: payload
    }
  },

  [EDIT_ACCOUNT]: (state) => {
    return {
      ...state,
      isEditingAccount: true,
      hasEditedAccount: false,
      editAccountSuccess: {},
      editAccountError: {}
    }
  },

  [EDIT_ACCOUNT_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isEditingAccount: true,
      hasEditedAccount: true,
      editAccountSuccess: payload,
      editAccountError: {}
    }
  },

  [EDIT_ACCOUNT_ERROR]: (state, { payload }) => {
    return {
      ...state,
      isEditingAccount: true,
      hasEditedAccount: false,
      editAccountSuccess: {},
      editAccountError: payload
    }
  },

  [RESET_EDIT_ACCOUNT_FORM]: (state) => {
    return {
      ...state,
      selectedAccount: {},
      isEditingAccount: false,
      hasEditedAccount: false,
      editAccountSuccess: {},
      editAccountError: {}
    }
  },

  [DELETE_ACCOUNT_BY_ID]: (state) => ({
    ...state,
    isDeletingAccount: true,
    hasDeletedAccount: false
  }),

  [DELETE_ACCOUNT_BY_ID_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isDeletingAccount: false,
      hasDeletedAccount: true,
      deleteAccountSuccess: payload
    }
  },

  [DELETE_ACCOUNT_BY_ID_ERROR]: (state, { payload }) => {
    return {
      ...state,
      isDeletingAccount: false,
      hasDeletedAccount: false,
      deleteAccountError: payload
    }
  },

  [DELETE_ACCOUNT_BY_ID_RESET]: (state) => {
    return {
      ...state,
      isDeletingAccount: false,
      hasDeletedAccount: false,
      deleteAccountError: {},
      deleteAccountSuccess: {}
    }
  },

  [GET_ACCOUNT_PERMISSIONS_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      selectedAccountPermissions: payload
    }
  },

  [EDIT_ACCOUNT_PERMISSIONS]: (state) => {
    return {
      ...state,
      isEditingAccount: true,
      hasEditedAccount: false,
      editAccountSuccess: {}
    }
  },

  [EDIT_ACCOUNT_PERMISSIONS_SUCCESS]: (state) => {
    return {
      ...state,
      isEditingAccount: false,
      hasEditedAccount: true,
      editAccountsSuccess: { success: true }
    }
  },

  [GET_AUDITOR_CURRENT_LOCATIONS]: (state) => {
    return {
      ...state,
      auditorCurrentLocations: [],
      isGettingAuditorCurrentLocations: true,
      hasGottenAuditorCurrentLocations: false
    }
  },

  [GET_AUDITOR_CURRENT_LOCATIONS_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      auditorCurrentLocations: payload,
      isGettingAuditorCurrentLocations: false,
      hasGottenAuditorCurrentLocations: true
    }
  },

  [GET_AUDITOR_CURRENT_LOCATIONS_ERROR]: (state) => {
    return {
      ...state,
      auditorCurrentLocations: [],
      isGettingAuditorCurrentLocations: false,
      hasGottenAuditorCurrentLocations: false
    }
  },

  [GET_AUDITOR_LOCATIONS_BY_ID_AND_DATE]: (state) => {
    return {
      ...state,
      auditorLocations: [],
      isGettingAuditorLocations: true,
      hasGottenAuditorLocations: false
    }
  },

  [GET_AUDITOR_LOCATIONS_BY_ID_AND_DATE_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      auditorLocations: payload,
      isGettingAuditorLocations: false,
      hasGottenAuditorLocations: true
    }
  },

  [GET_AUDITOR_LOCATIONS_BY_ID_AND_DATE_ERROR]: (state) => {
    return {
      ...state,
      auditorLocations: [],
      isGettingAuditorLocations: false,
      hasGottenAuditorLocations: false
    }
  },

  [SEND_MESSAGE_TO_AUDITOR]: (state) => {
    return {
      ...state,
      isSendingMessageToAuditor: true,
      hasSentMessageToAuditor: false,
      sendMessageToAuditorError: {}
    }
  },

  [SEND_MESSAGE_TO_AUDITOR_SUCCESS]: (state) => {
    return {
      ...state,
      isSendingMessageToAuditor: false,
      hasSentMessageToAuditor: true,
      sendMessageToAuditorError: {}
    }
  },

  [SEND_MESSAGE_TO_AUDITOR_ERROR]: (state, { payload }) => {
    return {
      ...state,
      isSendingMessageToAuditor: false,
      hasSentMessageToAuditor: false,
      sendMessageToAuditorError: payload
    }
  }

}, initialState)
