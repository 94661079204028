import { createAction, handleActions } from 'redux-actions'
// import { sendApiRequest } from 'utils/api'

export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR'
export const toggleSidebar = () => {
  return (dispatch, getState) => {
    let newState = true
    const currentState = getState().site.sidebarIsOpen
    if (currentState === true) {
      newState = false
    } else {
      newState = true
    }
    dispatch(createAction(TOGGLE_SIDEBAR)(newState))
  }
}

export const OPEN_SIDEBAR = 'OPEN_SIDEBAR'
export const openSidebar = createAction(OPEN_SIDEBAR)

export const CLOSE_SIDEBAR = 'CLOSE_SIDEBAR'
export const closeSidebar = createAction(CLOSE_SIDEBAR)

export const OPEN_PERMISSION_DENIED_DIALOG = 'OPEN_PERMISSION_DENIED_DIALOG'
export const openPermissionDeniedDialog = createAction(OPEN_PERMISSION_DENIED_DIALOG)

export const CLOSE_PERMISSION_DENIED_DIALOG = 'CLOSE_PERMISSION_DENIED_DIALOG'
export const closePermissionDeniedDialog = createAction(CLOSE_PERMISSION_DENIED_DIALOG)

export const OPEN_SUCCESS_DIALOG = 'OPEN_SUCCESS_DIALOG'
export const openSuccessDialog = (successDialogData) => {
  return (dispatch) => {
    dispatch(createAction(OPEN_SUCCESS_DIALOG)(successDialogData))
  }
}

export const CLOSE_SUCCESS_DIALOG = 'CLOSE_SUCCESS_DIALOG'
export const closeSuccessDialog = createAction(CLOSE_SUCCESS_DIALOG)

export const OPEN_LIGHTBOX = 'OPEN_LIGHTBOX'
export const openLightbox = (data) => {
  return (dispatch) => {
    dispatch(createAction(OPEN_LIGHTBOX)(data))
  }
}

export const UPDATE_LIGHTBOX_INDEX = 'UPDATE_LIGHTBOX_INDEX'
export const updateLightboxIndex = (index) => {
  return (dispatch) => {
    dispatch(createAction(UPDATE_LIGHTBOX_INDEX)(index))
  }
}

export const CLOSE_LIGHTBOX = 'CLOSE_LIGHTBOX'
export const closeLightbox = createAction(CLOSE_LIGHTBOX)

export const OPEN_CONFIRM_DIALOG = 'OPEN_CONFIRM_DIALOG'
export const openConfirmDialog = (data) => {
  return (dispatch) => {
    dispatch(createAction(OPEN_CONFIRM_DIALOG)(data))
  }
}

export const CLOSE_CONFIRM_DIALOG = 'CLOSE_CONFIRM_DIALOG'
export const closeConfirmDialog = createAction(CLOSE_CONFIRM_DIALOG)

export const OPEN_SEND_MESSAGE_DIALOG = 'OPEN_SEND_MESSAGE_DIALOG'
export const openSendMessageDialog = (data) => {
  return (dispatch) => {
    dispatch(createAction(OPEN_SEND_MESSAGE_DIALOG)(data))
  }
}

export const CLOSE_SEND_MESSAGE_DIALOG = 'CLOSE_SEND_MESSAGE_DIALOG'
export const closeSendMessageDialog = createAction(CLOSE_SEND_MESSAGE_DIALOG)

export const OPEN_MAP_DIALOG = 'OPEN_MAP_DIALOG'
export const openMapDialog = (data) => {
  return (dispatch) => {
    dispatch(createAction(OPEN_MAP_DIALOG)(data))
  }
}

export const CLOSE_MAP_DIALOG = 'CLOSE_MAP_DIALOG'
export const closeMapDialog = createAction(CLOSE_MAP_DIALOG)

const initialState = {
  sidebarIsOpen: false,
  permissionDeniedDialogIsOpen: false,
  successDialogIsOpen: false,
  successMessage: '',
  successRedirect: '',
  successInitialUrl: '',
  lightboxIsOpen: false,
  lightboxImages: [],
  currentLightboxImage: 0,
  confirmDialogIsOpen: false,
  confirmDialogMessage: '',
  confirmCallback: () => {},
  confirmCancelCallback: () => {},
  confirmDialogData: {},
  sendMessageDialogIsOpen: false,
  sendMessageAccount: {},
  mapDialogIsOpen: false,
  mapDialogMarkers: []
}

export default handleActions({

  [TOGGLE_SIDEBAR]: (state, { payload }) => ({
    ...state,
    sidebarIsOpen: payload
  }),

  [OPEN_SIDEBAR]: (state) => ({
    ...state,
    sidebarIsOpen: true
  }),

  [CLOSE_SIDEBAR]: (state) => ({
    ...state,
    sidebarIsOpen: false
  }),

  [OPEN_PERMISSION_DENIED_DIALOG]: (state) => ({
    ...state,
    permissionDeniedDialogIsOpen: true
  }),

  [CLOSE_PERMISSION_DENIED_DIALOG]: (state) => ({
    ...state,
    permissionDeniedDialogIsOpen: false
  }),

  [OPEN_SUCCESS_DIALOG]: (state, { payload }) => ({
    ...state,
    successDialogIsOpen: true,
    successMessage: payload.successMessage,
    successRedirect: payload.successRedirect,
    successInitialUrl: payload.successInitialUrl
  }),

  [CLOSE_SUCCESS_DIALOG]: (state) => ({
    ...state,
    successDialogIsOpen: false,
    successMessage: '',
    successRedirect: '',
    successInitialUrl: ''
  }),

  [OPEN_LIGHTBOX]: (state, { payload }) => ({
    ...state,
    lightboxIsOpen: true,
    lightboxImages: payload,
    currentLightboxImage: 0
  }),

  [UPDATE_LIGHTBOX_INDEX]: (state, { payload }) => ({
    ...state,
    currentLightboxImage: payload
  }),

  [CLOSE_LIGHTBOX]: (state) => ({
    ...state,
    lightboxIsOpen: false,
    lightboxImages: []
  }),

  [OPEN_CONFIRM_DIALOG]: (state, { payload }) => ({
    ...state,
    confirmDialogIsOpen: true,
    confirmDialogMessage: payload.message,
    confirmCallback: payload.confirmCallback,
    confirmCancelCallback: payload.cancelCallback,
    confirmDialogData: payload.data
  }),

  [CLOSE_CONFIRM_DIALOG]: (state) => ({
    ...state,
    confirmDialogIsOpen: false,
    confirmDialogMessage: '',
    confirmCallback: () => {},
    confirmCancelCallback: () => {},
    confirmDialogData: {}
  }),

  [OPEN_SEND_MESSAGE_DIALOG]: (state, { payload }) => ({
    ...state,
    sendMessageDialogIsOpen: true,
    sendMessageAccount: payload
  }),

  [CLOSE_SEND_MESSAGE_DIALOG]: (state) => ({
    ...state,
    sendMessageDialogIsOpen: false,
    sendMessageAccount: {}
  }),

  [OPEN_MAP_DIALOG]: (state, { payload }) => ({
    ...state,
    mapDialogIsOpen: true,
    mapDialogMarkers: payload.markers
  }),

  [CLOSE_MAP_DIALOG]: (state) => ({
    ...state,
    mapDialogIsOpen: false,
    mapDialogMarkers: []
  }),

}, initialState)
