import React from 'react'
import { browserHistory, Router } from 'react-router'
import PropTypes from 'prop-types'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import enLocale from 'date-fns/locale/en-AU'
import 'react-image-lightbox/style.css'
import 'styles/main.scss'

const newTheme = createTheme({
  palette: {
    primary: {
      main: 'rgb(0, 188, 212)',
      contrastText: 'white'
    },
    secondary: {
      main: 'rgb(255, 64, 129)'
    }
  },
  components: {
    MuiTextField: {
      defaultProps: {
        variant: 'standard'
      }
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained'
      }
    }
  }
})

class App extends React.Component {
  static propTypes = {
    // store: PropTypes.object.isRequired,
    routes: PropTypes.object.isRequired,
  }

  shouldComponentUpdate () {
    return false
  }

  render () {
    return (
      <ThemeProvider theme={newTheme}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enLocale}>
          <div style={{ height: '100%' }}>
            <Router history={browserHistory}>
              {this.props.routes}
            </Router>
          </div>
        </LocalizationProvider>
      </ThemeProvider>
    )
  }
}

export default App
