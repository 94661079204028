import { createAction, handleActions } from 'redux-actions'
import { sendApiRequest } from 'utils/api'
import { getClientId } from 'utils/utils'
import { sessionService } from 'redux-react-session'
import { getCurrentClientInfo } from './auth'
import config from 'config'

export const GET_NETWORKS = 'GET_NETWORKS'
export const getNetworks = () => {
  return (dispatch, getState) => {
    dispatch(createAction(GET_NETWORKS)())
    const options = {
      filter: {
        order: 'clientAdded desc',
        where: {
          active: {
            neq: false
          },
        }
      },
    }
    sendApiRequest('Clients/find', {
      accessToken: getState().auth.account.accessToken
    }, 'get', options).then((response) => {
      if (response.status === 'failure') {
        dispatch(getNetworksError(response))
      }
      if (response.status === 'success') {
        dispatch(getNetworksSuccess(response.data))
      }
    })
  }
}

export const GET_NETWORKS_SUCCESS = 'GET_NETWORKS_SUCCESS'
export const getNetworksSuccess = createAction(GET_NETWORKS_SUCCESS)

export const GET_NETWORKS_ERROR = 'GET_NETWORKS_ERROR'
export const getNetworksError = createAction(GET_NETWORKS_ERROR)

export const GET_NETWORK_BY_ID = 'GET_NETWORK_BY_ID'
export const getNetworkById = (id) => {
  return (dispatch, getState) => {
    dispatch(createAction(GET_NETWORK_BY_ID)())
    sendApiRequest('Clients/' + id, {
      accessToken: getState().auth.account.accessToken
    }, 'get').then((response) => {
      if (response.status === 'success') {
        dispatch(getNetworkByIdSuccess(response.data))
      }
    })
  }
}

export const GET_NETWORK_BY_ID_SUCCESS = 'GET_NETWORK_BY_ID_SUCCESS'
export const getNetworkByIdSuccess = createAction(GET_NETWORK_BY_ID_SUCCESS)

export const ADMIN_SELECT_NETWORK = 'ADMIN_SELECT_NETWORK'
export const adminSelectNetwork = (networkId) => {
  return (dispatch) => {
    // check for admin role from the server here - put it in api utils
    dispatch(createAction(ADMIN_SELECT_NETWORK)(networkId))
    if (config.client !== 'seaborne') {
      dispatch(getNetworkApiKey(networkId))
    }
    dispatch(getCurrentClientInfo(networkId))
    sessionService.loadSession()
      .then(currentSession => {
        currentSession.adminClientId = networkId
        sessionService.saveSession(currentSession)
      })
  }
}

export const GET_NETWORK_API_KEY = 'GET_NETWORK_API_KEY'
export const getNetworkApiKey = () => {
  return (dispatch, getState) => {
    const clientId = getClientId(getState)
    if (clientId !== 0 && getState().auth.account.accessToken) {
      sendApiRequest(`Clients/${clientId}/network`, {
        accessToken: getState().auth.account.accessToken
      }, 'get').then((response) => {
        if (response.status === 'failure') {
          dispatch(getNetworkApiKeyError())
        }
        if (response.status === 'success') {
          dispatch(getNetworkApiKeySuccess(response.data.id))
        }
      })
    }
  }
}

export const GET_NETWORK_API_KEY_SUCCESS = 'GET_NETWORK_API_KEY_SUCCESS'
export const getNetworkApiKeySuccess = createAction(GET_NETWORK_API_KEY_SUCCESS)

export const GET_NETWORK_API_KEY_ERROR = 'GET_NETWORK_API_KEY_ERROR'
export const getNetworkApiKeyError = createAction(GET_NETWORK_API_KEY_ERROR)

export const GET_NCE_NETWORK_BY_ID = 'GET_NCE_NETWORK_BY_ID'
export const getNceNetworkById = (id) => {
  return (dispatch, getState) => {
    const options = {
      filter: {
        where: {
          apiKey: id
        }
      }
    }
    dispatch(createAction(GET_NCE_NETWORK_BY_ID)())
    sendApiRequest('Networks', {
      accessToken: getState().auth.account.accessToken
    }, 'get', options).then((response) => {
      if (response.status === 'failure') {
        dispatch(getNceNetworkByIdError(response.data))
      }
      if (response.status === 'success') {
        dispatch(getNceNetworkByIdSuccess(response.data[0]))
      }
    })
  }
}

export const GET_NCE_NETWORK_BY_ID_SUCCESS = 'GET_NCE_NETWORK_BY_ID_SUCCESS'
export const getNceNetworkByIdSuccess = createAction(GET_NCE_NETWORK_BY_ID_SUCCESS)

export const GET_NCE_NETWORK_BY_ID_ERROR = 'GET_NCE_NETWORK_BY_ID_ERROR'
export const getNceNetworkByIdError = createAction(GET_NCE_NETWORK_BY_ID_ERROR)

export const DELETE_NETWORK_BY_ID = 'DELETE_NETWORK_BY_ID'
export const deleteNetworkById = (id) => {
  return (dispatch, getState) => {
    dispatch(createAction(DELETE_NETWORK_BY_ID)())
    sendApiRequest(`Clients/${id}`, {
      accessToken: getState().auth.account.accessToken
    }, 'delete').then((response) => {
      if (response.status === 'failure') {
        dispatch(deleteNetworkByIdError(response.data))
      }
      if (response.status === 'success') {
        dispatch(deleteNetworkByIdSuccess(response.data))
        dispatch(getNetworks())
      }
    })
  }
}

export const DELETE_NETWORK_BY_ID_SUCCESS = 'DELETE_NETWORK_BY_ID_SUCCESS'
export const deleteNetworkByIdSuccess = createAction(DELETE_NETWORK_BY_ID_SUCCESS)

export const DELETE_NETWORK_BY_ID_ERROR = 'DELETE_NETWORK_BY_ID_ERROR'
export const deleteNetworkByIdError = createAction(DELETE_NETWORK_BY_ID_ERROR)

export const DELETE_NETWORK_BY_ID_RESET = 'DELETE_NETWORK_BY_ID_RESET'
export const deleteNetworkByIdReset = createAction(DELETE_NETWORK_BY_ID_RESET)

export const OPEN_NETWORK_DIALOG = 'OPEN_NETWORK_DIALOG'
export const openNetworkDialog = createAction(OPEN_NETWORK_DIALOG)

export const CLOSE_NETWORK_DIALOG = 'CLOSE_NETWORK_DIALOG'
export const closeNetworkDialog = createAction(CLOSE_NETWORK_DIALOG)

export const OPEN_EDIT_NETWORK_DIALOG = 'OPEN_EDIT_NETWORK_DIALOG'
export const openEditNetworkDialog = createAction(OPEN_EDIT_NETWORK_DIALOG)

export const CLOSE_EDIT_NETWORK_DIALOG = 'CLOSE_EDIT_NETWORK_DIALOG'
export const closeEditNetworkDialog = createAction(CLOSE_EDIT_NETWORK_DIALOG)

export const CREATE_NEW_NETWORK = 'CREATE_NEW_NETWORK'
export const createNewNetwork = (data) => {
  return (dispatch, getState) => {
    dispatch(createAction(CREATE_NEW_NETWORK)())
    const networkData = data
    networkData.accessToken = getState().auth.account.accessToken
    sendApiRequest('Clients', networkData, 'post').then((response) => {
      if (response.status === 'failure') {
        dispatch(createNewNetworkError(response))
      }
      if (response.status === 'success') {
        dispatch(createNewNetworkSuccess(response.data))
        dispatch(getNetworks())
      }
    })
  }
}

export const CREATE_NEW_NETWORK_SUCCESS = 'CREATE_NEW_NETWORK_SUCCESS'
export const createNewNetworkSuccess = createAction(CREATE_NEW_NETWORK_SUCCESS)

export const CREATE_NEW_NETWORK_ERROR = 'CREATE_NEW_NETWORK_ERROR'
export const createNewNetworkError = createAction(CREATE_NEW_NETWORK_ERROR)

export const RESET_NETWORK_FORM = 'RESET_NETWORK_FORM'
export const resetNetworkForm = createAction(RESET_NETWORK_FORM)

export const GET_ALL_PERMISSIONS = 'GET_ALL_PERMISSIONS'
export const getAllPermissions = (type) => {
  const options = {
    filter: {}
  }
  if (type === 'client') {
    options.filter.where = { permType: 'Client' }
  }
  if (type === 'user') {
    options.filter.where = { permType: 'User' }
  }
  return (dispatch, getState) => {
    dispatch(createAction(GET_ALL_PERMISSIONS)())
    sendApiRequest('Permissions', {
      accessToken: getState().auth.account.accessToken
    }, 'get', options).then((response) => {
      if (response.status === 'failure') {
        dispatch(getAllPermissionsError(response))
      }
      if (response.status === 'success') {
        dispatch(getAllPermissionsSuccess(response.data))
      }
    })
  }
}

export const GET_ALL_PERMISSIONS_SUCCESS = 'GET_ALL_PERMISSIONS_SUCCESS'
export const getAllPermissionsSuccess = createAction(GET_ALL_PERMISSIONS_SUCCESS)

export const GET_ALL_PERMISSIONS_ERROR = 'GET_ALL_PERMISSIONS_ERROR'
export const getAllPermissionsError = createAction(GET_ALL_PERMISSIONS_ERROR)

export const GET_NETWORK_PERMISSIONS = 'GET_NETWORK_PERMISSIONS'
export const getNetworkPermissions = (id) => {
  return (dispatch, getState) => {
    if (!id) {
      id = getClientId(getState)
    }
    dispatch(createAction(GET_NETWORK_PERMISSIONS)())
    sendApiRequest(`Clients/${id}/permissions`, {
      accessToken: getState().auth.account.accessToken
    }, 'get').then((response) => {
      if (response.status === 'failure') {
        dispatch(getNetworkPermissionsError(response))
      }
      if (response.status === 'success') {
        dispatch(getNetworkPermissionsSuccess(response.data))
      }
    })
  }
}

export const GET_NETWORK_PERMISSIONS_SUCCESS = 'GET_NETWORK_PERMISSIONS_SUCCESS'
export const getNetworkPermissionsSuccess = createAction(GET_NETWORK_PERMISSIONS_SUCCESS)

export const GET_NETWORK_PERMISSIONS_ERROR = 'GET_NETWORK_PERMISSIONS_ERROR'
export const getNetworkPermissionsError = createAction(GET_NETWORK_PERMISSIONS_ERROR)

export const EDIT_NETWORK_PERMISSIONS = 'EDIT_NETWORK_PERMISSIONS'
export const editNetworkPermissions = (data) => {
  return (dispatch, getState) => {
    const accessToken = getState().auth.account.accessToken
    dispatch(createAction(EDIT_NETWORK_PERMISSIONS)())
    sendApiRequest(`Clients/${data.clientId}/clientPermissions`, {
      accessToken
    }, 'delete').then((response) => {
      if (response.status === 'success') {
        data.permIds.forEach((id) => {
          sendApiRequest(`Clients/${data.clientId}/clientPermissions`, {
            permId: id,
            accessToken
          }, 'post')
        })
        dispatch(editNetworkPermissionsSuccess())
      }
    })
  }
}

export const EDIT_NETWORK_PERMISSIONS_SUCCESS = 'EDIT_NETWORK_PERMISSIONS_SUCCESS'
export const editNetworkPermissionsSuccess = createAction(EDIT_NETWORK_PERMISSIONS_SUCCESS)

export const EDIT_NETWORK_PERMISSIONS_ERROR = 'EDIT_NETWORK_PERMISSIONS_ERROR'
export const editNetworkPermissionsError = createAction(EDIT_NETWORK_PERMISSIONS_ERROR)

export const EDIT_NETWORK_BY_ID = 'EDIT_NETWORK_BY_ID'
export const editNetworkById = (data) => {
  return (dispatch, getState) => {
    const accessToken = getState().auth.account.accessToken
    data.accessToken = accessToken
    dispatch(createAction(EDIT_NETWORK_BY_ID)())
    sendApiRequest(`Clients/${data.clientId}`, data, 'patch').then((response) => {
      if (response.status === 'success') {
        dispatch(editNetworkByIdSuccess())
      }
    })
  }
}

export const EDIT_NETWORK_BY_ID_SUCCESS = 'EDIT_NETWORK_BY_ID_SUCCESS'
export const editNetworkByIdSuccess = createAction(EDIT_NETWORK_BY_ID_SUCCESS)

const initialState = {
  networks: [],
  isGettingNetworks: false,
  hasGottenNetworks: false,
  getNetworksError: {},
  adminSelectedNetwork: 0,
  isDeletingNetwork: false,
  hasDeletedNetwork: false,
  deleteNetworkError: {},
  deleteNetworkSuccess: {},
  allPermissions: [],
  selectedNetworkPermissions: [],
  networkDialogIsOpen: false,
  editNetworkDialogIsOpen: false,
  selectedNetworkToEdit: {},
  isEditingNetwork: false,
  editNetworkSuccess: {},
  selectedNetworkApiKey: 0,
  isGettingNceNetwork: false,
  hasGottenNceNetwork: false,
  selectedNceNetwork: {}
}

export default handleActions({

  [GET_NETWORKS]: (state) => ({
    ...state,
    isGettingNetworks: true,
    hasGottenNetworks: false
  }),

  [GET_NETWORKS_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isGettingNetworks: false,
      hasGottenNetworks: true,
      networks: payload
    }
  },

  [GET_NETWORKS_ERROR]: (state, { payload }) => {
    return {
      ...state,
      isGettingNetworks: true,
      hasGottenNetworks: false,
      getNetworksError: payload
    }
  },

  [ADMIN_SELECT_NETWORK]: (state, { payload }) => {
    return {
      ...state,
      adminSelectedNetwork: payload
    }
  },

  [GET_NETWORK_API_KEY_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      selectedNetworkApiKey: payload
    }
  },

  [GET_NETWORK_API_KEY_ERROR]: (state) => {
    return {
      ...state,
      selectedNetworkApiKey: 0
    }
  },

  [OPEN_NETWORK_DIALOG]: (state, { payload }) => {
    return {
      ...state,
      networkDialogIsOpen: true,
      networkDialogRole: payload
    }
  },

  [CLOSE_NETWORK_DIALOG]: (state) => {
    return {
      ...state,
      networkDialogIsOpen: false,
      isCreatingNetwork: false,
      hasCreatedNetwork: false,
      createNetworkSuccess: {},
      createNetworkError: {}
    }
  },

  [OPEN_EDIT_NETWORK_DIALOG]: (state, { payload }) => {
    return {
      ...state,
      editNetworkDialogIsOpen: true,
      selectedNetworkToEdit: payload
    }
  },

  [CLOSE_EDIT_NETWORK_DIALOG]: (state) => {
    return {
      ...state,
      editNetworkDialogIsOpen: false,
      selectedNetworkToEdit: {},
      isEditingNetwork: true,
      hasEditedNetwork: false,
      editNetworkSuccess: {},
      selectedNetworkPermissions: []
    }
  },

  [GET_NCE_NETWORK_BY_ID]: (state) => ({
    ...state,
    isGettingNceNetwork: true,
    hasGottenNceNetwork: false
  }),

  [GET_NCE_NETWORK_BY_ID_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isGettingNceNetwork: true,
      hasGottenNceNetwork: false,
      selectedNceNetwork: payload
    }
  },

  [DELETE_NETWORK_BY_ID]: (state) => ({
    ...state,
    isDeletingNetwork: true,
    hasDeletedNetwork: false
  }),

  [DELETE_NETWORK_BY_ID_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isDeletingNetwork: false,
      hasDeletedNetwork: true,
      deleteNetworkSuccess: payload
    }
  },

  [DELETE_NETWORK_BY_ID_ERROR]: (state, { payload }) => {
    return {
      ...state,
      isDeletingNetwork: false,
      hasDeletedNetwork: false,
      deleteNetworkError: payload
    }
  },

  [DELETE_NETWORK_BY_ID_RESET]: (state) => {
    return {
      ...state,
      isDeletingNetwork: false,
      hasDeletedNetwork: false,
      deleteNetworkError: {},
      deleteNetworkSuccess: {}
    }
  },

  [OPEN_NETWORK_DIALOG]: (state, { payload }) => {
    return {
      ...state,
      networkDialogIsOpen: true,
      networkDialogRole: payload
    }
  },

  [CLOSE_NETWORK_DIALOG]: (state) => {
    return {
      ...state,
      networkDialogIsOpen: false,
      isCreatingNetwork: false,
      hasCreatedNetwork: false,
      createNetworkSuccess: {},
      createNetworkError: {}
    }
  },

  [RESET_NETWORK_FORM]: (state) => {
    return {
      ...state,
      isCreatingNetwork: false,
      hasCreatedNetwork: false,
      createNetworkSuccess: {},
      createNetworkError: {}
    }
  },

  [CREATE_NEW_NETWORK]: (state) => {
    return {
      ...state,
      isCreatingNetwork: true,
      hasCreatedNetwork: false,
      createNetworkSuccess: {},
      createNetworkError: {}
    }
  },

  [CREATE_NEW_NETWORK_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isCreatingNetwork: false,
      hasCreatedNetwork: true,
      createNetworkSuccess: payload
    }
  },

  [CREATE_NEW_NETWORK_ERROR]: (state, { payload }) => {
    return {
      ...state,
      isCreatingNetwork: false,
      hasCreatedNetwork: false,
      createNetworkError: payload
    }
  },

  [GET_ALL_PERMISSIONS_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      allPermissions: payload
    }
  },

  [GET_NETWORK_PERMISSIONS_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      selectedNetworkPermissions: payload
    }
  },

  [EDIT_NETWORK_PERMISSIONS]: (state) => {
    return {
      ...state,
      isEditingNetwork: true,
      hasEditedNetwork: false,
      editNetworkSuccess: {}
    }
  },

  [EDIT_NETWORK_PERMISSIONS_SUCCESS]: (state) => {
    return {
      ...state,
      isEditingNetwork: false,
      hasEditedNetwork: true,
      editNetworkSuccess: { success: true }
    }
  }

}, initialState)
