import { createAction, handleActions } from 'redux-actions'
import { sendApiRequest } from 'utils/api'
import { getClientId } from 'utils/utils'

export const GET_CWORK_COUNT = 'GET_CWORK_COUNT'
export const getCWorkCount = (type, options = {}, search) => {
  return (dispatch, getState) => {
    dispatch(createAction(GET_CWORK_COUNT)())
    const andArray = []
    options.where = {
      and: []
    }
    const clientId = getClientId(getState)
    andArray.push({ clientId })
    if (search) {
      andArray.push(search)
    }
    switch (type) {
      case 'new': {
        andArray.push({
          workStatusId: {
            inq: [1, 5, 8, 9]
          }
        })
        break
      }
      case 'scheduled': {
        andArray.push({
          workStatusId: {
            inq: [3, 7, 13]
          }
        })
        break
      }
      case 'completed': {
        andArray.push({
          workStatusId: 10
        })
        break
      }
      case 'closed': {
        andArray.push({
          workStatusId: 11
        })
        break
      }
      default: {
        andArray.push({
          workStatusId: 10
        })
      }
    }
    options.where.and = andArray
    sendApiRequest('ContractorWorks/count', {
      accessToken: getState().auth.account.accessToken
    }, 'get', options).then((response) => {
      if (response.status === 'failure') {
        dispatch(getCWorkCountError(response))
      }
      if (response.status === 'success') {
        dispatch(getCWorkCountSuccess(response.data.count))
      }
    })
  }
}

export const GET_CWORK_COUNT_SUCCESS = 'GET_CWORK_COUNT_SUCCESS'
export const getCWorkCountSuccess = createAction(GET_CWORK_COUNT_SUCCESS)

export const GET_CWORK_COUNT_ERROR = 'GET_CWORK_COUNT_ERROR'
export const getCWorkCountError = createAction(GET_CWORK_COUNT_ERROR)

export const GET_CWORKS = 'GET_CWORKS'
export const getCWorks = (pageSize, pageNumber, type, search, filter) => {
  return (dispatch, getState) => {
    dispatch(createAction(GET_CWORKS)())
    let options = {
      filter: {
        order: 'contractorWorkId DESC',
        limit: pageSize + 1,
      }
    }
    if (filter) { options = Object.assign(options, filter) }
    if (pageNumber !== 1) {
      options.filter.skip = (pageNumber - 1) * pageSize
    }
    if (search) {
      options.filter.search = search
    }
    const clientId = getClientId(getState)
    sendApiRequest(`ContractorWorks/type/${clientId}/${type}`, {
      accessToken: getState().auth.account.accessToken
    }, 'get', options).then((response) => {
      if (response.status === 'failure') {
        dispatch(getCWorksError(response))
      }
      if (response.status === 'success') {
        dispatch(getCWorksSuccess(response.data))
      }
    })
  }
}

export const GET_CWORKS_SUCCESS = 'GET_CWORKS_SUCCESS'
export const getCWorksSuccess = createAction(GET_CWORKS_SUCCESS)

export const GET_CWORKS_ERROR = 'GET_CWORKS_ERROR'
export const getCWorksError = createAction(GET_CWORKS_ERROR)

export const GET_CWORKS_IMAGES = 'GET_CWORKS_IMAGES'
export const getCWorksImages = (id) => {
  return (dispatch, getState) => {
    dispatch(createAction(GET_CWORKS_IMAGES)())
    sendApiRequest(`ContractorActivities/${id}/photos`, {
      accessToken: getState().auth.account.accessToken
    }, 'get').then((response) => {
      if (response.status === 'failure') {
        dispatch(getCWorksImagesError(response))
      }
      if (response.status === 'success') {
        dispatch(getCWorksImagesSuccess(response.data))
      }
    })
  }
}

export const GET_CWORKS_IMAGES_SUCCESS = 'GET_CWORKS_IMAGES_SUCCESS'
export const getCWorksImagesSuccess = createAction(GET_CWORKS_IMAGES_SUCCESS)

export const GET_CWORKS_IMAGES_ERROR = 'GET_CWORKS_IMAGES_ERROR'
export const getCWorksImagesError = createAction(GET_CWORKS_IMAGES_ERROR)

export const GET_CWORK_BY_ID = 'GET_CWORK_BY_ID'
export const getCWorkById = (id) => {
  return (dispatch, getState) => {
    dispatch(createAction(GET_CWORK_BY_ID)())
    sendApiRequest(`ContractorWorks/${id}`, {
      accessToken: getState().auth.account.accessToken
    }, 'get').then((response) => {
      if (response.status === 'failure') {
        dispatch(getCWorkByIdError(response))
      }
      if (response.status === 'success') {
        dispatch(getCWorkByIdSuccess(response.data))
      }
    })
  }
}

export const GET_CWORK_BY_ID_SUCCESS = 'GET_CWORK_BY_ID_SUCCESS'
export const getCWorkByIdSuccess = createAction(GET_CWORK_BY_ID_SUCCESS)

export const GET_CWORK_BY_ID_ERROR = 'GET_CWORK_BY_ID_ERROR'
export const getCWorkByIdError = createAction(GET_CWORK_BY_ID_ERROR)

export const SET_CWORK_STATUS_BY_ID = 'SET_CWORK_STATUS_BY_ID'
export const setCWorkStatusById = (id, status) => {
  return (dispatch, getState) => {
    dispatch(createAction(SET_CWORK_STATUS_BY_ID)())
    sendApiRequest(`ContractorWorks/${id}`, {
      accessToken: getState().auth.account.accessToken,
      workStatusId: status
    }, 'patch').then((response) => {
      if (response.status === 'failure') {
        dispatch(setWorkStatusByIdError(response))
      }
      if (response.status === 'success') {
        dispatch(setCWorkStatusByIdSuccess(response.data))
      }
    })
  }
}

export const SET_CWORK_STATUS_BY_ID_SUCCESS = 'SET_CWORK_STATUS_BY_ID_SUCCESS'
export const setCWorkStatusByIdSuccess = createAction(SET_CWORK_STATUS_BY_ID_SUCCESS)

export const SET_CWORK_STATUS_BY_ID_ERROR = 'SET_CWORK_STATUS_BY_ID_ERROR'
export const setWorkStatusByIdError = createAction(SET_CWORK_STATUS_BY_ID_ERROR)

export const DELETE_CWORK_BY_ID = 'DELETE_CWORK_BY_ID'
export const deleteCWorkById = (id) => {
  return (dispatch, getState) => {
    dispatch(createAction(DELETE_CWORK_BY_ID)())
    sendApiRequest(`ContractorWorks/${id}`, {
      accessToken: getState().auth.account.accessToken
    }, 'delete').then((response) => {
      if (response.status === 'failure') {
        dispatch(setWorkStatusByIdError(response))
      }
      if (response.status === 'success') {
        dispatch(setCWorkStatusByIdSuccess(response.data))
      }
    })
  }
}

export const DELETE_CWORK_BY_ID_SUCCESS = 'DELETE_CWORK_BY_ID_SUCCESS'
export const deleteCWorkByIdSuccess = createAction(DELETE_CWORK_BY_ID_SUCCESS)

export const DELETE_CWORK_BY_ID_ERROR = 'DELETE_CWORK_BY_ID_ERROR'
export const deleteCWorkByIdError = createAction(DELETE_CWORK_BY_ID_ERROR)

export const DELETE_CWORK_BY_ID_RESET = 'DELETE_CWORK_BY_ID_RESET'
export const deleteCWorkByIdReset = createAction(DELETE_CWORK_BY_ID_RESET)

export const SCHEDULE_CWORK_DATE_BY_ID = 'SCHEDULE_CWORK_DATE_BY_ID'
export const scheduleCWorkDateById = (id, date) => {
  return (dispatch, getState) => {
    dispatch(createAction(SCHEDULE_CWORK_DATE_BY_ID)())
    const scheduleDate = date.toString()
    sendApiRequest(`ContractorActivities/${id}`, {
      accessToken: getState().auth.account.accessToken,
      scheduleDate
    }, 'patch').then((response) => {
      if (response.status === 'failure') {
        dispatch(scheduleCWorkDateByIdError(response))
      }
      if (response.status === 'success') {
        dispatch(scheduleCWorkDateByIdSuccess(response.data))
      }
    })
  }
}

export const SCHEDULE_CWORK_DATE_BY_ID_SUCCESS = 'SCHEDULE_CWORK_DATE_BY_ID_SUCCESS'
export const scheduleCWorkDateByIdSuccess = createAction(SCHEDULE_CWORK_DATE_BY_ID_SUCCESS)

export const SCHEDULE_CWORK_DATE_BY_ID_ERROR = 'SCHEDULE_CWORK_DATE_BY_ID_ERROR'
export const scheduleCWorkDateByIdError = createAction(SCHEDULE_CWORK_DATE_BY_ID_ERROR)

export const EDIT_CWORK_BY_ID = 'EDIT_CWORK_BY_ID'
export const editCWorkById = (data) => {
  return (dispatch, getState) => {
    const cWorkData = data
    const id = data.contractorWorkId
    cWorkData.accessToken = getState().auth.account.accessToken
    delete cWorkData.contractorWorkId
    dispatch(createAction(EDIT_CWORK_BY_ID)())
    sendApiRequest(`ContractorWorks/${id}`, cWorkData, 'patch').then((response) => {
      if (response.status === 'failure') {
        dispatch(editCWorkByIdError(response))
      }
      if (response.status === 'success') {
        dispatch(editCWorkByIdSuccess(response.data))
      }
    })
  }
}

export const EDIT_CWORK_BY_ID_SUCCESS = 'EDIT_CWORK_BY_ID_SUCCESS'
export const editCWorkByIdSuccess = createAction(EDIT_CWORK_BY_ID_SUCCESS)

export const EDIT_CWORK_BY_ID_ERROR = 'EDIT_CWORK_BY_ID_ERROR'
export const editCWorkByIdError = createAction(EDIT_CWORK_BY_ID_ERROR)

export const RESET_EDIT_CWORK_BY_ID_FORM = 'RESET_EDIT_CWORK_BY_ID_FORM'
export const resetEditCWorkByIdForm = createAction(RESET_EDIT_CWORK_BY_ID_FORM)

const initialState = {
  cWorkCount: 0,
  isGettingCWorks: false,
  hasGottenCWorks: false,
  cWorks: [],
  isGettingCWorksImages: false,
  hasGottenCWorksImages: false,
  cWorksImages: [],
  isDeletingCWork: false,
  hasDeletedCWork: false,
  deleteCWorkError: {},
  deleteCWorkSuccess: {},
  isGettingSelectedCWork: false,
  hasGottenSelectedCWork: false,
  selectedCWork: {},
  isSettingCWorkStatus: false,
  hasSetCWorkStatus: false,
  setCWorkStatusSuccess: {},
  setCWorkStatusError: {},
  isSchedulingCWorkDate: false,
  hasScheduledCWorkDate: false,
  scheduleCWorkSuccess: {},
  scheduleCWorkError: {},
  isEditingCWork: false,
  hasEditedCWork: false,
  editCWorkError: {},
  editCWorkSuccess: {}
}

export default handleActions({

  [GET_CWORK_COUNT_SUCCESS]: (state, { payload }) => ({
    ...state,
    cWorkCount: payload
  }),

  [GET_CWORKS]: (state) => ({
    ...state,
    isGettingCWorks: true,
    hasGottenCWorks: false
  }),

  [GET_CWORKS_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isGettingCWorks: false,
      hasGottenCWorks: true,
      cWorks: payload
    }
  },

  [GET_CWORKS_ERROR]: (state, { payload }) => {
    return {
      ...state,
      isGettingCWorks: false,
      hasGottenCWorks: false,
      cWorksError: payload
    }
  },

  [GET_CWORKS_IMAGES]: (state) => ({
    ...state,
    isGettingCWorksImages: true,
    hasGottenCWorksImages: false
  }),

  [GET_CWORKS_IMAGES_SUCCESS]: (state, { payload }) => ({
    ...state,
    isGettingCWorksImages: false,
    hasGottenCWorksImages: true,
    cWorksImages: payload
  }),

  [DELETE_CWORK_BY_ID]: (state) => ({
    ...state,
    isDeletingCWork: true,
    hasDeletedCWork: false
  }),

  [DELETE_CWORK_BY_ID_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isDeletingCWork: false,
      hasDeletedCWork: true,
      deleteCWorkSuccess: payload
    }
  },

  [DELETE_CWORK_BY_ID_ERROR]: (state, { payload }) => {
    return {
      ...state,
      isDeletingCWork: false,
      hasDeletedCWork: false,
      deleteCWorkError: payload
    }
  },

  [DELETE_CWORK_BY_ID_RESET]: (state) => {
    return {
      ...state,
      isDeletingCWork: false,
      hasDeletedCWork: false,
      deleteCWorkError: {},
      deleteCWorkSuccess: {}
    }
  },

  [GET_CWORK_BY_ID]: (state) => ({
    ...state,
    isGettingSelectedCWork: true,
    hasGottenSelectedCWork: false
  }),

  [GET_CWORK_BY_ID_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isGettingSelectedCWork: false,
      hasGottenSelectedCWork: true,
      selectedCWork: payload
    }
  },
  [SET_CWORK_STATUS_BY_ID]: (state) => {
    return {
      ...state,
      isSettingCWorkStatus: true,
      hasSetCWorkStatus: false,
      setCWorkStatusSuccess: {},
      setCWorkStatusError: {}
    }
  },
  [SET_CWORK_STATUS_BY_ID_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isSettingCWorkStatus: false,
      hasSetCWorkStatus: true,
      setCWorkStatusSuccess: payload,
      setCWorkStatusError: {}
    }
  },
  [SET_CWORK_STATUS_BY_ID_ERROR]: (state, { payload }) => {
    return {
      ...state,
      isSettingCWorkStatus: false,
      hasSetCWorkStatus: false,
      setCWorkStatusSuccess: {},
      setCWorkStatusError: payload
    }
  },
  [SCHEDULE_CWORK_DATE_BY_ID]: (state) => {
    return {
      ...state,
      isSchedulingCWorkDate: true,
      hasScheduledCWorkDate: false,
      scheduleCWorkSuccess: {},
      scheduleCWorkError: {}
    }
  },
  [SCHEDULE_CWORK_DATE_BY_ID_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isSchedulingCWorkDate: false,
      hasScheduledCWorkDate: true,
      scheduleCWorkSuccess: payload,
      scheduleCWorkError: {}
    }
  },
  [SCHEDULE_CWORK_DATE_BY_ID_ERROR]: (state, { payload }) => {
    return {
      ...state,
      isSchedulingCWorkDate: true,
      hasScheduledCWorkDate: false,
      scheduleCWorkSuccess: {},
      scheduleCWorkError: payload
    }
  },
  [EDIT_CWORK_BY_ID]: (state) => {
    return {
      ...state,
      isEditingCWork: true,
      hasEditedCWork: false,
      editCWorkError: {},
      editCWorkSuccess: {}
    }
  },
  [EDIT_CWORK_BY_ID_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isEditingCWork: false,
      hasEditedCWork: true,
      editCWorkError: {},
      editCWorkSuccess: payload
    }
  },
  [EDIT_CWORK_BY_ID_ERROR]: (state, { payload }) => {
    return {
      ...state,
      isEditingCWork: false,
      hasEditedCWork: false,
      editCWorkError: payload,
      editCWorkSuccess: {}
    }
  },
  [RESET_EDIT_CWORK_BY_ID_FORM]: (state) => {
    return {
      ...state,
      isEditingCWork: false,
      hasEditedCWork: false,
      editCWorkError: {},
      editCWorkSuccess: {}
    }
  }

}, initialState)
